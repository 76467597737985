import React, { useContext } from 'react';
import hhInfoImg from '../../../../shared/assets/images/happyHour/info-banner.png';
import hhInfoMobileImg from '../../../../shared/assets/images/happyHour/info-banner-mobile.png';
import classes from './HappyHour.module.scss';
import ButtonPrimary from '../UI/ButtonPrimary';
import { SiteContext } from '@homeplay/app';

const InfoBanner = ({ texts }) => {
  const { siteName, isDesktop } = useContext(SiteContext);

  const textParts = (texts.time + texts.days).split('#');
  const text = textParts.map((textPart, index) => {
    const innerText = textPart.split('^').map((textPart, innerIndex) => {
      if (innerIndex % 2 !== 0) {
        return <span key={index + '' + innerIndex}>{textPart}</span>;
      }
      return textPart;
    });

    if (index === 0) {
      return innerText;
    } else {
      return (
        <React.Fragment key={index}>
          <br />
          {innerText}
        </React.Fragment>
      );
    }
  });

  return (
    <div className={classes.InfoBannerWrap}>
      <div className={classes.InfoBanner}>
        {isDesktop ? (
          <img src={hhInfoImg} alt="" width="860" height="262" />
        ) : (
          <img src={hhInfoMobileImg} alt="" width="480" height="211" />
        )}
        <div className={classes.Content}>Join us {text}</div>
        <ButtonPrimary
          navPath={`/${siteName ? siteName + '/' : ''}happy-hour`}
          customClass={classes.Button}
        >
          Find out more
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default React.memo(InfoBanner);
