import React from 'react';
import { ScrollToTop } from '@homeplay/components';
import { Provider } from 'react-redux';
import App from './App';
import { store } from '@homeplay/app';

function AppWrap() {
  return (
    <React.Fragment>
      <ScrollToTop />
      <Provider store={store}>
        <App />
      </Provider>
    </React.Fragment>
  );
}

export default AppWrap;
