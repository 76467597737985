import React, { useContext, useState } from 'react';
import classes from './Input.module.scss';
import ScreenKeyboard from '../ScreenKeyboard';
import { CSSTransition } from 'react-transition-group';
import { SiteContext } from '@homeplay/app';

const Input = ({
  refLink,
  id,
  type,
  placeholder,
  onChange,
  value,
  pattern,
  error,
  infoMessage,
  errorMessage,
  inputMode,
  autocomplete = 'off',
  maxLength,
}) => {
  const [lostFocus, setLostFocus] = useState(false);
  const { isTouchUnit, screenKeyboard, setScreenKeyboard } =
    useContext(SiteContext);

  const handleBlur = () => {
    if (!lostFocus) {
      setLostFocus(true);
    }
  };

  const handleChange = (event) => {
    const value = event.target.value.replace(/\s+/g, ' ');
    if (event.target.validity.valid && value.length <= (maxLength ?? 120)) {
      if (!value.replace(/[\s-]/g, '').length) {
        onChange('', event);
      } else {
        onChange(
          value.normalize('NFKC').replace(/[\u0300-\u036f]/g, ''),
          event
        );
      }
    }
  };

  const handleClickTab = () => {
    if (!isTouchUnit || !screenKeyboard) return;
    const inputs = Array.prototype.slice.call(
      document.querySelectorAll('input:not(.d-none)')
    );
    const currInput = document.getElementById(id);
    const currInputIndex = inputs.indexOf(currInput);
    const nextInputIndex = (currInputIndex + 1) % inputs.length;
    const input = inputs[nextInputIndex];
    setScreenKeyboard(input.id);
  };

  return (
    <React.Fragment>
      <input
        ref={refLink}
        className={
          classes.Input +
          (error && lostFocus ? ' ' + classes.Error : '') +
          (isTouchUnit && screenKeyboard === id ? ' active-input' : '')
        }
        type={type}
        id={id}
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
        pattern={pattern}
        inputMode={inputMode}
        onFocus={
          isTouchUnit
            ? () => {
                setScreenKeyboard(id);
              }
            : null
        }
        onBlur={handleBlur}
        autoComplete={autocomplete}
      />
      {infoMessage && <p className={classes.InfoMessage}>{infoMessage}</p>}
      {errorMessage && lostFocus && (
        <p className={classes.ErrorMessage}>{errorMessage}</p>
      )}

      {isTouchUnit && screenKeyboard === id && (
        <CSSTransition
          in={!!screenKeyboard}
          timeout={300}
          classNames="slideup"
          unmountOnExit
        >
          <ScreenKeyboard
            setValue={onChange}
            value={value}
            pattern={pattern}
            inputName={id}
            type={type}
            onClickHide={() => setScreenKeyboard(null)}
            onClickTab={handleClickTab}
          />
        </CSSTransition>
      )}
    </React.Fragment>
  );
};

export default React.memo(Input);
