import React, {useState} from "react";
import {motion} from 'framer-motion';
import WidgetToggle from "./WidgetToggle";
import classes from "./ChatWidget.module.css"

const ContactInfoWidget = () => {
    const [isOpen, setIsOpen] = useState(false);

    const onToggleClick = () => {
        setIsOpen((isOpen) => !isOpen);
    };

    return (
        <React.Fragment>
            <motion.div
                className={classes.WindowContainer + ' ' + classes.WindowInfoContainer}
                animate={isOpen ? 'open' : 'closed'}
                initial='closed'
                variants={{
                    closed: {opacity: 0, y: 4},
                    open: {opacity: 1, y: 0},
                }}
                transition={{duration: 0.2, ease: 'easeIn'}}
                style={
                    !isOpen
                        ? {
                            pointerEvents: 'none',
                            visibility: 'hidden',
                        }
                        : {}
                }
            >
                <span onClick={onToggleClick} className={classes.CloseButton}>
                    <svg height="24" width="24" viewBox="0 0 27 27" className="CloseIcon"><path fill="transparent" strokeWidth="2" stroke="#5e5e5e" strokeLinecap="round" d="M 7 7 L 20 20"/><path fill="transparent" strokeWidth="2" stroke="#5e5e5e" strokeLinecap="round" d="M 7 20 L 20 7"/></svg>
                </span>
                The Live Chat feature is not available on this website. Please contact a staff member at your local shop where you usually play.
            </motion.div>

            <motion.div
                className={classes.ToggleButtonContainer}
                initial={false}
                animate={isOpen ? 'open' : 'closed'}
            >
                <WidgetToggle
                    style={{backgroundColor: '#A9499D'}}
                    isDisabled={false}
                    isOpen={isOpen}
                    isLoading={false}
                    iconVariant={'outlined'}
                    toggle={onToggleClick}
                />
            </motion.div>
        </React.Fragment>
    );
};

export default ContactInfoWidget;
