import React, { Suspense, useEffect, useState } from 'react';
import classes from './HappyHour.module.scss';
import hhGetBonus1Img from '../../../../shared/assets/images/happyHour/get-bonus-1.png';
import hhGetBonus2Img from '../../../../shared/assets/images/happyHour/get-bonus-2.png';
import hhGetBonus3Img from '../../../../shared/assets/images/happyHour/get-bonus-3.png';
import hhSubstrateImg from '../../../../shared/assets/images/happyHour/substrate.jpg';
import CountUp from 'react-countup';
import { formatBalance } from '@homeplay/utils';

const AnimatedCoins = React.lazy(() => import('../UI/AnimatedCoins'));

const GetBonusBanner = ({ bonus, fetchUnitBonus, onClose }) => {
  const [showCoins, setShowCoins] = useState(false);
  const [bonusFetched, setBonusFetched] = useState(false);

  useEffect(() => {
    let timeOutId;
    timeOutId = setTimeout(() => {
      setShowCoins(true);
      fetchUnitBonus();
      setBonusFetched(true);
    }, 2000);

    return () => {
      clearTimeout(timeOutId);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    if (!bonusFetched) {
      fetchUnitBonus();
    }
    onClose();
  };

  return (
    <React.Fragment>
      <div className={classes.BannerWrap}>
        <div className={classes.GetBonus}>
          <img
            className="slide-up-1"
            src={hhGetBonus1Img}
            alt=""
            width="485"
            height="382"
          />
          <img
            className="slide-up-2"
            src={hhGetBonus2Img}
            alt=""
            width="320"
            height="60"
          />
          <div className={classes.Substrate + ' slide-up-3'}>
            <img
              className="img-1"
              src={hhSubstrateImg}
              alt=""
              width="335"
              height="113"
            />
            <img
              className="img-2"
              src={hhGetBonus3Img}
              alt=""
              width="485"
              height="260"
            />
            <div className={classes.Content}>
              <span>
                {showCoins ? (
                  <CountUp
                    start={bonus}
                    end={0}
                    formattingFn={formatBalance}
                    onEnd={() => setTimeout(() => onClose(), 1500)}
                  />
                ) : (
                  formatBalance(bonus)
                )}
              </span>
            </div>
          </div>
          <Suspense fallback={<div />}>
            <AnimatedCoins active={showCoins} />
          </Suspense>
        </div>
      </div>
      <div className={classes.BannerOverlay} onClick={handleClick} />
    </React.Fragment>
  );
};

export default React.memo(GetBonusBanner);
