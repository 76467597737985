import React from 'react';
import Product from './Product';
import classes from './ProductList.module.scss';

const ProductsList = ({ products }) => {
  const productsList = products.map((product) => (
    <li key={product.name}>
      <Product
        productId={product.id}
        imgPath={product.img.path}
        alt={product.name}
        imgWigth={product.img.width}
        imgHeight={product.img.height}
      />
    </li>
  ));

  return (
    <ul className={`${classes.ProductsList} products-list`}>{productsList}</ul>
  );
};

export default React.memo(ProductsList);
