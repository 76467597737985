import React from 'react';

const Divider = () => {
    return (
        <div className='divider'>
            <svg width="246" height="15" viewBox="0 0 246 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="116" y="7.07324" width="10.003" height="10.0025" transform="rotate(-45 116 7.07324)" fill="#C5C5C5"/>
                <path d="M0 7.2L97.8676 4L110 7.2L97.8676 10L0 7.2Z" fill="#C4C4C4"/>
                <path d="M246 6.8L148.132 10L136 6.8L148.132 4L246 6.8Z" fill="#C4C4C4"/>
            </svg>
        </div>
    );
};

export default React.memo(Divider);
