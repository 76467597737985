import React from 'react';

import classes from './ButtonSecondary.module.scss';
import {Link} from "react-router-dom";

const ButtonSecondary = ({value, navPath, onClick, customClass}) => {
    return (
        <React.Fragment>
            {navPath
                ? <Link to={navPath} className={classes.ButtonSecondary + ' button-secondary' + (customClass ? ' ' + customClass : '')}>{value}</Link>
                : <span onClick={onClick ? onClick : null} className={classes.ButtonSecondary + ' button-secondary' + (customClass ? ' ' + customClass : '')}>{value}</span>
            }
        </React.Fragment>
    );
};

export default React.memo(ButtonSecondary);
