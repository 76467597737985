import React, {useState} from 'react';
import {default as ReactSelect} from 'react-select';
import classes from './Select.module.scss';

const Select = ({id, placeholder, onChange, value, options, error, infoMessage, errorMessage}) => {
    const [isChanged, setIsChanged] = useState(false);

    const handleChange = ({value}) => {
        if (!isChanged) {
            setIsChanged(true);
        }
        onChange(value);
    };

    return (
        <React.Fragment>
            <ReactSelect
                id={id}
                className={classes.Select + (error && isChanged ? (' ' + classes.Error) : '')}
                classNames={{
                    container: () => classes.Container,
                    control: () => classes.Control,
                    singleValue: () => classes.SingleValue,
                    valueContainer: () => classes.ValueContainer,
                    input: () => classes.Input,
                    placeholder: () => classes.Placeholder,
                    indicatorsContainer: () => classes.Indicator,
                    menu: () => classes.Menu,
                    option: (state) => {
                        if (state.isSelected)
                            return classes.SelectedOption

                        if (state.isFocused)
                            return classes.FocusedOption
                        return '';
                    }
                }}
                defaultValue={value}
                onChange={handleChange}
                options={options}
                placeholder={placeholder}
            />

            {infoMessage &&
            <p className={classes.InfoMessage}>{infoMessage}</p>
            }
            {errorMessage && isChanged &&
            <p className={classes.ErrorMessage}>{errorMessage}</p>
            }
        </React.Fragment>
    );
};

export default React.memo(Select);
