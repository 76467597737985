import React, { useContext } from 'react';
import ButtonPrimary from '../UI/ButtonPrimary';
import { SiteContext } from '@homeplay/app';
import hhDontMissImg from '../../../../shared/assets/images/happyHour/dontmiss.png';
import classes from './HappyHour.module.scss';

const DontMissBanner = () => {
  const { siteName } = useContext(SiteContext);

  return (
    <div className={classes.DontMissBanner}>
      <img
        className={classes.Frame}
        src={hhDontMissImg}
        alt=""
        width="316"
        height="179"
      />
      <ButtonPrimary
        navPath={`/${siteName ? siteName + '/' : ''}auth`}
        customClass={classes.Button}
      >
        Login/Register
      </ButtonPrimary>
    </div>
  );
};

export default React.memo(DontMissBanner);
