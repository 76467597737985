import React from 'react';
import classes from "./BackDrop.module.scss";

const BackDrop = ({onClick}) => {
    return (
        <div onClick={onClick} className={classes.BackDrop}/>
    );
};

export default React.memo(BackDrop);
