import React, {useContext} from 'react';
import classes from './LcGameItem.module.scss';
import {SiteContext} from "@homeplay/app";

const MegaWheelResults = ({results}) => {
    const {isDesktop} = useContext(SiteContext);

    const resultsList = isDesktop ? results : results.slice(0, 9);
    const resultsItems = resultsList.map((result, index) => (
        <li key={index}>
            <span className={`color-${result.result}`}>{result.result}</span>
            {(result.result === result.slot) &&
            <div className={classes.MegaMultiplier}>
                x{result.multiplier}
            </div>
            }
        </li>
    ))

    return (
        <ul className={classes.MegaWheelResults}>
            {resultsItems}
        </ul>
    );
};

export default MegaWheelResults;
