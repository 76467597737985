import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Page } from '@homeplay/components';
import { formatBalance, formatToken } from '@homeplay/utils';
import ButtonSecondary from '@homeplay/components/src/UI/ButtonSecondary';
import { selectUser, SiteContext } from '@homeplay/app';
import Divider from '@homeplay/components/src/UI/Divider';
import classes from '../CashOut.module.scss';

const CashOutTicket = () => {
  const { siteName } = useContext(SiteContext);
  const location = useLocation();
  const user = useSelector(selectUser);

  if (!location.state) {
    return null;
  }

  return (
    <Page
      headerContent={'Cashout to Ticket'}
      backPath={`/${siteName ? siteName + '/' : ''}cashout`}
      customClass={classes.CashOut}
    >
      <div className="container">
        {location.state.ticket && location.state.status === 'completed' && (
          <React.Fragment>
            <div className="inline-block">
              <span className="label">Ticket Value</span>
              <span className="value">
                {formatBalance(location.state.ticket.value)}
              </span>
            </div>

            <Divider />

            <h4>Ticket Number</h4>
            <p className="value">{formatToken(location.state.ticket.code)}</p>

            <ul className="marked-list d-i-block">
              <li>Copy down the Ticket Number.</li>
              <li>Visit the shop where you usually play.</li>
              <li>Give the ticket number to the friendly cashier.</li>
              <li>The cashier will pay out the value of the ticket.</li>
            </ul>
            <div className={classes.rulesWrapper}>
              <p>The 12-digit ticket is valid for 28 days.</p>
              <p>
                Once users cash out their funds to a 12-digit ticket, they can
                only reload that ticket to their own registered account.
              </p>
            </div>
          </React.Fragment>
        )}

        {location.state.status === 'in_progress' && (
          <React.Fragment>
            {user ? (
              <div>
                <p>
                  The cashout is still in progress. We'll continue our attempts
                  to process your funds.
                </p>
                <p>
                  You can close this page and check the cashout status in your
                  statement later.
                </p>
              </div>
            ) : (
              <div>
                <p>
                  The cashout is still in progress, and we're working on
                  processing your funds.
                </p>
                <p>
                  If you need to close the page, please remember the unit ID
                  located at the bottom for reference. You can use this unit ID
                  to contact support and inquire about the status of your
                  cashout.
                </p>
                <p>
                  Also, remember to log in next time to avoid potential loss of
                  funds.
                </p>
              </div>
            )}

            <Divider />
          </React.Fragment>
        )}
      </div>

      <div className="container bottom-block">
        <ButtonSecondary
          navPath={`/${siteName ? siteName + '/' : ''}`}
          value="Play Games"
          customClass="d-block"
        />
      </div>
    </Page>
  );
};

export default CashOutTicket;
