import React, {useContext} from 'react';
import classes from './LcGameItem.module.scss';
import {SiteContext} from "@homeplay/app";

const BaccaratStats = ({options}) => {
    const {isDesktop} = useContext(SiteContext);

    let statsItems;
    if (isDesktop) {
        statsItems = options.stats.map((statsRow, rowIndex) => {
            const statsRowItems = statsRow.map((statsItem, itemIndex) => (
                <div key={rowIndex + '' + itemIndex}>
                    {statsItem && <span className={statsItem}><span/></span>}
                </div>
            ));

            return (
                <div key={rowIndex}>
                    {statsRowItems}
                </div>
            );
        });
    } else {
        statsItems = (
            <React.Fragment>
                <div>#{options.playerWins + options.bankerWins + options.tieWins}</div>
                <div><span className={classes.Banker}/>{options.bankerWins}</div>
                <div><span className={classes.Player}/>{options.playerWins}</div>
                <div><span className={classes.Tie}/>{options.tieWins}</div>
                <div><span className={classes.BankerPair}/>{options.bankerPairWins}</div>
                <div><span className={classes.PlayerPair}/>{options.playerPairWins}</div>
            </React.Fragment>
        );
    }

    return (
        <div className={classes.BaccaratStats}>
            {statsItems}
        </div>
    );
};

export default BaccaratStats;
