import React from 'react';
import { GamesList, ProductPage } from '@homeplay/components';
import classes from './OnlyPlay.module.scss';
import { selectOnlyPlayGames } from '@homeplay/app';
import onlyPlayLogo from '../../../../shared/assets/images/only-play-logo.png';
import { useSelector } from 'react-redux';

const OnlyPlay = () => {
  const onlyPlayGames = useSelector(selectOnlyPlayGames);

  const getGameLink = (loadParams) => {
    return loadParams.url;
  };

  return (
    <ProductPage
      getGameLink={getGameLink}
      allowScrolling={true}
      customClass={classes.OnlyPlay}
    >
      <div className="container">
        <img
          className={classes.Logo}
          src={onlyPlayLogo}
          alt="OnlyPlay"
          width="330"
          height="74"
        />
        {onlyPlayGames && <GamesList games={onlyPlayGames} />}
      </div>
    </ProductPage>
  );
};

export default OnlyPlay;
