import parser from 'cron-parser';
import { DateTime } from 'luxon';
import convert from './cron-converter';

const daysOfWeekStrings = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

const formatLuxonTime = (dateTime) => {
  return dateTime.minute
    ? dateTime.toFormat('h:mm a')
    : dateTime.toFormat('h a');
};

const isDaysSequential = (daysOfWeek) =>
  daysOfWeek.every(
    (day, index) => index === 0 || day - daysOfWeek[index - 1] === 1
  );

export const getCronSchedule = (cronString, currentDate) => {
  try {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localCronString = convert(cronString, 'UTC', timeZone);

    return parser.parseExpression(localCronString, { currentDate });
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getPromoTexts = (schedule, duration) => {
  if (!schedule || !duration) {
    return null;
  }

  const promoDates = getCronSchedule(schedule);
  if (promoDates === null) {
    return null;
  }

  const daysOfWeek = promoDates.fields.dayOfWeek.filter((day) => day !== 7);

  const promoTimeText = getPromoTimeText(promoDates.fields, duration);
  const promoDaysText = getPromoDaysText(daysOfWeek);

  return {
    time: promoTimeText,
    days: promoDaysText,
  };
};

const getPromoDaysText = (daysOfWeek) => {
  const firstDay = daysOfWeekStrings[daysOfWeek[0]];

  switch (daysOfWeek.length) {
    case 7:
      return 'daily';

    case 1:
      return `every ^${firstDay}^`;

    case 2:
      const secondDay = daysOfWeekStrings[daysOfWeek[1]];
      return `every ^${firstDay}^ and ^${secondDay}^`;

    default:
      const lastDay = daysOfWeekStrings[daysOfWeek[daysOfWeek.length - 1]];

      if (isDaysSequential(daysOfWeek)) {
        return `#every ^${firstDay} - ${lastDay}^`;
      }

      return `#every ^${daysOfWeek
        .slice(0, -1)
        .map((day) => daysOfWeekStrings[day])
        .join(', ')}^ and ^${lastDay}^`;
  }
};

const getPromoTimeText = (fields, promoDuration) => {
  let timeText = '';

  if (fields.hour.length < 4) {
    timeText += 'between ';

    fields.hour.forEach((time, index) => {
      if (index !== 0) {
        timeText += 'or ';
      }

      const luxonTime = DateTime.fromObject({
        hour: time,
        minute: fields.minute[0],
      });

      timeText += `^${formatLuxonTime(luxonTime)}^ and ^${formatLuxonTime(
        luxonTime.plus({ milliseconds: promoDuration })
      )}^ `;
    });
  } else {
    if (fields.minute.length === 1) {
      const hoursDiff = fields.hour[1] - fields.hour[0];
      timeText = `every ^${hoursDiff}^ hour${hoursDiff > 1 ? 's' : ''} `;
    } else {
      const minutesDiff = fields.minute[1] - fields.minute[0];
      timeText = `every ^${minutesDiff}^ minutes `;
    }
  }

  return timeText.toLowerCase();
};
