import React, { useContext } from 'react';
import Modal from './index';
import Button from '../UI/Button';
import ButtonSecondary from '../UI/ButtonSecondary';
import { SiteContext } from '@homeplay/app';
import DontMissBanner from '../HappyHour/DontMissBanner';
import { Link } from 'react-router-dom';

const WhyRegisterModal = ({ onClose, isHappyHour }) => {
  const { siteName } = useContext(SiteContext);

  return (
    <Modal modalClose={onClose}>
      <div>
        <h3>Disclaimer</h3>
        <div className="text-left">
          <p>
            Please{' '}
            <Link to={`/${siteName ? siteName + '/' : ''}auth`}>log in</Link> to
            prevent loss of funds.
          </p>
          <p>
            If you chose to proceed playing anonymously, you will still be able
            to cashout the balance to your ticket.
          </p>
          <p>
            However please note that 7Riches will not be able to assist in
            processing the cashout, should you lose your play session due to a
            disruption in your connection such as loadshedding or accidentally
            closing your browser tab.
          </p>
          <p>
            We strongly recommend, that you{' '}
            <Link to={`/${siteName ? siteName + '/' : ''}auth`}>register</Link>{' '}
            and{' '}
            <Link to={`/${siteName ? siteName + '/' : ''}auth`}>log in</Link> in
            order to keep your funds safe.
          </p>
        </div>

        <Button
          navPath={`/${siteName ? siteName + '/' : ''}auth`}
          customClass="d-block"
        >
          Login/Register
        </Button>
        <ButtonSecondary
          navPath={`/${siteName ? siteName + '/' : ''}cashin`}
          value="Understand, proceed without registration"
          customClass="d-block"
        />
      </div>
      {isHappyHour && <DontMissBanner />}
    </Modal>
  );
};

export default WhyRegisterModal;
