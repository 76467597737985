import React from 'react';
import { Link } from 'react-router-dom';
import classes from './ButtonPrimary.module.scss';

const ButtonPrimary = ({
  children,
  navPath,
  onClick,
  disabled = false,
  customClass,
}) => {
  let button;
  if (navPath && !disabled) {
    button = (
      <Link
        to={navPath}
        className={classes.Button + (customClass ? ' ' + customClass : '')}
      >
        {children}
      </Link>
    );
  } else if (onClick || (navPath && disabled)) {
    button = (
      <span
        onClick={onClick && !disabled ? onClick : null}
        className={
          classes.Button +
          (customClass ? ' ' + customClass : '') +
          (disabled ? ' ' + classes.Disabled : '')
        }
      >
        {children}
      </span>
    );
  } else {
    button = (
      <button
        type="submit"
        className={
          classes.Button +
          (customClass ? ' ' + customClass : '') +
          (disabled ? ' ' + classes.Disabled : '')
        }
        disabled={disabled}
      >
        {children}
      </button>
    );
  }

  return button;
};

export default React.memo(ButtonPrimary);
