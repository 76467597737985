import React, { useContext } from 'react';
import { selectHHConfigs, SiteContext } from '@homeplay/app';
import { ButtonSecondary, Page } from '@homeplay/components';
import classes from './InfoPage.module.scss';
import { useSelector } from 'react-redux';
import { formatBalance } from '@homeplay/utils';
import { Link } from 'react-router-dom';
import BonusLabel from '@homeplay/components/src/UI/BonusLabel';

const InfoPage = () => {
  const { siteName } = useContext(SiteContext);
  const hhConfigs = useSelector(selectHHConfigs);

  const formatRulesText = (hhTexts) => {
    const hhText = (hhTexts.time + hhTexts.days).replace('#', '');
    const textParts = hhText.split('^');
    return textParts.map((textPart, index) => {
      if (index % 2 === 0) {
        return textPart;
      } else {
        return <span key={index}>{textPart}</span>;
      }
    });
  };

  if (!hhConfigs || !hhConfigs.length) {
    return null;
  }

  let isMinDepositSame = true;
  let isBonusPercentageSame = true;
  let isMaxBonusSame = true;

  if (hhConfigs.length > 1) {
    hhConfigs.forEach((happyHour) => {
      if (happyHour.minDeposit !== hhConfigs[0].minDeposit) {
        isMinDepositSame = false;
      }
      if (happyHour.bonusPercentage !== hhConfigs[0].bonusPercentage) {
        isBonusPercentageSame = false;
      }
      if (happyHour.maxBonus !== hhConfigs[0].maxBonus) {
        isMaxBonusSame = false;
      }
    });
  }

  let hhRules;

  if (isMaxBonusSame && isBonusPercentageSame && isMinDepositSame) {
    const text = hhConfigs.map((happyHour, index) => {
      const innerText = formatRulesText(happyHour.texts);
      return index > 0 ? (
        <React.Fragment key={index}> and {innerText}</React.Fragment>
      ) : (
        innerText
      );
    });

    hhRules = (
      <React.Fragment>
        <p>
          Deposit <span>{formatBalance(hhConfigs[0].minDeposit, false)}</span>{' '}
          or more in one transaction and play {text} and we will give you{' '}
          {hhConfigs[0].bonusPercentage}% of your deposit in Free Spins if you
          don’t win.
        </p>
        <p>
          Only tickets purchased at the land based site, or aCoin vouchers will
          be eligible for the HappyHour bonus. Previously used tickets will not
          activate the HappyHour bonus.
        </p>
        <p>To qualify you need to Register and Login.</p>
        <p>
          <Link to={`/${siteName ? siteName + '/' : ''}bonus-games`}>
            The list of the games
          </Link>{' '}
          considered for the Happy Hour bonus can be found{' '}
          <Link to={`/${siteName ? siteName + '/' : ''}bonus-games`}>here</Link>
          .
        </p>
        <div className={classes.Paragraph}>
          <span>
            When Happy Hour bonus starts, games considered for Happy Hour will
            have an icon
          </span>{' '}
          <BonusLabel />
        </div>
        <p>We only count the first deposit made during happy hour.</p>
        <p>Losses {text} only will count towards your Free Spins.</p>
        <p>
          Free Spins are limited to{' '}
          {formatBalance(hhConfigs[0].maxBonus, false)}.
        </p>
      </React.Fragment>
    );
  } else if (isMaxBonusSame && isBonusPercentageSame) {
    const text = hhConfigs.map((happyHour, index) => {
      if (index > 0) {
        return (
          <React.Fragment key={index}>
            {' '}
            and <span>{formatBalance(happyHour.minDeposit, false)}</span> or
            more in one transaction {formatRulesText(happyHour.texts)}
          </React.Fragment>
        );
      }
      return (
        <React.Fragment key={index}>
          <span>{formatBalance(happyHour.minDeposit, false)}</span> or more in
          one transaction {formatRulesText(happyHour.texts)}
        </React.Fragment>
      );
    });

    const timeText = hhConfigs.map((happyHour, index) => {
      const innerText = formatRulesText(happyHour.texts);
      return index > 0 ? (
        <React.Fragment key={`${index}_`}> and {innerText}</React.Fragment>
      ) : (
        innerText
      );
    });

    hhRules = (
      <React.Fragment>
        <p>
          Deposit and play {text} and we will give you{' '}
          {hhConfigs[0].bonusPercentage}% of your deposit in Free Spins if you
          don’t win.
        </p>
        <p>
          Only tickets purchased at the land based site, or aCoin vouchers will
          be eligible for the HappyHour bonus. Previously used tickets will not
          activate the HappyHour bonus.
        </p>
        <p>To qualify you need to Register and Login.</p>
        <p>
          <Link to={`/${siteName ? siteName + '/' : ''}bonus-games`}>
            The list of the games
          </Link>{' '}
          considered for the Happy Hour bonus can be found{' '}
          <Link to={`/${siteName ? siteName + '/' : ''}bonus-games`}>here</Link>
          .
        </p>
        <p>We only count the first deposit made during happy hour.</p>
        <p>Losses {timeText} only will count towards your Free Spins.</p>
        <p>
          Free Spins are limited to{' '}
          {formatBalance(hhConfigs[0].maxBonus, false)}.
        </p>
      </React.Fragment>
    );
  } else {
    hhRules = hhConfigs.map((happyHour, index) => {
      return (
        <div className={classes.Rule} key={index}>
          <p>
            Deposit <span>{formatBalance(happyHour.minDeposit, false)}</span> or
            more in one transaction and play {formatRulesText(happyHour.texts)}{' '}
            and we will give you {happyHour.bonusPercentage}% of your deposit in
            Free Spins if you don’t win.
          </p>
          <p>
            Only tickets purchased at the land based site, or aCoin vouchers
            will be eligible for the HappyHour bonus. Previously used tickets
            will not activate the HappyHour bonus.
          </p>
          <p>To qualify you need to Register and Login.</p>
          <p>
            <Link to={`/${siteName ? siteName + '/' : ''}bonus-games`}>
              The list of the games
            </Link>{' '}
            considered for the Happy Hour bonus can be found{' '}
            <Link to={`/${siteName ? siteName + '/' : ''}bonus-games`}>
              here
            </Link>
            .
          </p>
          <p>We only count the first deposit made during happy hour.</p>
          <p>
            Losses {formatRulesText(happyHour.texts)} only will count towards
            your Free Spins.
          </p>
          <p>
            Free Spins are limited to {formatBalance(happyHour.maxBonus, false)}
            .
          </p>
        </div>
      );
    });
  }

  return (
    <Page headerContent={'Happy Hour Bonus'} customClass={classes.InfoPage}>
      <div className="container">{hhRules}</div>

      <div className="container bottom-block">
        <ButtonSecondary
          navPath={`/${siteName ? siteName + '/' : ''}`}
          value="Back"
          customClass="d-block"
        />
      </div>
    </Page>
  );
};

export default InfoPage;
