import React, { useContext, useEffect, useState } from 'react';
import { logout, SiteContext, selectUser, selectBalance } from '@homeplay/app';
import { useIdleTimer } from 'react-idle-timer';
import { CSSTransition } from 'react-transition-group';
import Modal from '../Modal';
import ButtonSecondary from '../UI/ButtonSecondary';
import Button from '../UI/Button';
import classes from './IdleModal.module.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const IdleModal = () => {
  const { siteName, isTouchUnit } = useContext(SiteContext);
  const dispatch = useDispatch();
  const balance = useSelector(selectBalance);
  const user = useSelector(selectUser);
  const [isIdle, setIsIdle] = useState(false);
  const [counter, setCounter] = useState(0);
  const navigate = useNavigate();

  const handleOnActive = () => {
    if (user) {
      setIsIdle(false);
    }
  };

  const handleOnIdle = () => {
    if (user) {
      setCounter(15);
      setIsIdle(true);
    }
  };

  const { reset } = useIdleTimer({
    timeout: process.env.REACT_APP_AUTO_LOGOUT_TIMEOUT,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
    stopOnIdle: true,
  });

  const handleClickStay = () => {
    setIsIdle(false);
    reset();
  };

  const handleCloseModal = () => {
    setIsIdle(false);
    reset();
  };

  const handleClickLogOut = () => {
    dispatch(logout(siteName, isTouchUnit));
    setIsIdle(false);
    navigate(`/${siteName}`);
  };

  useEffect(() => {
    if (user) {
      setIsIdle(false);
      reset();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, balance]);

  useEffect(() => {
    let timerId;
    if (counter > 0) {
      timerId = setTimeout(() => setCounter((counter) => counter - 1), 1000);
    } else if (isIdle && user) {
      dispatch(logout(siteName, isTouchUnit));
      setIsIdle(false);
      navigate(`/${siteName}`);
    }

    return () => {
      clearTimeout(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);

  return (
    <CSSTransition in={isIdle} timeout={0} classNames="modal" unmountOnExit>
      <Modal modalClose={handleCloseModal} customClass={classes.IdleModal}>
        <h2>Are you still there?</h2>
        <p>You will be logged out in</p>
        <div className={classes.Timer}>
          <svg
            width="40"
            height="40"
            viewBox="0 0 22 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="fill-primary"
              d="M14.6235 10.5608L16.9253 7.37358L16.5288 7.05217L13.3216 9.50554C12.7322 9.39629 12.0973 9.55937 11.6677 9.99479C11.0251 10.6463 11.0871 11.6478 11.8066 12.2305C12.5261 12.8131 13.6298 12.7569 14.2725 12.1046C14.7021 11.6692 14.8095 11.0794 14.6235 10.5608ZM11.2093 2.13275V0H14.7021V2.13275C13.4456 1.94038 12.4388 1.94433 11.2093 2.13275ZM18.938 3.76675L20.1666 2.65288L21.4013 3.77229L20.2478 4.81808C19.8444 4.43492 19.406 4.08421 18.938 3.76675ZM2.4773 7.125H9.4629V8.70833H2.4773V7.125ZM0.730896 10.2917H7.7165V11.875H0.730896V10.2917ZM3.3505 13.4583H9.4629V15.0417H3.3505V13.4583ZM21.6877 11.0833C21.6877 15.4557 17.7784 19 12.9557 19C10.5195 19 8.32774 18.0856 6.74637 16.625H9.61047C10.6042 17.1245 11.7402 17.4167 12.9557 17.4167C16.8074 17.4167 19.9413 14.5754 19.9413 11.0833C19.9413 7.59129 16.8074 4.75 12.9557 4.75C11.7402 4.75 10.6042 5.04213 9.61047 5.54167H6.74637C8.32774 4.08104 10.5195 3.16667 12.9557 3.16667C17.7784 3.16667 21.6877 6.71096 21.6877 11.0833Z"
            />
          </svg>
          <span>
            <span>{counter}</span> sec
          </span>
        </div>
        <div className={classes.Inline}>
          <ButtonSecondary value="Logout" onClick={handleClickLogOut} />
          <Button onClick={handleClickStay}>Stay</Button>
        </div>
      </Modal>
    </CSSTransition>
  );
};

export default React.memo(IdleModal);
