import React, { useContext } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import classes from './Maintenance.module.scss';
import { SiteContext } from '@homeplay/app';

const Maintenance = () => {
  const { siteName } = useContext(SiteContext);

  return (
    <React.Fragment>
      <Header headerContent={null} backPath={siteName ? siteName + '/' : '/'} />
      <main className={classes.Maintenance}>
        <h2 className="color-primary">Maintenance in Progress</h2>
        <p>
          Sorry for the inconvenience. Our website is undergoing scheduled
          maintenance. <br /> We’ll be back soon!
        </p>
      </main>
      <Footer />
    </React.Fragment>
  );
};

export default Maintenance;
