export const apolloRedirects = [
	'vivian',
	'agatha',
	'fani',
	'berguitta',
	'tina',
	'jeanne',
	'gail',
	'ilsa',
	'janice',
	'tasha',
	'dell',
	'vanessa',
	'camila',
	'adele',
	'milla',
	'aida',
	'molly',
];
