import React, {useContext} from 'react';
import {Link} from "react-router-dom";
import classes from './LcGameItem.module.scss';
import NewLabel from "../../UI/NewLabel";
import BjSeats from "./BjSeats";
import {formatBalance} from "@homeplay/utils";
import BaccaratStats from "./BaccaratStats";
import RouletteResults from "./RouletteResults";
import SicBoResults from "./SicBoResults";
import MegaWheelResults from "./MegaWheelResults";
import {SiteContext} from "@homeplay/app";

const LcGameItem = ({game}) => {
    const {siteName} = useContext(SiteContext);
    let gameOptions = null;

    switch (game.type) {
        case 'BLACKJACK':
            gameOptions = (<BjSeats seats={game.options.seats}/>);
            break;
        case 'BACCARAT':
            gameOptions = (<BaccaratStats options={game.options}/>);
            break;
        case 'ROULETTE':
            gameOptions = (<RouletteResults results={game.options.results}/>);
            break;
        case 'SicBO':
            gameOptions = (<SicBoResults results={game.options.results}/>);
            break
        case 'MEGAWHEEL':
            gameOptions = (<MegaWheelResults results={game.options.results}/>);
            break;
    }

    return (
        <React.Fragment>
            {game.dealer ?
                <Link
                    to={`/${siteName ? (siteName + '/') : ''}${game.productId}/game/${game.slug}`}
                    state={{exitUrl: window.location.href}}
                    className={classes.Game}
                >
                    {game.isNew && <NewLabel/>}
                    <img src={game.img} alt={game.name} width='800' height='450'/>

                    <div className={classes.BoxHead}>
                        <div>{formatBalance(game.minBet, false)} - {formatBalance(game.maxBet, false)}</div>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="14" viewBox="0 0 11 14" fill="none"><g opacity="0.4"><path d="M10.8 10.3V10.9C10.5 11.4 10.2 11.8 9.8 12.2H8.7V13.1C7.9 13.6 6.9 13.9 5.9 14H5.1C4.1 13.9 3.2 13.6 2.4 13.1V12.2H1.2C0.8 11.8 0.5 11.3 0.3 10.9V10.3C0.3 8.5 1.4 6.8 3 6.2 3.8 6.6 4.6 6.8 5.5 6.8 6.4 6.8 7.3 6.6 8 6.2 9.6 6.9 10.8 8.5 10.8 10.3Z" fill="white"/><path d="M5.5 5.4C7 5.4 8.2 4.2 8.2 2.7 8.2 1.2 7 0 5.5 0 4 0 2.8 1.2 2.8 2.7 2.8 4.2 4 5.4 5.5 5.4Z" fill="white"/></g></svg>

                            {(game.totalSeatedPlayers > 0 || game.totalSeats) && <span>{game.totalSeatedPlayers}</span>}
                            {game.totalSeats && <span>/{game.totalSeats}</span>}
                        </div>
                    </div>

                    <div className={classes.BoxFoot}>
                        {gameOptions}
                        <div className={classes.LiveLeft}>
                            {game.name}
                        </div>
                        <div className={classes.LiveRight}>
                            {game.dealer}
                        </div>
                    </div>
                </Link>
                :
                <div className={classes.Game + ' ' + classes.TableClose}>
                    {game.isNew && <NewLabel/>}
                    {game.img ?
                        <img src={game.img} alt={game.name} width='800' height='450'/>
                        :
                        <div className={classes.Placeholder}/>
                    }

                    {game.minBet &&
                    <div className={classes.BoxHead}>
                        <div>{formatBalance(game.minBet, false)} - {formatBalance(game.maxBet, false)}</div>
                    </div>
                    }

                    <div className={classes.BoxFoot}>
                        <div className={classes.LiveLeft}>
                            {game.name}
                        </div>
                    </div>

                    <div className={classes.TableMsg}>Table closed</div>
                </div>
            }
        </React.Fragment>
    );
};

export default LcGameItem;
