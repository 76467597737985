import React, { Suspense, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import CashOutTicket from './CashOutTicket';
import { useSelector } from 'react-redux';
import { selectCashoutParams } from '@homeplay/app';
import { Loader } from '@homeplay/components';

const HelpPage = React.lazy(() => import('./HelpPage'));
const CashOutMain = React.lazy(() => import('./CashOutMain'));
const CashOutATM = React.lazy(() => import('./CashOutATM'));
const CashOutEFT = React.lazy(() => import('./CashOutEFT'));

const CashOut = () => {
  const {
    balance,
    cashoutAtmEnabled,
    cashoutEftEnabled,
    cashoutTicketEnabled,
    cashoutAtmMinAmount,
    cashoutEftMinAmount,
  } = useSelector(selectCashoutParams);

  const [isCashoutingATM, setIsCashoutingATM] = useState(false);
  const [isCashoutingEFT, setIsCashoutingEFT] = useState(false);

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={<CashOutMain />} />
        {cashoutTicketEnabled && (
          <Route path="ticket" element={<CashOutTicket />} />
        )}
        {cashoutAtmEnabled &&
          (balance >= cashoutAtmMinAmount || isCashoutingATM) && (
            <Route
              path="atm"
              element={<CashOutATM setIsCashouting={setIsCashoutingATM} />}
            />
          )}
        {cashoutEftEnabled &&
          (balance >= cashoutEftMinAmount || isCashoutingEFT) && (
            <Route
              path="eft"
              element={<CashOutEFT setIsCashouting={setIsCashoutingEFT} />}
            />
          )}
        <Route path="help" element={<HelpPage />} />
        <Route path="*" element={<Navigate to=".." replace />} />
      </Routes>
    </Suspense>
  );
};

export default CashOut;
