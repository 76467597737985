import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import classes from './GameItem.module.scss';
import NewLabel from '../../UI/NewLabel';
import BonusLabel from '../../UI/BonusLabel';
import lotoImg from '../../../../../shared/assets/images/loto.png';

const GameItem = ({
  gamePath,
  isNew,
  hasBonus,
  gameImg,
  imgWigth,
  imgHeight,
  alt,
  activeHappyHour,
  productId,
}) => {
  const [imgError, setImgError] = useState(false);
  let gameConfig = null;

  const handleImgLoadError = () => {
    setImgError(true);
  };

  const parseLotoConfigs = (name) => {
    const words = name.split(' ');
    const { name: country, code } = parseLotoCountry(words);
    const lottoName = name.replace(country, '');
    const result = lottoName.match(/\d+\/\d+/g);
    const digits = result[0].split('/');
    return {
      country: country,
      code: code,
      name: lottoName,
      digit1: digits[0],
      digit2: digits[1],
    };
  };

  const parseLotoCountry = (words) => {
    const firstWord = words[0].toLowerCase();
    let name;
    let code;

    if (
      firstWord === 'united' ||
      firstWord === 'south' ||
      firstWord === 'new' ||
      firstWord === 'hong'
    ) {
      name = words[0] + ' ' + words[1];
      code = firstWord + '-' + words[1].toLowerCase();
    } else if (firstWord === 'bosnia' && words[1].toLowerCase() === 'and') {
      name = words[0] + ' ' + words[1] + ' ' + words[2];
      code =
        firstWord + '-' + words[1].toLowerCase() + '-' + words[2].toLowerCase();
    } else {
      name = words[0];
      code = firstWord;
    }

    return { name, code };
  };

  if (productId === 'oln') {
    gameConfig = parseLotoConfigs(alt);
  }

  return (
    <Link
      to={gamePath}
      state={{ exitUrl: window.location.href }}
      className={classes.Game}
    >
      {hasBonus && activeHappyHour && <BonusLabel />}
      {isNew && <NewLabel />}

      {productId === 'oln' ? (
        <React.Fragment>
          {gameConfig && (
            <div className={classes.Loto}>
              <div
                className={classes.LotoFlag}
                style={{
                  backgroundImage: `url(/static/media/${gameConfig.code}.jpg)`,
                }}
              ></div>
              <img src={lotoImg} alt={alt} width={264} height={158} />
              <h5>
                <span
                  style={{
                    fontSize: `${Math.min(100, 1800 / gameConfig.country.length)}%`,
                  }}
                >
                  {gameConfig.country}
                </span>{' '}
                <span
                  style={{
                    fontSize: `${gameConfig.name.length < 32 ? Math.min(100, 2100 / gameConfig.name.length) : 70}%`,
                  }}
                >
                  {gameConfig.name}
                </span>
              </h5>
              <div className={classes.Digit1}>{gameConfig.digit1}</div>
              <div className={classes.Digit2}>{gameConfig.digit2}</div>
            </div>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {imgError ? (
            <div
              className="placeholder"
              style={{
                width: `${imgWigth}px`,
                paddingBottom: `${(imgHeight / imgWigth) * 100}%`,
              }}
            >
              <span>{alt}</span>
            </div>
          ) : (
            <img
              src={gameImg}
              alt={alt}
              width={imgWigth}
              height={imgHeight}
              onError={handleImgLoadError}
            />
          )}
        </React.Fragment>
      )}
    </Link>
  );
};

export default GameItem;
