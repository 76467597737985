import React from "react";
import {isDev, isValidUuid, noop, stringify} from "../utils";
import Logger from "../logger";
import store from "../storage";
import {getUserInfo} from "../track/info";
import {fetchWidgetSettings} from "../api";

const DEFAULT_IFRAME_URL = 'https://ikfasw.com';

// TODO: set this up somewhere else
const setupPostMessageHandlers = (handlers) => {
    const cb = (msg) => {
        handlers(msg);
    };

    if (window.addEventListener) {
        window.addEventListener('message', cb);

        return () => window.removeEventListener('message', cb);
    } else {
        window.attachEvent('onmessage', cb);

        return () => window.detachEvent('onmessage', cb);
    }
};

const setupCustomEventHandlers = (events, handlers) => {
    if (window.addEventListener) {
        for (const event of events) {
            window.addEventListener(event, handlers);
        }

        return () => events.map((event) => window.removeEventListener(event, handlers));
    } else {
        console.error('Custom events are not supported in your browser!');
        return noop;
    }
};

class ChatWidgetContainer extends React.Component {
    iframeRef;
    storage;
    subscriptions = [];
    logger;

    EVENTS = [
        'papercups:open',
        'papercups:close',
        'papercups:toggle',
        'papercups:identify',
        'storytime:customer:set',
    ];

    constructor(props) {
        super(props);

        if (!props.accountId) {
            throw new Error('An `accountId` is required to run the LiveChat!');
        } else if (!isValidUuid(props.accountId)) {
            console.error(
                `The \`accountId\` must be a valid UUID. (Received invalid \`accountId\`: ${props.accountId})`
            );
            console.error(
                `If you're missing a LiveChat \`accountId\`, you can get one by signing up for a free account at https://app.papercups.io/register`
            );
            throw new Error(`Invalid \`accountId\`: ${props.accountId}`);
        }

        this.state = {
            isOpen: false,
            isLoaded: false,
            needOpen: false,
            query: '',
            config: {},
            shouldDisplayNotifications: false,
            isTransitioning: false,
        };
    };

    async componentDidMount() {
        const settings = await this.fetchWidgetSettings();
        if (!settings.enable_widget) {
            return;
        }
        const {
            accountId,
            primaryColor,
            iconVariant,
            baseUrl,
            awayMessage,
            newMessagePlaceholder,
            authMessage,
            emailInputPlaceholder,
            newMessagesNotificationText,
            agentAvailableText,
            agentUnavailableText,
            showAgentAvailability,
            requireEmailUpfront,
            requireAuth,
            canToggle,
            customer = {},
        } = this.props;
        // TODO: make it possible to opt into debug mode via props
        const debugModeEnabled = isDev(window);

        this.logger = new Logger(debugModeEnabled);
        this.subscriptions = [
            setupPostMessageHandlers(this.postMessageHandlers),
            setupCustomEventHandlers(this.EVENTS, this.customEventHandlers),
        ];

        this.storage = store(window);

        const metadata = {...getUserInfo(), ...customer};
        const config = {
            accountId,
            baseUrl,
            title: this.getDefaultTitle(settings),
            subtitle: this.getDefaultSubtitle(settings),
            primaryColor: settings.color || primaryColor,
            greeting: this.getDefaultGreeting(settings),
            awayMessage: settings.away_message || awayMessage,
            newMessagePlaceholder: settings.new_message_placeholder || newMessagePlaceholder,
            authMessage: settings.auth_message || authMessage,
            emailInputPlaceholder: settings.email_input_placeholder || emailInputPlaceholder,
            newMessagesNotificationText: settings.new_messages_notification_text || newMessagesNotificationText,
            companyName: settings?.account?.company_name,
            companyLogo: settings?.account?.company_logo_url,
            requireEmailUpfront: (('require_email_upfront' in settings) ? settings.require_email_upfront : requireEmailUpfront) ? 1 : 0,
            requireAuth: (('require_auth' in settings) ? settings.require_auth : requireAuth) ? 1 : 0,
            showAgentAvailability: (('show_agent_availability' in settings) ? settings.show_agent_availability : showAgentAvailability) ? 1 : 0,
            agentAvailableText: settings.agent_available_text || agentAvailableText,
            agentUnavailableText: settings.agent_unavailable_text || agentUnavailableText,
            closeable: canToggle ? 1 : 0,
            customerId: this.storage.getCustomerId(),
            isOutsideWorkingHours: settings?.account?.is_outside_working_hours ? 1 : 0,
            metadata: JSON.stringify(metadata),
            version: '1.1.8',
        };

        const query = stringify(config, {skipEmptyString: true, skipNull: true});
        this.setState({config: {...config, iconVariant: settings.icon_variant || iconVariant}, query});
    };

    componentWillUnmount() {
        this.subscriptions.forEach((unsubscribe) => {
            if (typeof unsubscribe === 'function') {
                unsubscribe();
            }
        });
    };

    componentDidUpdate(prevProps) {
        const {accountId, customer, baseUrl} = this.props;

        if (this.customerChanged(prevProps.customer, customer) || accountId !== prevProps.accountId || baseUrl !== prevProps.baseUrl) {
            const metadata = JSON.stringify({...getUserInfo(), ...customer});

            this.handleConfigUpdated({
                accountId,
                metadata,
                baseUrl,
            });
        } else {
            const {
                title,
                subtitle,
                primaryColor,
                greeting,
                newMessagePlaceholder,
                authMessage,
                emailInputPlaceholder,
                newMessagesNotificationText,
                requireEmailUpfront,
                requireAuth,
                showAgentAvailability,
                agentAvailableText,
                agentUnavailableText,
            } = this.props;
            const current = [
                title,
                subtitle,
                primaryColor,
                greeting,
                newMessagePlaceholder,
                authMessage,
                emailInputPlaceholder,
                newMessagesNotificationText,
                requireEmailUpfront,
                requireAuth,
                showAgentAvailability,
                agentAvailableText,
                agentUnavailableText,
            ];
            const prev = [
                prevProps.title,
                prevProps.subtitle,
                prevProps.primaryColor,
                prevProps.greeting,
                prevProps.newMessagePlaceholder,
                prevProps.authMessage,
                prevProps.emailInputPlaceholder,
                prevProps.newMessagesNotificationText,
                prevProps.requireEmailUpfront,
                prevProps.requireAuth,
                prevProps.showAgentAvailability,
                prevProps.agentAvailableText,
                prevProps.agentUnavailableText,
            ];
            const shouldUpdate = current.some((value, idx) => {
                return value !== prev[idx];
            });

            // Send updates to iframe if props change. (This is mainly for use in
            // the demo and "Getting Started" page, where users can play around with
            // customizing the chat widget to suit their needs)
            if (shouldUpdate) {
                const metadata = JSON.stringify({...getUserInfo(), ...customer});
                this.handleConfigUpdated({
                    accountId,
                    title,
                    subtitle,
                    metadata,
                    primaryColor,
                    baseUrl,
                    greeting,
                    newMessagePlaceholder,
                    authMessage,
                    emailInputPlaceholder,
                    newMessagesNotificationText,
                    agentAvailableText,
                    agentUnavailableText,
                    requireEmailUpfront: requireEmailUpfront ? 1 : 0,
                    requireAuth: requireAuth ? 1 : 0,
                    showAgentAvailability: showAgentAvailability ? 1 : 0,
                });
            }
        }
    };

    customerChanged = (prevCustomer, currentCustomer) => {
        if ((prevCustomer && !currentCustomer) || (!prevCustomer && currentCustomer)) {
            return true;
        } else if (currentCustomer === prevCustomer) {
            return false;
        }
        return prevCustomer.external_id && currentCustomer.external_id && prevCustomer.external_id !== currentCustomer.external_id;
    };

    getDefaultTitle = (settings) => {
        const {title, setDefaultTitle} = this.props;

        if (setDefaultTitle && typeof setDefaultTitle === 'function') {
            return setDefaultTitle(settings);
        } else {
            return settings.title || title;
        }
    };

    getDefaultSubtitle = (settings) => {
        const {subtitle, setDefaultSubtitle} = this.props;

        if (setDefaultSubtitle && typeof setDefaultSubtitle === 'function') {
            return setDefaultSubtitle(settings);
        } else {
            return settings.subtitle || subtitle;
        }
    };

    getDefaultGreeting = (settings) => {
        const {greeting, setDefaultGreeting} = this.props;

        if (setDefaultGreeting && typeof setDefaultGreeting === 'function') {
            return setDefaultGreeting(settings);
        } else {
            return settings.greeting || greeting;
        }
    };

    setIframeRef = (el) => {
        this.iframeRef = el;
    };

    getIframeUrl = () => {
        return this.props.iframeUrlOverride || DEFAULT_IFRAME_URL;
    };

    handleConfigUpdated = (updates) => {
        this.setState({
            config: {
                ...this.state.config,
                ...updates,
            },
        });

        this.send('config:update', updates);
    };

    handleCustomerIdUpdated = (id) => {
        const cachedCustomerId = this.storage.getCustomerId();
        const customerId = id || cachedCustomerId;
        const config = {...this.state.config, customerId};

        // TODO: this is a slight hack to force a refresh of the chat window
        this.setState({
            config,
            query: stringify(config, {skipEmptyString: true, skipNull: true}),
        });

        this.logger.debug('Updated customer ID:', customerId);
    };

    fetchWidgetSettings = async () => {
        const {accountId, baseUrl} = this.props;
        const empty = {};

        return fetchWidgetSettings(accountId, baseUrl)
            .then((settings) => settings || empty)
            .catch(() => empty);
    };

    customEventHandlers = (event) => {
        if (!event || !event.type) {
            return null;
        }

        const {type, detail} = event;

        switch (type) {
            case 'papercups:open':
                return this.handleOpenWidget();
            case 'papercups:close':
                return this.handleCloseWidget();
            case 'papercups:toggle':
                return this.handleToggleOpen();
            case 'storytime:customer:set':
                return this.handleCustomerIdUpdated(detail); // TODO: test this!
            default:
                return null;
        }
    };

    postMessageHandlers = (msg) => {
        this.logger.debug('Handling in parent:', msg.data);
        const iframeUrl = this.getIframeUrl();
        const {origin} = new URL(iframeUrl);

        if (msg.origin !== origin) {
            return null;
        }

        const {event, payload = {}} = msg.data;

        switch (event) {
            case 'chat:loaded':
                return this.handleChatLoaded();
            case 'customer:created':
            case 'customer:updated':
                return this.handleCacheCustomerId(payload);
            case 'conversation:join':
                return this.sendCustomerUpdate(payload);
            case 'message:received':
                return this.handleMessageReceived(payload);
            case 'message:sent':
                return this.handleMessageSent(payload);
            case 'messages:unseen':
                return this.handleUnseenMessages(payload);
            case 'messages:seen':
                return this.handleMessagesSeen();
            case 'papercups:open':
            case 'papercups:close':
                return this.handleToggleOpen();
            case 'papercups:auth':
                return this.navigateToAuth();
            default:
                return null;
        }
    };

    send = (event, payload) => {
        this.logger.debug('Sending from parent:', {event, payload});
        const el = this.iframeRef;

        if (!el) {
            this.logger.error(`Attempted to send event ${event} with payload ${JSON.stringify(
                payload
            )} before iframeRef was ready`);
            return;
        }

        el.contentWindow.postMessage({event, payload}, this.getIframeUrl());
    };

    handleMessageReceived = (message) => {
        const {onMessageReceived = noop} = this.props;
        const {user_id: userId, customer_id: customerId} = message;
        const isFromAgent = !!userId && !customerId;

        // Only invoke callback if message is from agent, because we currently track
        // `message:received` events to know if a message went through successfully
        if (isFromAgent) {
            onMessageReceived && onMessageReceived(message);
        }
    };

    handleMessageSent = (message) => {
        const {onMessageSent = noop} = this.props;

        onMessageSent && onMessageSent(message);
    };

    handleUnseenMessages = (payload) => {
        this.logger.debug('Handling unseen messages:', payload);

        this.setState({shouldDisplayNotifications: true});
        this.send('notifications:display', {shouldDisplayNotifications: true});
    };

    handleMessagesSeen = () => {
        this.logger.debug('Handling messages seen');

        this.setState({shouldDisplayNotifications: false});
        this.storage.setPopupSeen(true);
        this.send('notifications:display', {shouldDisplayNotifications: false});
    };

    shouldOpenByDefault = () => {
        const {
            defaultIsOpen,
            isOpenByDefault,
            persistOpenState,
            canToggle,
        } = this.props;

        if (!canToggle) {
            return true;
        }

        const isOpenFromCache = this.storage.getOpenState();

        if (persistOpenState) {
            return isOpenFromCache;
        }

        return !!(isOpenByDefault || defaultIsOpen);
    };

    handleChatLoaded = () => {
        this.setState({isLoaded: true});

        if (this.state.needOpen) {
            this.handleToggleOpen();
        }

        const {popUpInitialMessage, onChatLoaded = noop} = this.props;

        if (onChatLoaded && typeof onChatLoaded === 'function') {
            onChatLoaded();
        }

        if (this.shouldOpenByDefault()) {
            this.setState({isOpen: true}, () => this.emitToggleEvent(true));
        }

        if (popUpInitialMessage && !this.storage.getPopupSeen()) {
            const t =
                typeof popUpInitialMessage === 'number' ? popUpInitialMessage : 0;

            setTimeout(() => {
                this.setState({shouldDisplayNotifications: true});
                this.send('notifications:display', {
                    shouldDisplayNotifications: true,
                    // TODO: this may not be necessary
                    popUpInitialMessage: true,
                });
            }, t);
        }
    };

    formatCustomerMetadata = () => {
        const {customer = {}} = this.props;

        if (!customer) {
            return {};
        }

        return Object.keys(customer).reduce((acc, key) => {
            if (key === 'metadata') {
                return {...acc, [key]: customer[key]};
            } else {
                // Make sure all other passed-in values are strings
                return {...acc, [key]: String(customer[key])};
            }
        }, {});
    };

    sendCustomerUpdate = (payload) => {
        const {customerId} = payload;
        const customerBrowserInfo = getUserInfo(window);
        const metadata = {...customerBrowserInfo, ...this.formatCustomerMetadata()};

        return this.send('customer:update', {customerId, metadata});
    };

    handleCacheCustomerId = (payload) => {
        const {customerId} = payload;

        // Let other modules know that the customer has been set
        this.logger.debug('Caching customer ID:', customerId);
        window.dispatchEvent(
            new CustomEvent('papercups:customer:set', {
                detail: customerId,
            })
        );

        this.storage.setCustomerId(customerId);
    };

    emitToggleEvent = (isOpen) => {
        this.send('papercups:toggle', {isOpen});

        const {
            persistOpenState = false,
            onChatOpened = noop,
            onChatClosed = noop,
        } = this.props;

        if (persistOpenState) {
            this.storage.setOpenState(isOpen);
        }

        if (isOpen) {
            onChatOpened && onChatOpened();
        } else {
            onChatClosed && onChatClosed();
        }
    };

    handleOpenWidget = () => {
        if (!this.props.canToggle || this.state.isOpen) {
            return;
        }

        if (this.state.shouldDisplayNotifications) {
            this.setState({isTransitioning: true}, () => {
                setTimeout(() => {
                    this.setState({isOpen: true, isTransitioning: false}, () =>
                        this.emitToggleEvent(true)
                    );
                }, 200);
            });
        } else {
            this.setState({isOpen: true}, () => this.emitToggleEvent(true));
        }
    };

    handleCloseWidget = () => {
        if (!this.props.canToggle || !this.state.isOpen) {
            return;
        }

        this.setState({isOpen: false}, () => this.emitToggleEvent(false));
    };

    handleToggleOpen = () => {
        const {isOpen: wasOpen, isLoaded, shouldDisplayNotifications} = this.state;
        const isOpen = !wasOpen;

        // Prevent opening the widget until everything has loaded
        if (!isLoaded || !this.props.canToggle) {
            this.setState({needOpen: true});
            return;
        }

        if (!wasOpen && shouldDisplayNotifications) {
            this.setState({isTransitioning: true, needOpen: false}, () => {
                setTimeout(() => {
                    this.setState({isOpen, isTransitioning: false}, () =>
                        this.emitToggleEvent(isOpen)
                    );
                }, 200);
            });
        } else {
            this.setState({isOpen, needOpen: false}, () => this.emitToggleEvent(isOpen));
        }
    };

    navigateToAuth = () => {
        const {authUrl, onClickAuth} = this.props;

        if (onClickAuth && typeof onClickAuth === 'function') {
            onClickAuth();
        } else if (authUrl) {
            window.location.replace(authUrl);
        }
        this.handleCloseWidget();
    };

    render() {
        const {
            isOpen,
            isLoaded,
            needOpen,
            query,
            config,
            shouldDisplayNotifications,
            isTransitioning,
        } = this.state;
        const {
            customIconUrl,
            hideOutsideWorkingHours = false,
            children,
        } = this.props;
        const {primaryColor, iconVariant} = config;

        if (!query) {
            return null;
        }

        if (hideOutsideWorkingHours && config.isOutsideWorkingHours) {
            return null;
        }

        const iframeUrl = this.getIframeUrl();
        const isActive = (isOpen || shouldDisplayNotifications) && !isTransitioning;
        const sandbox = [
            // Allow scripts to load in iframe
            'allow-scripts',
            // Allow opening links from iframe
            'allow-popups',
            // Needed to access localStorage
            'allow-same-origin',
            // Allow form for message input
            'allow-forms',
            // Allow downloads files
            'allow-downloads',
        ].join(' ');

        return (
            <React.Fragment>
                {children({
                    sandbox,
                    isLoaded,
                    needOpen,
                    isActive,
                    isOpen,
                    isTransitioning,
                    customIconUrl,
                    iframeUrl,
                    query,
                    primaryColor,
                    iconVariant,
                    shouldDisplayNotifications,
                    setIframeRef: this.setIframeRef,
                    onToggleOpen: this.handleToggleOpen,
                })}
            </React.Fragment>
        );
    };
}

export default ChatWidgetContainer;
