import React, { useContext } from 'react';
import { SiteContext } from '@homeplay/app';
import Modal from './index';
import Button from '../UI/Button';
import ButtonSecondary from '../UI/ButtonSecondary';

const NeedVerifyAccountModal = ({ onClose }) => {
  const { siteName } = useContext(SiteContext);

  return (
    <Modal modalClose={onClose}>
      <div>
        <h3>Registration</h3>
        <div className="text-left">
          You can withdraw your funds after signing up. To keep your money safe,
          please register and verify your account.
          <br /> Thank you!
        </div>

        <Button
          navPath={`/${siteName ? siteName + '/' : ''}auth`}
          customClass="d-block"
          state={{ from: 'cashout' }}
        >
          Register
        </Button>
        <ButtonSecondary
          value="Do it later"
          customClass="d-block"
          onClick={onClose}
        />
      </div>
    </Modal>
  );
};

export default NeedVerifyAccountModal;
