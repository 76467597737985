import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNotification } from './useNotification';

export const useDepositGlobalNotification = () => {
  const {
    depositNotification,
    operationCancelledNotification,
    errorNotification,
  } = useNotification();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const status = searchParams.get('status');

    if (!status) return;

    const amount = Number(searchParams.get('amount'));

    switch (status) {
      case 'complete':
        amount && depositNotification(amount);
        break;
      case 'canceled':
        operationCancelledNotification();
        break;
      default:
        errorNotification();
    }

    window.history.replaceState({}, '', window.location.pathname);
  }, []);
};
