import React from 'react';
import { GamesList, ProductPage } from '@homeplay/components';
import classes from './NetEnt.module.scss';
import { selectNetEntGames } from '@homeplay/app';
import netEntLogo from '../../../../shared/assets/images/netenttemp-logo.svg';
import { useSelector } from 'react-redux';

const NetEnt = () => {
  const netEntGames = useSelector(selectNetEntGames);

  const getGameLink = (loadParams) => {
    const searchParams = [
      `gameId=${encodeURIComponent(loadParams.game_id)}`,
      `gameLocation=${encodeURIComponent(loadParams.game_location)}`,
      `server=${encodeURIComponent(loadParams.game_server)}`,
      `sessId=${encodeURIComponent(loadParams.session_id)}`,
      `lobbyURL=${encodeURIComponent(`${window.location.origin}/exit`)}`,
      'applicationType=browser',
      'disableDeviceDetection=true',
      'showHomeButton=true',
      'lang=en',
    ];

    return `${process.env.REACT_APP_NT_BASE_URL}/${loadParams.path}?${searchParams.join('&')}`;
  };

  return (
    <ProductPage getGameLink={getGameLink} customClass={classes.NetEnt}>
      <div className="container">
        <img
          className={classes.Logo}
          src={netEntLogo}
          alt="NetEnt"
          width="250"
          height="88"
        />
        {netEntGames && <GamesList games={netEntGames} />}
        <p className={classes.Info}>
          We are working hard to bring your favourite NetEnt games here.
        </p>
        <p className={classes.Info + ' ' + classes.Light}>
          Watch this space as more games are coming soon!
        </p>
      </div>
    </ProductPage>
  );
};

export default NetEnt;
