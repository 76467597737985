import React from 'react';
import classes from './LcGameItem.module.scss';

const BjSeats = ({seats}) => {
    const seatsItems = seats.map((seat, index) => (
        <li key={index}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 36" fill="none">
                {!seat && <path d="M25 36C38.8 36 50 27.9 50 18 50 8.1 38.8 0 25 0 11.2 0 0 8.1 0 18 0 27.9 11.2 36 25 36Z" className="hoverstate" style={{fillOpacity: 0.3, fill: 'white'}}/>}
                <path d="M29.6 10.5C29.6 12.9 27.6 14.9 25 14.9 22.4 14.9 20.4 12.9 20.4 10.5 20.4 8 22.4 6 25 6 27.6 6 29.6 8 29.6 10.5ZM34 23.9V23C34 19.9 32 17.3 29.3 16.2 28 16.8 26.5 17.1 25 17.1 23.5 17.1 22 16.8 20.7 16.2 18 17.3 16 19.9 16 23V23.9C16.5 24.6 17 25.4 17.7 26H19.6V27.5C21 28.4 22.6 28.9 24.3 29H25.7C27.4 28.9 29 28.4 30.4 27.5V26H32.3C33 25.4 33.5 24.7 34 23.9Z" style={seat ? {fillOpacity: 0.8, fill: '#565656'} : {fillOpacity: 0.87, fill: 'white'}}/>
            </svg>
        </li>
    ))

    return (
        <ul className={classes.BjSeats}>
            {seatsItems}
        </ul>
    );
};

export default BjSeats;
