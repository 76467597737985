import React from 'react';
import { selectUnitToken } from '@homeplay/app';
import { Frame } from '@homeplay/components';
import { useSelector } from 'react-redux';

const RLN = () => {
  const unitToken = useSelector(selectUnitToken);
  const rlnSrc = unitToken
    ? `${process.env.REACT_APP_RLN_BASE_URL}?token=${unitToken}&mode=home_play&exit=${window.location.origin}/exit`
    : null;

  return (
    <Frame frameSrc={rlnSrc} frameTitle="Game Frame" allowScrolling={true} />
  );
};

export default RLN;
