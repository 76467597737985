import { useSelector } from 'react-redux';
import { selectUserVerificationLevel } from '@homeplay/app';
import { PassedIcon } from '@homeplay/components/src/UI/icons';
import { VerificationButton } from '../VerificationButton';
import classes from './VerificationStatus.module.scss';

const VerificationStatus = () => {
  const currentVerificationLevel = useSelector(selectUserVerificationLevel);

  switch (currentVerificationLevel) {
    case 1:
      return <VerificationButton requiredVerificationLevel={2} />;

    case 2:
      return (
        <div className={`${classes.statusWrapper} color-primary semi-bold`}>
          <span>Standard</span>
          <PassedIcon />
          <VerificationButton requiredVerificationLevel={3} />
        </div>
      );

    case 3:
      return (
        <div className={`${classes.statusWrapper} color-primary semi-bold`}>
          <span>Advanced</span>
          <PassedIcon />
        </div>
      );

    default:
      return 'Unknown';
  }
};

export default VerificationStatus;
