import React, { useContext, useEffect, useRef, useState } from 'react';
import { Page } from '@homeplay/components';
import Button from '@homeplay/components/src/UI/Button';
import { setMaskForPhone } from '@homeplay/utils';
import Input from '@homeplay/components/src/UI/Input';
import classes from '../UserAccount.module.scss';
import { SiteContext } from '@homeplay/app';

const EnterRegistrationData = ({ phoneNumber, onSubmit, onClickBack }) => {
  const { isTouchUnit, screenKeyboard } = useContext(SiteContext);
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const focusInput = useRef();

  useEffect(() => {
    if (focusInput.current) {
      focusInput.current.focus();
    }
  }, []);

  const isInputsValid = () => {
    return password.length > 7 && firstName.length > 1 && lastName.length > 1;
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    onSubmit(password, { firstName, lastName });
    setPassword('');
  };

  return (
    <Page
      headerContent={'Create User Account'}
      customClass={
        classes.Auth +
        (isTouchUnit && !!screenKeyboard ? ' screen-keyboard' : '')
      }
      onClickBack={onClickBack}
    >
      <div className="container">
        <div>
          <h4>Welcome</h4>
          <p className="value">{setMaskForPhone(phoneNumber)}</p>

          <form name="form" onSubmit={onSubmitHandler}>
            <input
              className="d-none"
              type="tel"
              id="id"
              value={phoneNumber}
              autoComplete="id"
              readOnly
            />

            <label className="label" htmlFor="firstName">
              Your First Name
            </label>
            <Input
              refLink={focusInput}
              type="text"
              id="firstName"
              placeholder="Enter Your First Name"
              onChange={setFirstName}
              value={firstName}
              pattern="^[a-zA-Z\d\-\s]+$"
              error={!firstName.length}
              autocomplete="given-name"
            />

            <label className="label" htmlFor="lastName">
              Your Last Name
            </label>
            <Input
              type="text"
              id="lastName"
              placeholder="Enter Your Last Name"
              onChange={setLastName}
              value={lastName}
              pattern="^[a-zA-Z\d\-\s]+$"
              error={!lastName.length}
              autocomplete="family-name"
            />

            <label className="label" htmlFor="lastName">
              Your Password
            </label>
            <Input
              id="password"
              type="password"
              placeholder="Type your new password"
              onChange={setPassword}
              value={password}
              error={password.length < 8}
              infoMessage="Password must be at least 8 characters long"
              autocomplete="new-password"
            />

            <Button disabled={!isInputsValid()} customClass="d-block">
              Submit
            </Button>
          </form>
        </div>
      </div>
    </Page>
  );
};

export default EnterRegistrationData;
