import React from 'react';
import classes from './WhatsAppLink.module.scss';
import whatsAppImg from '../../../../shared/assets/images/whatsapp.png';

const WhatsAppLink = ({phoneNumber}) => {
    return (
        <a href={`https://api.whatsapp.com/send?phone=${phoneNumber}`}
           rel="noopener noreferrer"
           target="_blank"
           className={classes.WhatsApp}
        >
            <img src={whatsAppImg} alt="WhatsApp" width="50" height="50"/>
        </a>
    );
};

export default React.memo(WhatsAppLink);
