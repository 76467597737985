import React from 'react';
import classes from './CashInTerminal.module.scss';

const Key = ({ value, handleClick, disabled, customClass }) => (
  <div
    className={`${classes.Key}${customClass ? ' ' + customClass : ''}${disabled ? ` ${classes.Disabled} disabled` : ''}`}
    onClick={!disabled ? handleClick : null}
  >
    {value}
  </div>
);

export default React.memo(Key);
