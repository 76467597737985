import React, { useContext, useEffect, useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import {
  SiteContext,
  selectIsCongratsBanner,
  setIsCongratsBanner,
} from '@homeplay/app';
import Loader from '../UI/Loader';
import classes from './Frame.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import CongratsBanner from '../HappyHour/CongratsBanner';
import { CSSTransition } from 'react-transition-group';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';

const Frame = ({
  frameSrc,
  frameTitle,
  allowScrolling = false,
  allowFullScreen = false,
}) => {
  const dispatch = useDispatch();
  const isCongratsBanner = useSelector(selectIsCongratsBanner);
  const { siteName, enableFullScreen } = useContext(SiteContext);
  const [redirect, setRedirect] = useState(false);
  const [fullScreenClick, setFullScreenClick] = useState(false);
  const location = useLocation();
  let exitUrl;
  const handleFullScreen = useFullScreenHandle();

  if (location.state) {
    exitUrl = location.state.exitUrl;
  } else {
    exitUrl = window.location.origin + `/${siteName}`;
  }

  const onMessageRecive = (event) => {
    if (event.data) {
      try {
        if (event.data.command && event.data.command === 'exitWebnovomatic') {
          exitGame();
        } else {
          const data = JSON.parse(event.data);
          if (data.event && data.event.name === 'gameClosing') {
            exitGame();
          }
        }
      } catch (e) {
        return false;
      }
    }
  };

  const exitGame = () => {
    setRedirect(true);
  };

  useEffect(() => {
    window.addEventListener('message', onMessageRecive, false);
    return () => {
      window.removeEventListener('message', onMessageRecive, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFullScreenClick = () => {
    handleFullScreen.enter();
    setFullScreenClick(true);
  };

  const onChangeFullScreen = (state) => {
    setFullScreenClick(state);
  };

  if (redirect) {
    return <Navigate to={new URL(exitUrl).pathname} />;
  }
  return (
    <main className={classes.FrameContainer}>
      {frameSrc ? (
        <FullScreen handle={handleFullScreen} onChange={onChangeFullScreen}>
          <iframe
            id="game-frame"
            src={frameSrc}
            title={frameTitle}
            className={classes.Frame}
            allowFullScreen={allowFullScreen}
            scrolling={allowScrolling ? 'yes' : 'no'}
          />

          {enableFullScreen &&
            !allowFullScreen &&
            !handleFullScreen.active &&
            !fullScreenClick && (
              <div
                onClick={handleFullScreenClick}
                className={classes.FullScreen}
              />
            )}
          <CSSTransition
            in={isCongratsBanner}
            timeout={0}
            classNames="modal"
            unmountOnExit
          >
            <CongratsBanner
              onClick={() => dispatch(setIsCongratsBanner({ value: false }))}
            />
          </CSSTransition>
        </FullScreen>
      ) : (
        <Loader />
      )}
    </main>
  );
};

export default Frame;
