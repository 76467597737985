import React from 'react';
import classes from './NewLabel.module.scss';
import starImg from '../../../../../shared/assets/images/star.png';
import newImg from '../../../../../shared/assets/images/new.png';

const NewLabel = () => {

    return (
        <div className={classes.NewLabel + " new"} data-testid="label-new">
            <img src={starImg} alt="" width="41" height="42"/>
            <img className={classes.Text} src={newImg} alt="" width="55" height="28"/>
            <span className={classes.Star1}><span/></span>
            <span className={classes.Star2}><span/></span>
        </div>
    );
};

export default React.memo(NewLabel);
