import { getValidPhoneNumber } from '@homeplay/utils';
import axios from './axiosSRP';

export const usersUnit = async (accessToken, siteName, isLandBased) => {
  let localStorageParamName = siteName;
  let siteSlug = siteName;
  if (!siteName) {
    localStorageParamName = 'hp-token';
    siteSlug = process.env.REACT_APP_SITE_NAME;
  }
  let usersUnitToken = await getUsersUnit(accessToken, siteSlug);
  if (!usersUnitToken) {
    const unitToken = localStorage.getItem(localStorageParamName);
    if (!unitToken || unitToken.length < 100) {
      usersUnitToken = await createUsersUnit(
        accessToken,
        siteSlug,
        isLandBased
      );
    } else {
      usersUnitToken = await assignUnitToUser(
        accessToken,
        unitToken,
        isLandBased
      );
    }
    localStorage.removeItem(localStorageParamName);
  }
  return usersUnitToken;
};

export const getAnonymousUnit = async (siteName, isLandBased) => {
  let unitToken = localStorage.getItem(siteName ? siteName : 'hp-token');
  if (!unitToken || unitToken.length < 100) {
    unitToken = await createUnit(
      siteName ? siteName : process.env.REACT_APP_SITE_NAME,
      isLandBased
    );
  }
  return unitToken;
};

export const getUsersUnit = async (accessToken, siteName) => {
  try {
    const params = {
      site_slug: siteName,
    };
    const { data } = await axios.get('hp/users/units', {
      params,
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data.unit_token;
  } catch (error) {
    if (
      !error.response ||
      !error.response.data ||
      !error.response.data.error ||
      error.response.data.error.code !== 'units_ctx:units:not_found'
    ) {
      throw error;
    }
  }
  return null;
};

export const assignUnitToUser = async (accessToken, unitToken, isLandBased) => {
  const params = {
    unit_token: unitToken,
    device_type: isLandBased ? 'land_based' : 'online',
  };
  const { data } = await axios.post('hp/users/units/link', params, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return data.unit_token;
};

export const getUnitParams = async (unitToken, menuName = 'homeplay') => {
  const params = {
    token: unitToken,
  };
  if (menuName) {
    params.menu_slug = menuName;
  }
  const { data } = await axios.get('hp/units/lobby', { params });
  return data.unit;
};

export const getGameParams = async (
  unitToken,
  gameSlug,
  exitUrl,
  isLandBased
) => {
  const params = {
    token: unitToken,
    game_slug: gameSlug,
    lobby_url: exitUrl,
    device_type: isLandBased ? 'land_based' : 'online',
  };
  const { data } = await axios.get('hp/units/games/show', { params });
  return data;
};

export const sendCashOutOtp = async (unitToken, phone) => {
  const params = {
    token: unitToken,
    phone: getValidPhoneNumber(phone),
  };
  const { data } = await axios.post('hp/send_otp', params);
  return data;
};

export const confirmCashOutOtp = async (unitToken, phone, otpCode) => {
  const params = {
    token: unitToken,
    phone: getValidPhoneNumber(phone),
    otp: otpCode,
  };
  const { data } = await axios.post('hp/confirm_phone', params);
  return data;
};

export const preCheckCashOutATM = async (
  unitToken,
  phoneToken,
  firstName,
  lastName,
  amount
) => {
  const params = {
    token: unitToken,
    first_name: firstName,
    last_name: lastName,
    amount: '' + amount,
  };

  if (phoneToken) {
    params.phone_token = phoneToken;
  }

  const { data } = await axios.post('hp/im_withdrawals/pre_check', params);
  return data;
};

export const confirmCashOutATMRequest = async (
  unitToken,
  phoneToken,
  firstName,
  lastName,
  amount
) => {
  const params = {
    token: unitToken,
    first_name: firstName,
    last_name: lastName,
    amount: '' + amount,
  };

  if (phoneToken) {
    params.phone_token = phoneToken;
  }

  const { data } = await axios.post('hp/im_withdrawals/create', params);
  return data;
};

export const preCheckCashOutEFT = async (
  unitToken,
  phoneToken,
  firstName,
  lastName,
  amount,
  bankName,
  bankAccountNumber,
  bankAccountHolderName
) => {
  const params = {
    token: unitToken,
    first_name: firstName,
    last_name: lastName,
    amount: '' + amount,
    bank_name: bankName,
    bank_account_number: bankAccountNumber,
    bank_account_holder_name: bankAccountHolderName,
  };

  if (phoneToken) {
    params.phone_token = phoneToken;
  }

  const { data } = await axios.post('hp/eft_withdrawals/pre_check', params);
  return data;
};

export const confirmCashOutEFTRequest = async (
  unitToken,
  phoneToken,
  firstName,
  lastName,
  amount,
  bankName,
  bankAccountNumber,
  bankAccountHolderName
) => {
  const params = {
    token: unitToken,
    first_name: firstName,
    last_name: lastName,
    amount: '' + amount,
    bank_name: bankName,
    bank_account_number: bankAccountNumber,
    bank_account_holder_name: bankAccountHolderName,
  };

  if (phoneToken) {
    params.phone_token = phoneToken;
  }

  const { data } = await axios.post('hp/eft_withdrawals/create', params);
  return data;
};

export const cashOutTicket = async (unitToken) => {
  const { data } = await axios.post('hp/units/ticket_cash_out', {
    token: unitToken,
  });
  let ticket = null;

  if (data.fin_operation.gs_ticket) {
    ticket = {
      code: data.fin_operation.gs_ticket.code,
      value: data.fin_operation.gs_ticket.value,
    };
  }
  return {
    balance: data.unit.fin_account.balance,
    depositBalance: data.unit.fin_account.deposit_balance || 0,
    status: data.fin_operation.status,
    ticket,
  };
};

export const cashInTicket = async (unitToken, ticketCode) => {
  const { data } = await axios.post('hp/units/ticket_cash_in', {
    token: unitToken,
    code: ticketCode,
  });

  return {
    balance: data.unit.fin_account.balance,
    depositBalance: data.unit.fin_account.deposit_balance || 0,
    depositAmount: data.fin_operation.gs_ticket?.value,
    status: data.fin_operation.status,
  };
};

export const cashInAcoin = async (unitToken, voucherCode) => {
  let response = await axios.post('hp/units/acoin_cash_in', {
    token: unitToken,
    code: voucherCode,
  });
  return {
    balance: response.data.unit.fin_account.balance,
    depositBalance: response.data.unit.fin_account.deposit_balance || 0,
  };
};

export const startAcoinCashIn = async (unitToken, voucherCode) => {
  let response = await axios.post('hp/acoin/start_loading', {
    token: unitToken,
    code: voucherCode,
  });

  const result = {
    amount: response.data.acoin_batch.total_amount,
    expires: response.data.acoin_batch.expires,
    transactionToken: response.data.acoin_batch_token,
  };

  localStorage.setItem(
    'hp-pending-redemption',
    JSON.stringify({
      token: result.transactionToken,
      expires: result.expires,
    })
  );

  return result;
};

export const startAcoinPhoneRedemption = async (
  unitToken,
  phoneNumber,
  siteName
) => {
  const url = siteName
    ? `${process.env.REACT_APP_BASE_URL}/${siteName}`
    : process.env.REACT_APP_BASE_URL;
  const cancelUrl = url + '/cashin';
  let response = await axios.post('hp/acoin/start_phone_redemption', {
    token: unitToken,
    phone: phoneNumber,
    success_url: url,
    cancel_url: cancelUrl,
    error_url: cancelUrl,
  });

  return {
    confirmationUrl: response.data.acoin_phone_redemption.confirmation_url,
    status: response.data.acoin_phone_redemption.status,
  };
};

export const cancelAcoinCashIn = async (unitToken, transactionToken) => {
  let response = await axios.post('hp/acoin/cancel_loading', {
    token: unitToken,
    acoin_tx_token: transactionToken,
  });
  localStorage.removeItem('hp-pending-redemption');
  return response.success;
};

export const completeAcoinCashIn = async (
  unitToken,
  transactionToken,
  amount
) => {
  const params = {
    token: unitToken,
    acoin_tx_token: transactionToken,
  };
  if (amount && amount > 0) {
    params.amount = amount;
  }
  let response = await axios.post('hp/acoin/complete_loading', params);
  localStorage.removeItem('hp-pending-redemption');
  return {
    balance: response.data.unit.fin_account.balance,
    depositBalance: response.data.unit.fin_account.deposit_balance || 0,
  };
};

export const cancelAcoinPendingRedemption = async (unitToken) => {
  let redemptionJSON = localStorage.getItem('hp-pending-redemption');
  if (!redemptionJSON) {
    return;
  }
  try {
    const redemption = JSON.parse(redemptionJSON);
    if (
      redemption.token &&
      redemption.expires &&
      redemption.expires * 1000 > Date.now()
    ) {
      cancelAcoinCashIn(unitToken, redemption.token);
    }
  } catch (e) {
    console.log('Error parse hp-pending-redemption');
  }
  localStorage.removeItem('hp-pending-redemption');
};

export const getFafiParams = async (unitToken, isLandBased) => {
  const params = {
    token: unitToken,
    device_type: isLandBased ? 'land_based' : 'online',
  };
  const { data } = await axios.get('hp/units/games/fafi', { params });
  return data.load_params;
};

const createUsersUnit = async (accessToken, siteName, isLandBased) => {
  const params = {
    site_slug: siteName,
    device_type: isLandBased ? 'land_based' : 'online',
  };
  const { data } = await axios.post('hp/users/units/create', params, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return data.unit_token;
};

const createUnit = async (siteName, isLandBased) => {
  const params = {
    site_slug: siteName,
    device_type: isLandBased ? 'land_based' : 'online',
  };
  const { data } = await axios.post('hp/units/create', params);
  return data.unit_token;
};

export const getTransactions = async (unitToken, cursor) => {
  const params = {
    token: unitToken,
    types: [
      'load_gs_ticket',
      'unload_gs_ticket',
      'load_acoin',
      'load_acoin_by_phone',
      'request_withdrawal',
      'request_eft_withdrawal',
      'im_withdrawal_cancellation',
      'eft_withdrawal_cancellation',
      'unload_gs_ticket_rollback',
    ],
    page_size: 10,
  };
  if (cursor) {
    params.cursor = cursor;
  }
  const { data } = await axios.get('transactions/unit', { params });
  return data;
};
