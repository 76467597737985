import React, { useEffect, useRef, useState } from 'react';
import { setMaskForPhone } from '@homeplay/utils';
import OTPInput from '../UI/Input/OTPInput';
import Button from '../UI/Button';

const CashOutOtp = ({
  user,
  phoneNumber,
  onSubmit,
  onClickResend,
  onClickEdit,
}) => {
  const [otpCode, setOtpCode] = useState('');
  const [counter, setCounter] = useState(0);
  const focusInput = useRef();
  const [error, setError] = useState(false);

  useEffect(() => {
    setCounter(300);
    if (focusInput.current) {
      focusInput.current.focus();
    }
  }, []);

  useEffect(() => {
    let timerId;
    if (counter > 0) {
      timerId = setTimeout(() => setCounter((counter) => counter - 1), 1000);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [counter]);

  const isInputsValid = () => {
    return otpCode.length === 4;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isInputsValid()) {
      const isSuccess = await onSubmit(otpCode);
      setError(!isSuccess);
    }
  };

  const handleClickResend = () => {
    onClickResend();
    setCounter(300);
    if (error) {
      setError(false);
    }
  };

  return (
    <div className="container">
      <p>
        To complete your transaction <br /> enter the 4 digit code sent to
      </p>

      {user ? (
        <p className="value">{setMaskForPhone(phoneNumber)}</p>
      ) : (
        <span onClick={onClickEdit} className="phone">
          <span>{setMaskForPhone(phoneNumber)}</span>
          <svg
            width="21"
            height="18"
            viewBox="0 0 21 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="fill-primary"
              d="M15.8917 6.348L17.0772 7.42125L6.59601 16.923L0.701904 18L1.89231 12.6547L12.3693 3.15375L13.5531 4.227L3.41526 13.395L2.81468 16.083L5.77952 15.5408L15.8917 6.348ZM15.8454 0L13.5167 2.11275L18.2246 6.38175L20.5549 4.266L15.8454 0V0ZM5.65543 14.0385L15.014 5.5515L14.4308 5.02125L5.07223 13.5068L5.65543 14.0385Z"
            />
          </svg>
        </span>
      )}

      <form name="form" onSubmit={handleSubmit}>
        <OTPInput
          refLink={focusInput}
          id="otp"
          onChange={setOtpCode}
          value={otpCode}
          timerValue={counter}
          onClickResend={handleClickResend}
        />
        <Button disabled={!isInputsValid()} customClass="d-block">
          Submit
        </Button>
      </form>

      {error && (
        <div className="error-message">
          <h6>We have encountered an error with your transaction.</h6>
          <ul className="marked-list">
            <li>Please make sure that the phone number entered is correct.</li>
            <li>Edit your number if necessary.</li>
            <li>Press the "resend" button to receive a new OTP.</li>
            <li>Make sure that you have entered the OTP correctly.</li>
            <li>Press the "Submit" button.</li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default CashOutOtp;
