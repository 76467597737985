import React from 'react';

const SiteNotFound = () => {
  return (
    <main>
      <div className="container">
        <h2>
          Yor must enter site name in URL {process.env.REACT_APP_BASE_URL}
          /sitename <br />
          e.g. {process.env.REACT_APP_BASE_URL}/name
        </h2>
      </div>
    </main>
  );
};

export default SiteNotFound;
