import React, { useEffect, useState } from 'react';
import UnitSocketContext from './unitSocketContext';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadUnitFail,
  selectUnitToken,
  setUnitParams,
  updateUnitParams,
} from '../unitSlice';
import {
  removeHappyHourBonusState,
  resetHappyHour,
  setHappyHour,
  setHappyHourBonus,
  setHappyHourConfigs,
  updateHappyHourBonusState,
} from '../promotionsSlice';
import { UnitSocket, Channel, handleError, getUnitParams } from '@homeplay/api';
import { selectUser } from '../../index';
import { getPromoTexts } from '@homeplay/utils';

const UnitSocketProvider = ({ children, siteName, isTouchUnit }) => {
  const [unitChannel, setUnitChannel] = useState(null);
  const [promotionsChannel, setPromotionsChannel] = useState(null);

  const [timeOffset, setTimeOffset] = useState(0);
  const token = useSelector(selectUnitToken);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    let socket;
    let connectionErrorCount = 0;
    if (token) {
      socket = UnitSocket.getInstance(token, true);
      socket.onError((err) => {
        getUnitParams(token).catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error &&
            error.response.data.error.code === 'auth:unauthenticated'
          ) {
            const localStorageParamName = siteName ? siteName : 'hp-token';
            localStorage.removeItem(localStorageParamName);
            dispatch(loadUnitFail({ error: handleError(error, 'reload') }));
          } else {
            connectionErrorCount++;
            if (connectionErrorCount < 3) {
              return;
            }
            dispatch(loadUnitFail({ error: handleError(error, 'reload') }));
          }
        });
      });

      socket.connect();

      if (unitChannel) {
        unitChannel.leave();
        setUnitChannel(null);
      }
      if (promotionsChannel) {
        dispatch(resetHappyHour());
        promotionsChannel.leave();
        setPromotionsChannel(null);
      }

      connectUnitChannel(socket).then((unitChannel) =>
        setUnitChannel(unitChannel)
      );
      connectPromotionsChannel(socket).then((promotionsChannel) =>
        setPromotionsChannel(promotionsChannel)
      );
    }

    return () => {
      if (socket) {
        socket.disconnect();
      }

      if (unitChannel) {
        unitChannel.leave();
      }

      if (promotionsChannel) {
        promotionsChannel.leave();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (promotionsChannel && user) {
      promotionsChannel.push('get_hh_bonus_states').then((props) => {
        dispatch(setHappyHourBonus(getHappyHourBonus(props.hh_bonus_states)));
      });

      promotionsChannel.on('hh_bonus_state_updated', (props) => {
        if (props.hh_bonus_state) {
          if (
            props.hh_bonus_state.status === 'ongoing' ||
            props.hh_bonus_state.status === 'complete'
          ) {
            dispatch(
              updateHappyHourBonusState({
                bonusState: getBonusParams(props.hh_bonus_state),
              })
            );
          } else {
            dispatch(
              removeHappyHourBonusState({
                bonusStateId: props.hh_bonus_state.id,
              })
            );
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promotionsChannel, user]);

  const connectUnitChannel = async (socket) => {
    const channel = new Channel('unit', {
      device_type: isTouchUnit ? 'land_based' : 'online',
    });

    try {
      let gameSlugs = [
        'popular',
        'club888',
        'greendream',
        'ptc',
        'png',
        'rln',
        'fafi',
        'pp-lg',
        'op',
        'oln',
        'viso',
        'netent',
        'bonus',
        'popular-lotto',
        'aviator',
      ];
      // TODO temporary disable club888 on touchscreen
      if (isTouchUnit && process.env.REACT_APP_ENV === 'prod') {
        gameSlugs = [
          'popular',
          'greendream',
          'ptc',
          'png',
          'rln',
          'fafi',
          'pp-lg',
          'op',
          'oln',
          'viso',
          'netent',
          'bonus',
          'popular-lotto',
          'aviator',
        ];
      }

      const { props } = await channel.connect(socket);
      const { tags_games } = await channel.push('get_tagged_games', {
        slugs: gameSlugs,
      });
      if (tags_games && Object.keys(tags_games).length > 0) {
        props.games = tags_games;
        dispatch(setUnitParams(props));
      } else {
        const error = new Error('Something wrong');
        dispatch(loadUnitFail({ error: handleError(error, 'reload') }));
      }
    } catch (error) {
      dispatch(loadUnitFail({ error: handleError(error, 'reload') }));
    }

    channel.on('unit_updated', (props) => {
      dispatch(updateUnitParams(props));
    });

    if (isTouchUnit) {
      channel.on('command', (props) => {
        if (props.command && props.command === 'shutdown') {
          window.location.replace('https://www.google.com/');
        }
      });
    }

    return channel;
  };

  const connectPromotionsChannel = async (socket) => {
    const channel = new Channel('promotions');
    try {
      const { props } = await channel.connect(socket);
      if (props.promotions) {
        const promotions = props.promotions.filter(
          (promotion) => promotion.type === 'happy_hour'
        );
        const hhPromotions = promotions.map((promotion) =>
          getPromotionConfigs(promotion)
        );
        if (hhPromotions) {
          dispatch(setHappyHourConfigs(hhPromotions));
        }
      }
      if (props.now) {
        setTimeOffset(Date.now() - props.now * 1000);
      }
    } catch (error) {
      console.log('Promotions Channel connect error');
    }

    channel.push('get_ongoing_promotion_states').then((props) => {
      const happyHour = getHappyHourPromotion(props.promotion_states);
      if (happyHour) {
        channel
          .push('get_hh_bonus_state', { promotion_state_id: happyHour.id })
          .then((props) => {
            if (props.hh_bonus_state.status !== 'paid') {
              dispatch(setHappyHour({ happyHour }));
            }
          });
      }
    });

    channel.on('promotion_state_updated', (props) => {
      if (
        props.promotion_state &&
        props.promotion_state.promotion.type === 'happy_hour'
      ) {
        let happyHour = null;
        if (props.promotion_state.status === 'ongoing') {
          happyHour = getHappyHourParams(props.promotion_state);
        }
        dispatch(setHappyHour({ happyHour }));
      }
    });

    return channel;
  };

  const getPromotionConfigs = (promotion) => {
    const hhPromotion = {
      schedule: promotion.schedule,
      duration: promotion.duration * 1000,
      minDeposit: promotion.config.min_deposit,
      bonusPercentage: promotion.config.bonus_percentage,
      maxBonus: promotion.config.max_bonus,
    };
    hhPromotion.texts = getPromoTexts(
      promotion.schedule,
      promotion.duration * 1000
    );

    return hhPromotion;
  };

  const getHappyHourParams = (promotionState) => ({
    id: promotionState.id,
    end: promotionState.end * 1000 + timeOffset,
    minDeposit: promotionState.config.min_deposit,
    bonusPercentage: promotionState.config.bonus_percentage,
  });

  const getBonusParams = (bonusState) => ({
    id: bonusState.id,
    promotionId: bonusState.promotion_state.id,
    complete: bonusState.status === 'complete',
    balance: bonusState.balance,
    amount: bonusState.bonus_amount,
    end: bonusState.end * 1000 + timeOffset,
    isDeposited:
      bonusState.deposit_amount >=
      bonusState.promotion_state.config.min_deposit,
  });

  const getHappyHourPromotion = (promotionStates) => {
    for (let state of promotionStates) {
      if (state.promotion.type === 'happy_hour' && state.status === 'ongoing') {
        return getHappyHourParams(state);
      }
    }
    return null;
  };

  const getHappyHourBonus = (bonusStates) => {
    let hhBonusStates = null;
    let isComplete = false;
    if (bonusStates && bonusStates.length) {
      hhBonusStates = bonusStates.map((state) => {
        if (state.status === 'complete') {
          isComplete = true;
        }
        return getBonusParams(state);
      });
    }
    return { bonusStates: hhBonusStates, isComplete };
  };

  const acquireHappyHourBonus = (stateId) => {
    promotionsChannel.push('acquire_hh_bonus', { hh_bonus_state_id: stateId });
  };

  return (
    <UnitSocketContext.Provider
      value={{ unitChannel, promotionsChannel, acquireHappyHourBonus }}
    >
      {children}
    </UnitSocketContext.Provider>
  );
};

export default UnitSocketProvider;
