import { useContext } from 'react';
import { SiteContext } from '@homeplay/app';
import Button from '@homeplay/components/src/UI/Button';
import classes from './VerificationButton.module.scss';

export const VerificationButton = ({ requiredVerificationLevel }) => {
  const { siteName } = useContext(SiteContext);

  return (
    <Button
      navPath={`/${siteName ? siteName + '/' : ''}verification`}
      customClass={classes.verificationButton}
      state={{ requiredVerificationLevel }}
    >
      Start
    </Button>
  );
};
