import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Game } from '@homeplay/containers';
import Page from './index';

const ProductPage = (props) => {
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <Page headerContent={null} customClass={props.customClass}>
            {props.children}
          </Page>
        }
      />
      <Route
        path={'game/:gameSlug'}
        element={
          <Game
            getGameLink={props.getGameLink}
            allowScrolling={props.allowScrolling}
          />
        }
      />
      <Route path="*" element={<Navigate to=".." replace />} />
    </Routes>
  );
};

export default ProductPage;
