import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import VerifyPhone from './VerifyPhone';
import EnterPassword from './EnterPassword';
import {
  SiteContext,
  authFail,
  authStart,
  disableAuthLoading,
  login,
  resetAuthLocalError,
  selectLocalError,
  selectUser,
} from '@homeplay/app';
import {
  handleError,
  resetPasswordRequest,
  sendAuthOTP,
  setNewPassword,
} from '@homeplay/api';

const ResetPassword = ({ phoneNumber, onClickBack }) => {
  const dispatch = useDispatch();
  const otpError = useSelector(selectLocalError);
  const user = useSelector(selectUser);
  const { siteName, isTouchUnit } = useContext(SiteContext);
  const [resetStep, setResetStep] = useState(1); // 1 - OTP; 2 - Enter password
  const [phone, setPhone] = useState('');
  const [passwordToken, setPasswordToken] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!phone.length) {
      if (phoneNumber) {
        setPhone(phoneNumber);
      } else if (user && user.phone) {
        setPhone(user.phone);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (phone.length) {
      sendOTP(phone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone]);

  const sendOTP = async (phoneNumber) => {
    dispatch(authStart());
    try {
      await sendAuthOTP(phoneNumber);
      dispatch(resetAuthLocalError());
      dispatch(disableAuthLoading());
    } catch (error) {
      dispatch(authFail({ error: handleError(error) }));
      handleClickBack();
    }
  };

  const resendOtp = async (phoneNumber) => {
    dispatch(authStart());
    try {
      await sendAuthOTP(phoneNumber);
      dispatch(resetAuthLocalError());
      dispatch(disableAuthLoading());
      return true;
    } catch (error) {
      dispatch(authFail({ error: handleError(error) }));
    }
    return false;
  };

  const handleClickBack = () => {
    if (onClickBack) {
      onClickBack();
    } else {
      navigate(`/${siteName}`);
    }
  };

  const otpConfirm = async (otpCode) => {
    dispatch(authStart());
    try {
      const data = await resetPasswordRequest(phone, otpCode, siteName);
      setPasswordToken(data.password_token);
      setResetStep(2);
      if (otpError) {
        dispatch(resetAuthLocalError());
      }
      dispatch(disableAuthLoading());
    } catch (error) {
      dispatch(
        authFail({
          error: handleError(error),
          localError: true,
        })
      );
    }
  };

  const savePassword = async (password) => {
    dispatch(authStart());
    try {
      await setNewPassword(phone, passwordToken, password, siteName);
      if (user) {
        dispatch(disableAuthLoading());
      } else {
        dispatch(login(phone, password, siteName, isTouchUnit));
      }
      navigate(`/${siteName}`);
    } catch (error) {
      dispatch(authFail({ error: handleError(error) }));
    }
  };

  return (
    <React.Fragment>
      {resetStep === 1 && (
        <VerifyPhone
          title="Reset Password"
          label="To reset your password"
          phoneNumber={phone}
          onSubmit={otpConfirm}
          onClickBack={handleClickBack}
          resendOtp={resendOtp}
          otpError={otpError}
        />
      )}

      {resetStep === 2 && (
        <EnterPassword
          title={'Create Password'}
          phoneNumber={phone}
          onSubmit={savePassword}
          onClickBack={handleClickBack}
          label="Create new password"
          placeholder="Type your new password"
        />
      )}
    </React.Fragment>
  );
};

export default ResetPassword;
