import React, { useEffect } from 'react';
import ModalContainer from './ModalContainer';
import classes from './Modal.module.scss';

const Modal = ({ children, customClass, modalClose }) => {
  useEffect(() => {
    document.body.classList.add('overflow-hidden');
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  return (
    <ModalContainer>
      <div
        className={classes.ModalBody + (customClass ? ' ' + customClass : '')}
      >
        <span className={classes.Close} onClick={modalClose}>
          <svg
            width="24"
            height="38"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24 20.188L15.685 11.979L23.885 3.697L20.188 0L11.976 8.318L3.666 0.115L0 3.781L8.321 12.021L0.115 20.334L3.781 24L12.018 15.682L20.303 23.885L24 20.188Z"
              fill="#C5C6C6"
            />
          </svg>
        </span>
        {children}
      </div>
    </ModalContainer>
  );
};

export default React.memo(Modal);
