import React from 'react';
import { GamesList, ProductPage } from '@homeplay/components';
import classes from './Club888.module.scss';
import logoClub888 from '../../../../shared/assets/images/logo-club888.png';
import { useSelector } from 'react-redux';
import { selectClub888Games } from '@homeplay/app';

const Club888 = () => {
  const club888Games = useSelector(selectClub888Games);

  const getGameLink = (loadParams, vendorData, exitUrl) => {
    let baseUrl = `${process.env.REACT_APP_CLUB888_BASE_URL}/loader`;
    let lobbyUrl = exitUrl;
    if (vendorData.altloader) {
      baseUrl = `${process.env.REACT_APP_CLUB888_NEW_BASE_URL}/loader/index.html`;
      lobbyUrl = `${window.location.origin}/exit`;
    }

    return `${baseUrl}?gameid=${vendorData.gameid}&token=${loadParams.player_token}&lobbyurl=${lobbyUrl}&lang=en`;
  };

  return (
    <ProductPage getGameLink={getGameLink} customClass={classes.Club888}>
      <div className="container">
        <img
          className={classes.Logo}
          src={logoClub888}
          alt="Club888"
          width="322"
          height="90"
        />
        {club888Games && <GamesList games={club888Games} />}
      </div>
    </ProductPage>
  );
};

export default Club888;
