import React from 'react';
import LcGameItem from './LcGameItem';
import classes from './LcGamesList.module.scss';

const LcGamesList = ({ games }) => {
  const gamesList = games.map((game) => (
    <li key={game.id} className="game">
      <LcGameItem game={game} />
    </li>
  ));

  return <ul className={classes.GamesList}>{gamesList}</ul>;
};

export default LcGamesList;
