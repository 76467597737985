import axios from 'axios';

export const DEFAULT_BASE_URL = 'https://dsarsa.com';

export const fetchWidgetSettings = async (accountId, baseUrl = DEFAULT_BASE_URL) => {
  return axios
      .get(`${baseUrl}/api/widget_settings`, {
        params: {
          account_id: accountId
        }
      })
      .then((res) => {
          return res.data.data;
      });
};
