import React, { useContext, useEffect, useState } from 'react';
import { loadUnitFail, selectUnitToken, SiteContext } from '@homeplay/app';
import { Frame } from '@homeplay/components';
import { getFafiParams, handleError } from '@homeplay/api';
import { useDispatch, useSelector } from 'react-redux';

const Fafi = () => {
  const unitToken = useSelector(selectUnitToken);
  const { isTouchUnit } = useContext(SiteContext);
  const dispatch = useDispatch();
  const [fafiSrc, setFafiSrc] = useState(null);

  useEffect(() => {
    if (unitToken) {
      fetchFafiParams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitToken]);

  const fetchFafiParams = async () => {
    try {
      const params = await getFafiParams(unitToken, isTouchUnit);
      const fafiSrc = `${process.env.REACT_APP_FAFI_BASE_URL}?token=${params.player_token}&mode=home_play&exit=${window.location.origin}/exit`;
      setFafiSrc(fafiSrc);
    } catch (error) {
      dispatch(loadUnitFail({ error: handleError(error) }));
    }
  };

  return (
    fafiSrc && (
      <Frame
        frameSrc={fafiSrc}
        frameTitle="Fafi"
        allowScrolling={true}
        allowFullScreen={true}
      />
    )
  );
};

export default Fafi;
