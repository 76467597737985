export const EditIcon = ({ customClass }) => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`stroke-primary ${customClass}`}
  >
    <path
      d="M15.1908 6.348L16.3762 7.42125L5.89508 16.923L0.000976562 18L1.19138 12.6547L11.6684 3.15375L12.8522 4.227L2.71433 13.395L2.11375 16.083L5.07859 15.5408L15.1908 6.348ZM15.1445 0L12.8158 2.11275L17.5236 6.38175L19.854 4.266L15.1445 0V0ZM4.9545 14.0385L14.3131 5.5515L13.7299 5.02125L4.3713 13.5068L4.9545 14.0385Z"
      fill="#9B4090"
    />
  </svg>
);
