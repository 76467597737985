import React, {useEffect, useRef, useState} from 'react';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import classes from './ScreenKeyboard.module.scss'

const ScreenKeyboard = ({value, setValue, pattern, inputName, type, onClickHide, onClickTab}) => {
    const [layout, setLayout] = useState("default");
    const keyboard = useRef();

    useEffect(() => {
        if (keyboard.current.getInput() !== value) {
            keyboard.current.setInput(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const handleKeyboardChange = input => {
        setValue(input);
    };

    const handleShift = () => {
        const newLayoutName = layout === "default" ? "shift" : "default";
        setLayout(newLayoutName);
    };

    const onKeyPress = (button) => {
        switch (button) {
            case "{shift}":
                handleShift();
                break;
            case "{lock}":
                handleShift();
                break;
            case "{enter}":
                document.form.querySelector("button[type='submit']").click();
                break;
        }
    };

    const onKeyReleased = (button) => {
        if (button === "{tab}") {
            onClickTab();
        }
    };

    let keyboardLayout = null;

    if (type === 'tel') {
        keyboardLayout = {
            custom: ["1 2 3", "4 5 6", "7 8 9", "+ 0 {bksp}"]
        }
    } else if (type === 'number') {
        keyboardLayout = {
            custom: ["1 2 3", "4 5 6", "7 8 9", "0", "{bksp}"]
        }
    }

    return (
        <div className={classes.ScreenKeyboard + (type === 'tel' || type === 'number' ? ' ' + classes.Small : '')}>
            <Keyboard
                keyboardRef={r => (keyboard.current = r)}
                layoutName={keyboardLayout ? 'custom' : layout}
                layout={keyboardLayout ? keyboardLayout : null}
                onChange={handleKeyboardChange}
                onKeyPress={onKeyPress}
                onKeyReleased={onKeyReleased}
                maxLength={120}
                inputPattern={new RegExp(pattern)}
                baseClass={inputName}
                disableCaretPositioning={true}
                tabCharOnTab={false}
            />
        </div>
    );
};

export default ScreenKeyboard;
