import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGameParams, handleError } from '@homeplay/api';
import { loadUnitFail, selectUnitToken } from '@homeplay/app';
import { Frame } from '@homeplay/components';

const Aviator = () => {
  const unitToken = useSelector(selectUnitToken);
  const dispatch = useDispatch();
  const [aviatorSrc, setAviatorSrc] = useState(null);

  const getAviatorParams = async () => {
    try {
      const { load_params } = await getGameParams(unitToken, 'spaviator');

      const aviatorSrc = `${process.env.REACT_APP_SPRIBE_BASE_URL}/spribe/${load_params.url_slug}/?currency=ZAR&jurisdiction=CW&lang=EN&token=m&operator=default&extra=${load_params.game_server}&user=${load_params.user}`;

      setAviatorSrc(aviatorSrc);
    } catch (error) {
      dispatch(loadUnitFail({ error: handleError(error) }));
    }
  };

  useEffect(() => {
    if (unitToken) {
      getAviatorParams();
    }
  }, [unitToken]);

  return (
    aviatorSrc && (
      <Frame
        frameSrc={aviatorSrc}
        frameTitle="Aviator Game"
        allowFullScreen={true}
        allowScrolling={true}
      />
    )
  );
};

export default Aviator;
