import React from 'react';
import ButtonPrimary from '../UI/ButtonPrimary';
import ModalContainer from '../Modal/ModalContainer';
import classes from './HappyHour.module.scss';

const CongratsBanner = ({ onClick }) => {
  return (
    <ModalContainer>
      <div
        onClick={onClick}
        className={
          classes.CongratsBanner +
          (navigator.userAgent.indexOf('Safari') !== -1 &&
          navigator.userAgent.indexOf('Chrome') === -1
            ? ` ${classes.Safari}`
            : '')
        }
      >
        <div>
          <h3 data-text="CONGRATS!">CONGRATS!</h3>

          <p>Check Out Your</p>
          <h4>
            <span data-text="Happy Hour">Happy Hour</span>
            <span className={classes.Primary} data-text="BONUS!">
              BONUS!
            </span>
          </h4>
          <p>In The Main Menu</p>

          <ButtonPrimary onClick={onClick} customClass={classes.Button}>
            Thanks!
          </ButtonPrimary>
        </div>
      </div>
    </ModalContainer>
  );
};

export default React.memo(CongratsBanner);
