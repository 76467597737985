import React from 'react';
import classes from './BonusLabel.module.scss';

const BonusLabel = () => {

    return (
        <div className={classes.BonusLabel + " bonus-label"} data-testid="label-bonus">
            BONUS
        </div>
    );
};

export default React.memo(BonusLabel);
