import React from 'react';
import classes from './CashoutTicketDropdown.module.scss';
import { formatBalance } from '@homeplay/utils';
import Divider from '../UI/Divider';
import Button from '../UI/Button';
import ButtonSecondary from '../UI/ButtonSecondary';

const CashoutTicketDropdown = ({
  balance,
  depositBalance,
  onCashOutClick,
  onCancelClick,
}) => {
  return (
    <div className={classes.CashoutTicketDropdown}>
      <div>
        <Divider />
        <p>
          Your balance {formatBalance(balance + depositBalance)} <br /> will be
          cashed out to the ticket.
        </p>

        <p>
          Please make sure to write down the{' '}
          <span className="color-primary">ticket number</span> that you will see
          on screen, or take a photo/screenshot.
        </p>

        <p className="color-primary">
          Do not give this ticket number to anyone!
        </p>

        <ButtonSecondary
          value={'OK'}
          onClick={onCashOutClick}
          customClass="d-block"
        />

        <Button onClick={onCancelClick} customClass="d-block">
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default CashoutTicketDropdown;
