import React, { useContext } from 'react';
import { GamesList, ProductPage } from '@homeplay/components';
import classes from './PlaynGo.module.scss';
import { SiteContext, selectPlaynGoGames } from '@homeplay/app';
import playnGoLogo from '../../../../shared/assets/images/playn-go-logo.png';
import { useSelector } from 'react-redux';

const PlaynGo = () => {
  const { isDesktop } = useContext(SiteContext);
  const playnGoGames = useSelector(selectPlaynGoGames);

  const getGameLink = (loadParams) => {
    return `${process.env.REACT_APP_PNG_BASE_URL}/Casino/IframedView.html?pid=2&gameId=${loadParams.game_id}&lang=en_US&practice=1&channel=${isDesktop ? 'desktop' : 'mobile'}&unitId=${loadParams.player_token}&gsServer=${loadParams.game_server}`;
  };

  return (
    <ProductPage getGameLink={getGameLink} customClass={classes.PlaynGo}>
      <div className="container">
        <img
          className={classes.Logo}
          src={playnGoLogo}
          alt="PlaynGo"
          width="320"
          height="93"
        />
        {playnGoGames && <GamesList games={playnGoGames} />}
      </div>
    </ProductPage>
  );
};

export default PlaynGo;
