import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VerifyPhone from './VerifyPhone';
import EnterRegistrationData from './EnterRegistrationData';
import {
  SiteContext,
  authFail,
  authStart,
  disableAuthLoading,
  registerUser,
  resetAuthLocalError,
  selectLocalError,
} from '@homeplay/app';
import { handleError, confirmAuthOTP, sendAuthOTP } from '@homeplay/api';

const Registration = ({ phoneNumber, onClickBack }) => {
  const dispatch = useDispatch();
  const { siteName, isTouchUnit } = useContext(SiteContext);
  const otpError = useSelector(selectLocalError);
  const [registrationStep, setRegistrationStep] = useState(1); // 1 - OTP; 2 - Enter password
  const [phoneToken, setPhoneToken] = useState(null);

  const otpConfirm = async (otpCode) => {
    dispatch(authStart());
    try {
      const phoneToken = await confirmAuthOTP(phoneNumber, otpCode);
      setPhoneToken(phoneToken);
      setRegistrationStep(2);
      if (otpError) {
        dispatch(resetAuthLocalError());
      }
      dispatch(disableAuthLoading());
    } catch (error) {
      dispatch(
        authFail({
          error: handleError(error),
          localError: true,
        })
      );
    }
  };

  const resendOtp = async () => {
    dispatch(authStart());
    try {
      await sendAuthOTP(phoneNumber);
      dispatch(resetAuthLocalError());
      dispatch(disableAuthLoading());
      return true;
    } catch (error) {
      dispatch(authFail({ error: handleError(error) }));
    }
    return false;
  };

  const handleRegisterUser = (password, userAttrs) => {
    dispatch(
      registerUser(phoneToken, password, userAttrs, siteName, isTouchUnit)
    );
  };

  return (
    <React.Fragment>
      {registrationStep === 1 && (
        <VerifyPhone
          title="Verify Your Phone Number"
          label="To complete your registration"
          phoneNumber={phoneNumber}
          onSubmit={otpConfirm}
          onClickBack={onClickBack}
          resendOtp={resendOtp}
          otpError={otpError}
          allowEditPhone={true}
        />
      )}

      {registrationStep === 2 && (
        <EnterRegistrationData
          phoneNumber={phoneNumber}
          onSubmit={handleRegisterUser}
          onClickBack={onClickBack}
        />
      )}
    </React.Fragment>
  );
};

export default Registration;
