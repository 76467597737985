import React, { useContext, useEffect, useRef, useState } from 'react';
import { Page } from '@homeplay/components';
import Button from '@homeplay/components/src/UI/Button';
import { setMaskForPhone } from '@homeplay/utils';
import Input from '@homeplay/components/src/UI/Input';
import classes from '../UserAccount.module.scss';
import { SiteContext } from '@homeplay/app';

const EnterPassword = ({
  title,
  phoneNumber,
  label,
  placeholder,
  error,
  onSubmit,
  onClickBack,
  onClickResetPassword,
  validation = true,
}) => {
  const { isTouchUnit, screenKeyboard } = useContext(SiteContext);
  const [password, setPassword] = useState('');
  const focusInput = useRef();

  useEffect(() => {
    if (focusInput.current) {
      focusInput.current.focus();
    }
  }, []);

  const isInputsValid = () => {
    if (validation) {
      return password.length > 7;
    }
    return password.length > 0;
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    onSubmit(password);
    setPassword('');
  };

  return (
    <Page
      headerContent={title}
      customClass={
        classes.Auth +
        (isTouchUnit && !!screenKeyboard ? ' screen-keyboard' : '')
      }
      onClickBack={onClickBack}
    >
      <div className="container">
        <div>
          <h4>{label}</h4>
          {error ? (
            <span onClick={onClickBack} className="phone">
              <span>{setMaskForPhone(phoneNumber)}</span>
              <svg
                width="21"
                height="18"
                viewBox="0 0 21 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-primary"
                  d="M15.8917 6.348L17.0772 7.42125L6.59601 16.923L0.701904 18L1.89231 12.6547L12.3693 3.15375L13.5531 4.227L3.41526 13.395L2.81468 16.083L5.77952 15.5408L15.8917 6.348ZM15.8454 0L13.5167 2.11275L18.2246 6.38175L20.5549 4.266L15.8454 0V0ZM5.65543 14.0385L15.014 5.5515L14.4308 5.02125L5.07223 13.5068L5.65543 14.0385Z"
                />
              </svg>
            </span>
          ) : (
            <p className="value">{setMaskForPhone(phoneNumber)}</p>
          )}

          <form name="form" onSubmit={onSubmitHandler}>
            <input
              className="d-none"
              type="tel"
              id="id"
              value={phoneNumber}
              readOnly
            />

            <Input
              refLink={focusInput}
              id="password"
              type="password"
              placeholder={placeholder}
              onChange={setPassword}
              value={password}
              error={validation ? password.length < 8 : !password.length}
              infoMessage={
                validation
                  ? 'Password must be at least 8 characters long.'
                  : null
              }
              autocomplete={validation ? 'new-password' : 'current-password'}
            />

            <Button disabled={!isInputsValid()} customClass="d-block">
              Submit
            </Button>
          </form>

          {error && (
            <div className="error-message">
              <h6>We have encountered an error with your login.</h6>
              <ul className="marked-list">
                <li>
                  Please make sure that the phone number entered is correct.
                </li>
                <li>Edit your number if necessary.</li>

                <li>Press the "Submit" button.</li>
                <li>Or reset your password by clicking the link below.</li>
              </ul>
            </div>
          )}
        </div>
      </div>
      {error && (
        <div className="bottom-block">
          <span onClick={onClickResetPassword} className="link">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="fill-primary"
                d="M16 2C19.309 2 22 4.691 22 8C22 11.309 19.309 14 16 14C12.691 14 10 11.309 10 8C10 4.691 12.691 2 16 2ZM16 0C11.582 0 8 3.582 8 8C8 12.418 11.582 16 16 16C20.418 16 24 12.418 24 8C24 3.582 20.418 0 16 0ZM10.595 16.4L9.123 18H6V20H4V22H2V19.821L7.903 13.845C7.499 13.286 7.149 12.687 6.865 12.05L0 19V24H6V22H8V20H10L12.451 17.337C11.796 17.088 11.175 16.775 10.595 16.4ZM18 5C18.551 5 19 5.449 19 6C19 6.551 18.551 7 18 7C17.449 7 17 6.551 17 6C17 5.449 17.449 5 18 5ZM18 4C16.896 4 16 4.896 16 6C16 7.104 16.896 8 18 8C19.104 8 20 7.104 20 6C20 4.896 19.104 4 18 4Z"
              />
            </svg>
            <span>Reset Password</span>
          </span>
        </div>
      )}
    </Page>
  );
};

export default EnterPassword;
