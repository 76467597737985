import React, {useContext} from 'react';
import classes from './LcGameItem.module.scss';
import {SiteContext} from "@homeplay/app";

const SicBoResults = ({results}) => {
    const {isDesktop} = useContext(SiteContext);

    const resultsList = isDesktop ? results : results.slice(0, 10);
    const resultsItems = resultsList.map((result, index) => {
        return (
            <ul key={index}>
                {isDesktop &&
                    <React.Fragment>
                        <li className={(result.die1 === result.die2 || result.die1 === result.die3) ? classes.Highlight : ''}>{result.die1}</li>
                        <li className={(result.die2 === result.die1 || result.die2 === result.die3) ? classes.Highlight : ''}>{result.die2}</li>
                        <li className={(result.die3 === result.die1 || result.die3 === result.die2) ? classes.Highlight : ''}>{result.die3}</li>
                    </React.Fragment>
                }
                <li className={result.totalSum > 10 ? classes.Highlight : ''}>{result.totalSum}</li>
            </ul>
        );
    })

    return (
        <div className={classes.SicBoResults}>
            {resultsItems}
        </div>
    );
};

export default SicBoResults;
