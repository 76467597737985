import React, { useContext, useEffect, useState } from 'react';
import { LcGamesList, Loader, ProductPage } from '@homeplay/components';
import classes from './PPLiveCasino.module.scss';
import { SiteContext, selectPPLiveCasionGames } from '@homeplay/app';
import ppLClogo from '../../../../shared/assets/images/pp-lc-logo.png';
import { useSelector } from 'react-redux';
import { PragmaticDGA } from '@homeplay/api';

const PPLiveCasino = () => {
  const ppLiveCasinoGames = useSelector(selectPPLiveCasionGames);
  const [games, setGames] = useState(null);
  const [loadedGamesCount, setLoadedGamesCount] = useState(0);
  const [error, setError] = useState(null);
  const { isDesktop } = useContext(SiteContext);
  const { siteName } = useContext(SiteContext);

  useEffect(() => {
    let dga;
    if (ppLiveCasinoGames && ppLiveCasinoGames.length) {
      setGames(ppLiveCasinoGames);
      const gamesTableIds = ppLiveCasinoGames.map((game) => game.id);
      dga = new PragmaticDGA(
        process.env.REACT_APP_PRAGMATIC_WSS_URL,
        process.env.REACT_APP_PRAGMATIC_CASINO_ID,
        gamesTableIds,
        updateGamesParams,
        onError
      );
    }

    return () => {
      if (dga) {
        dga.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ppLiveCasinoGames]);

  const updateGamesParams = (params) => {
    const updatedGame = parseGameParams(params);
    setGames((games) =>
      games.map((game) => {
        if (game.id === updatedGame.id) {
          setLoadedGamesCount((gamesCount) => gamesCount + 1);
          if (error) {
            setError(null);
          }
          return { ...game, ...updatedGame };
        } else {
          return game;
        }
      })
    );
  };

  const parseGameParams = (params) => {
    return {
      id: params.tableId,
      name: parseGameName(params.tableName),
      dealer: parseDealerName(params.dealer.name),
      isNew: params.newTable,
      img: params.tableImage,
      open: params.tableOpen,
      type: params.tableType,
      minBet: params.tableLimits.minBet * 100,
      maxBet: params.tableLimits.maxBet * 100,
      totalSeatedPlayers: params.totalSeatedPlayers,
      totalSeats: params.availableSeats
        ? params.availableSeats + params.totalSeatedPlayers
        : null,
      options: parseGameOptions(params),
    };
  };

  const parseDealerName = (name) => {
    if (name === 'No dealer') {
      return null;
    }
    if (name.startsWith('Autodealer')) {
      return 'Autodealer';
    }
    return name;
  };

  const parseGameName = (name) => {
    if (name.startsWith('Live ')) {
      return name.slice(5);
    }
    return name;
  };

  const parseBaccaratOptions = (params) => {
    let stats = [];
    let playerWins = 0;
    let bankerWins = 0;
    let playerPairWins = 0;
    let bankerPairWins = 0;
    let tieWins = 0;

    try {
      if (isDesktop) {
        stats = JSON.parse(params.statistics).slice(-25);
      } else {
        stats = JSON.parse(params.statistics);
      }
    } catch (e) {
      console.log('Error parse statistics JSON');
    }

    stats.forEach((statsCol, index) => {
      stats[index] = statsCol.map((item) => {
        if (item === '---') {
          return null;
        }

        switch (item[0]) {
          case 'P':
            playerWins++;
            break;

          case 'B':
            bankerWins++;
            break;
        }

        switch (item[1]) {
          case 'P':
            playerPairWins++;
            break;

          case 'B':
            bankerPairWins++;
            break;

          case 'E':
            playerPairWins++;
            bankerPairWins++;
            break;
        }

        if (item[2] === '1' || item[2] === '2') {
          tieWins++;
        }

        return item;
      });
    });
    for (let i = stats.length; i < 27; i++) {
      stats[i] = [null, null, null, null, null, null];
    }
    return {
      stats,
      playerWins,
      bankerWins,
      playerPairWins,
      bankerPairWins,
      tieWins,
    };
  };

  const parseGameOptions = (params) => {
    switch (params.tableType) {
      case 'BLACKJACK':
        const seatsKeys = Object.keys(params).filter((key) =>
          key.startsWith('seat')
        );
        return { seats: seatsKeys.map((key) => params[key]) };

      case 'BACCARAT':
        return parseBaccaratOptions(params);

      case 'ROULETTE':
        const results = params.last20Results.slice(0, 11).map((item) => ({
          result: item.result,
          color: item.color,
        }));
        return { results };

      case 'SicBO':
        return { results: params.last20Results.slice(0, 14) };

      case 'MEGAWHEEL':
        return { results: params.last20Results.slice(0, 10) };
    }
  };

  const getGameLink = (loadParams) => {
    const hpUrl = `${window.location.origin}${siteName ? '/' + siteName : ''}/pp-live-casino`;
    const ppKeyParams = `token=${loadParams.player_token}&symbol=${loadParams.game_id}&language=en&technology=H5&platform=${isDesktop ? 'WEB' : 'MOBILE'}&lobbyUrl=${hpUrl}`;
    const ppKey = encodeURIComponent(ppKeyParams);
    return `${process.env.REACT_APP_PRAGMATIC_BASE_URL}/playGame.do?key=${ppKey}&stylename=${loadParams.stylename}&rcCloseUrl=${hpUrl}`;
  };

  const onError = (message) => {
    setError(message);
  };

  return (
    <ProductPage getGameLink={getGameLink} customClass={classes.PPLiveCasino}>
      <div>
        <img
          className={classes.Logo}
          src={ppLClogo}
          alt="LiveCasino"
          width="340"
          height="63"
        />
        {games &&
        games.length &&
        (loadedGamesCount > 7 || loadedGamesCount > games.length / 2) ? (
          <LcGamesList games={games} />
        ) : error ? (
          <div className={classes.Error}>{error}</div>
        ) : (
          <Loader customClass={classes.LcLoader} />
        )}
      </div>
    </ProductPage>
  );
};

export default PPLiveCasino;
