import React from "react";

class ErrorBoundary extends React.Component {
    state = {error: null};

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {error};
    }

    componentDidCatch(error, info) {
        console.error('Error rendering LiveChat:', error, info);
    }

    render() {
        if (this.state.error) {
            // TODO: should we render something different here?
            return null;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
