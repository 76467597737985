import React from 'react';
import classes from './AnimatedStars.module.scss';

const AnimatedStars = () => {
    const positions = [
        {x: 27, y: 8, duration: 5423, size: 120},
        {x: 46, y: -3, duration: 5631, size: 100},
        {x: 63, y: 5, duration: 7823, size: 100},
        {x: 35, y: 15, duration: 8323, size: 100},
        {x: 0, y: 10, duration: 9233, size: 100},
        {x: 0, y: 8, duration: 11732, size: 70},
        {x: 65, y: 23, duration: 7231, size: 70},
        {x: 50, y: 9, duration: 12393, size: 130},
        {x: 67, y: 12, duration: 7454, size: 100},
        {x: 60, y: 15, duration: 5231, size: 100},
        {x: 15, y: 5, duration: 8232, size: 110},
        {x: 5, y: 15, duration: 7831, size: 100},
        {x: 77, y: 0, duration: 7231, size: 100},
    ];

    const stars = positions.map(star => (
        <div key={`${star.x}-${star.duration}-${star.y}`}
             className={classes.Star}
             style={{
                 top: `${star.y}%`,
                 left: `${star.x}%`,
                 width: `${star.size}px`,
                 height: `${star.size}px`,
                 animationDuration: `${star.duration}ms`
             }}
        >
            <span/>
        </div>
    ));

    return (
        <div className={classes.AnimatedStars}>
            {stars}
        </div>
    );
};

export default React.memo(AnimatedStars);
