import React from 'react';
import Header from '../Header';
import Footer from '../Footer';

const Page = ({
  children,
  customClass,
  headerContent,
  backPath,
  onClickBack,
}) => {
  return (
    <React.Fragment>
      <Header
        headerContent={headerContent}
        backPath={backPath}
        onClickBack={onClickBack}
      />
      <main className={customClass ? customClass : null}>{children}</main>
      <Footer />
    </React.Fragment>
  );
};

export default Page;
