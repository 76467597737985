import React, { useEffect, useRef, useState } from 'react';
import Input from '@homeplay/components/src/UI/Input';
import Select from '@homeplay/components/src/UI/Select';
import Button from '@homeplay/components/src/UI/Button';
import { getBanksList } from '@homeplay/api';
import classes from './EditBankAccount.module.scss';
import Divider from '../UI/Divider';

const EditBankAccount = ({ onSubmit }) => {
  const [bankName, setBankName] = useState('');
  const [accountName, setAccountName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const focusInput = useRef();

  useEffect(() => {
    if (focusInput.current) {
      focusInput.current.focus();
    }
  }, []);

  const isInputsValid = () => {
    return (
      !!bankName.length &&
      !!accountName.length &&
      accountNumber.length > 6 &&
      accountNumber.length < 12
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit({
      bankName,
      holderName: accountName.trim(),
      number: accountNumber,
    });
  };

  return (
    <div className="container">
      <p>
        Please fill in the following banking details accurately for the
        withdrawal process.
      </p>

      <Divider />

      <form name="form" onSubmit={handleSubmit}>
        <label className="label" htmlFor="bankName">
          Select Bank
        </label>
        <Select
          id="bankName"
          value={bankName}
          onChange={setBankName}
          options={getBanksList()}
          placeholder="Select Bank"
          error={!bankName.length}
          refLink={focusInput}
        />

        <label className="label" htmlFor="accountName">
          Account Name
        </label>
        <Input
          type="text"
          id="accountName"
          placeholder="Enter Your Account Name"
          onChange={setAccountName}
          value={accountName}
          pattern="^[a-zA-Z\d\-\s]+$"
          error={!accountName.length}
          maxLength={20}
        />

        <label className="label" htmlFor="accountNumber">
          Account Number
        </label>
        <Input
          type="number"
          id="accountNumber"
          placeholder="Enter Your Account Number"
          onChange={setAccountNumber}
          value={accountNumber}
          error={accountNumber.length < 7 || accountNumber.length > 11}
          pattern="^[0-9]{0,11}$"
          inputMode="numeric"
          maxLength={11}
        />
        <Button disabled={!isInputsValid()} customClass={classes.SubmitButton}>
          Submit
        </Button>
      </form>
    </div>
  );
};

export default EditBankAccount;
