import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  logout,
  selectIsVerificationEnabled,
  selectUser,
  SiteContext,
} from '@homeplay/app';
import { formatBalance, setMaskForPhone } from '@homeplay/utils';
import { ButtonSecondary, Page } from '@homeplay/components';
import Divider from '@homeplay/components/src/UI/Divider';
import { EditIcon } from '@homeplay/components/src/UI/icons';
import VerificationStatus from '../../Verification/VerificationStatus';
import classes from './Profile.module.scss';

const Profile = () => {
  const { siteName, isTouchUnit } = useContext(SiteContext);
  const user = useSelector(selectUser);
  const { balance, depositBalance, bonus, cashoutAtmEnabled } = useSelector(
    (state) => state.unit
  );
  const isVerificationEnabled = useSelector(selectIsVerificationEnabled);
  const dispatch = useDispatch();

  const onLogOutClick = () => {
    dispatch(logout(siteName, isTouchUnit));
  };

  return (
    <Page headerContent={'Profile'} customClass={classes.root}>
      <div className="container">
        <div className={`${classes.balanceInfo} color-primary`}>
          <div>Total: {formatBalance(balance + depositBalance)}</div>
          {bonus > 0 && <div>Free Spins: {formatBalance(bonus)}</div>}
        </div>

        <Divider />

        <div className="inline-block">
          <span className="label">Phone</span>
          <span className="value">
            {setMaskForPhone(user.phone)}{' '}
            <Link to="../edit-phone">
              <EditIcon customClass={classes.editIcon} />
            </Link>
          </span>
        </div>

        <div className="inline-block">
          <span className="label">First Name</span>
          <span className="value">
            {user.firstName}{' '}
            <Link to="../edit-profile">
              <EditIcon customClass={classes.editIcon} />
            </Link>
          </span>
        </div>

        <div className="inline-block">
          <span className="label">Last Name</span>
          <span className="value">
            {user.lastName}{' '}
            <Link to="../edit-profile">
              <EditIcon customClass={classes.editIcon} />
            </Link>
          </span>
        </div>

        {isVerificationEnabled && !isTouchUnit && (
          <div className="inline-block" style={{ minHeight: '30px' }}>
            <Link
              className={classes.link}
              to={`/${siteName ? siteName + '/' : ''}verification/info`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="3 2 18 18"
                className="color-primary"
              >
                <g fill="none" stroke="currentColor">
                  <path d="M14.049 5.54a1 1 0 0 1 1.071.443l.994 1.587a1 1 0 0 0 .316.316l1.587.994a1 1 0 0 1 .444 1.072l-.42 1.824a1 1 0 0 0 0 .448l.42 1.825a1 1 0 0 1-.444 1.07l-1.587.995a.993.993 0 0 0-.316.316l-.994 1.587a1 1 0 0 1-1.071.444l-1.825-.42a1 1 0 0 0-.447 0l-1.825.42a1 1 0 0 1-1.071-.444l-.994-1.587a1.001 1.001 0 0 0-.317-.316l-1.586-.994a1 1 0 0 1-.444-1.071l.419-1.825a1 1 0 0 0 0-.448l-.42-1.824a1 1 0 0 1 .445-1.072l1.586-.994a1 1 0 0 0 .317-.316l.994-1.587a1 1 0 0 1 1.07-.443l1.826.419a1 1 0 0 0 .447 0z" />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m9.515 12.536l2.035 1.949l2.935-4.97"
                  />
                </g>
              </svg>
              <span>Verification</span>
            </Link>

            <span className={classes.valueWithMargin}>
              <VerificationStatus />
            </span>
          </div>
        )}

        <div className="inline-block">
          <Link
            to={`/${siteName ? siteName + '/' : ''}statement`}
            className={classes.link}
          >
            <svg
              width="24"
              height="24"
              className="stroke-primary"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span>My Statement</span>
          </Link>
        </div>

        <div className="inline-block">
          <Link
            to={`/${siteName ? siteName + '/' : ''}reset-password`}
            className={classes.link}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="fill-primary"
                d="M16 2C19.309 2 22 4.691 22 8C22 11.309 19.309 14 16 14C12.691 14 10 11.309 10 8C10 4.691 12.691 2 16 2ZM16 0C11.582 0 8 3.582 8 8C8 12.418 11.582 16 16 16C20.418 16 24 12.418 24 8C24 3.582 20.418 0 16 0ZM10.595 16.4L9.123 18H6V20H4V22H2V19.821L7.903 13.845C7.499 13.286 7.149 12.687 6.865 12.05L0 19V24H6V22H8V20H10L12.451 17.337C11.796 17.088 11.175 16.775 10.595 16.4ZM18 5C18.551 5 19 5.449 19 6C19 6.551 18.551 7 18 7C17.449 7 17 6.551 17 6C17 5.449 17.449 5 18 5ZM18 4C16.896 4 16 4.896 16 6C16 7.104 16.896 8 18 8C19.104 8 20 7.104 20 6C20 4.896 19.104 4 18 4Z"
              />
            </svg>
            <span>Reset Password</span>
          </Link>
        </div>

        <div className="inline-block">
          <span className="label">Total Balance</span>
          <span className={`value ${classes.valueWithMargin}`}>
            {formatBalance(balance + depositBalance)}
          </span>
        </div>

        <div className="inline-block">
          <span className="label">Free Spins</span>
          <span className={`value ${classes.valueWithMargin}`}>
            {formatBalance(bonus)}
          </span>
        </div>

        {cashoutAtmEnabled && !isTouchUnit && (
          <React.Fragment>
            <div className="inline-block">
              <span className="label">Available to Withdraw *</span>
              <span className={`value ${classes.valueWithMargin}`}>
                {formatBalance(balance)}
              </span>
            </div>
            <p className={classes.infoText}>
              * Credits loaded to your account must be used to play. You can
              only withdraw what you have won.
            </p>
          </React.Fragment>
        )}

        <ButtonSecondary
          navPath={`/${siteName ? siteName + '/' : ''}cashout`}
          value="Cashout"
          customClass={classes.cashoutBtn + ' d-block'}
        />

        <span onClick={onLogOutClick} className="link">
          <svg
            width="24"
            height="20"
            viewBox="0 0 24 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="fill-primary"
              d="M16 7V3L24 10L16 17V13H8V7H16ZM14 17V16.917C12.822 17.602 11.458 18 10 18C5.589 18 2 14.411 2 10C2 5.589 5.589 2 10 2C11.458 2 12.822 2.398 14 3.083V0.838C12.774 0.302 11.423 0 10 0C4.478 0 0 4.477 0 10C0 15.523 4.478 20 10 20C11.423 20 12.774 19.698 14 19.162V17Z"
            />
          </svg>
          <span>Log Out</span>
        </span>
      </div>
    </Page>
  );
};

export default Profile;
