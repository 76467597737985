import React from 'react';

import classes from './Loader.module.scss';

const Loader = ({customClass, message}) => {
    return (
        <div className={classes.Loader + (customClass ? ' ' + customClass : '')} data-testid="loader">
            <div className={classes.Spinner}/>
            {message && <div className={classes.Message}>{message}</div>}
        </div>
    );
};

export default React.memo(Loader);
