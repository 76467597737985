import React, { useContext } from 'react';
import { GamesList, ProductPage } from '@homeplay/components';
import AnimatedStars from '@homeplay/components/src/UI/AnimatedStars';
import classes from './GreenDream.module.scss';
import { SiteContext, selectGreenDreamGames } from '@homeplay/app';
import logoGreenDream from '../../../../shared/assets/images/logo-green-dream.png';
import { useSelector } from 'react-redux';

const GreenDream = () => {
  const { isDesktop } = useContext(SiteContext);
  const greenDreamGames = useSelector(selectGreenDreamGames);

  const getGameLink = (loadParams) => {
    return `${process.env.REACT_APP_GREENDREAM_BASE_URL}/${!isDesktop && loadParams.mobile_path ? loadParams.mobile_path : loadParams.path}?hash=${loadParams.hash}&exit=${window.location.origin}/exit`;
  };

  return (
    <ProductPage getGameLink={getGameLink} customClass={classes.GreenDream}>
      <AnimatedStars />
      <div className="container">
        <img
          className={classes.Logo}
          src={logoGreenDream}
          alt="GreenDream"
          width="256"
          height="98"
        />
        {greenDreamGames && <GamesList games={greenDreamGames} />}
      </div>
    </ProductPage>
  );
};

export default GreenDream;
