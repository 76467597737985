import React from "react";
import {motion} from 'framer-motion';
import ErrorBoundary from "./ErrorBoundary";
import ChatWidgetContainer from "./ChatWidgetContainer";
import WidgetToggle from "./WidgetToggle";
import classes from "./ChatWidget.module.css"

const DEFAULT_X_OFFSET = 20;

const normalizePositionConfig = (position) => {
    if (!position) {
        return {side: 'right', offset: DEFAULT_X_OFFSET};
    }

    switch (position) {
        case 'left':
            return {side: 'left', offset: DEFAULT_X_OFFSET};
        case 'right':
            return {side: 'right', offset: DEFAULT_X_OFFSET};
        default:
            return position;
    }
};

const getDefaultStyles = (styles = {}, position) => {
    const {
        chatContainer: chatContainerStyle = {},
        toggleContainer: toggleContainerStyle = {},
        toggleButton: toggleButtonStyle = {},
    } = styles;
    const {side = 'right', offset = DEFAULT_X_OFFSET} = position;

    switch (side) {
        case 'left':
            return {
                chatContainer: {left: offset, right: 'auto', ...chatContainerStyle},
                toggleContainer: {left: offset, right: 'auto', ...toggleContainerStyle},
                toggleButton: toggleButtonStyle,
            };
        case 'right':
        default:
            return {
                chatContainer: {right: offset, left: 'auto', ...chatContainerStyle},
                toggleContainer: {right: offset, left: 'auto', ...toggleContainerStyle},
                toggleButton: toggleButtonStyle,
            };
    }
};

const ChatWidget = (props) => {
  return (
    <ErrorBoundary>
      <ChatWidgetContainer {...props} canToggle>
          {(config) => {
              const {
                  sandbox,
                  isActive,
                  isOpen,
                  needOpen,
                  isTransitioning,
                  customIconUrl,
                  iframeUrl,
                  query,
                  primaryColor,
                  iconVariant,
                  shouldDisplayNotifications,
                  setIframeRef,
                  onToggleOpen,
              } = config;

              const {
                  hideToggleButton,
                  renderToggleButton,
                  position = 'right',
                  styles = {},
              } = props;
              const positionConfig = normalizePositionConfig(position);
              const {
                  chatContainer: chatContainerStyle = {},
                  toggleContainer: toggleContainerStyle = {},
                  toggleButton: toggleButtonStyle = {},
              } = getDefaultStyles(styles, positionConfig);

              return (
                  <React.Fragment>
                      <motion.iframe
                          ref={setIframeRef}
                          title='LiveChat Widget Container'
                          className={!isOpen && shouldDisplayNotifications ? classes.WindowContainerNotifications : classes.WindowContainer}
                          sandbox={sandbox}
                          animate={isActive ? 'open' : 'closed'}
                          initial='closed'
                          variants={{
                              closed: {opacity: 0, y: 4},
                              open: {opacity: 1, y: 0},
                          }}
                          transition={{duration: 0.2, ease: 'easeIn'}}
                          src={`${iframeUrl}?${query}`}
                          style={
                              isActive
                                  ? chatContainerStyle
                                  : {
                                      pointerEvents: 'none',
                                      height: 0,
                                      minHeight: 0,
                                  }
                          }
                      >
                          Loading...
                      </motion.iframe>

                      {!hideToggleButton && (
                          <motion.div
                              className={classes.ToggleButtonContainer}
                              initial={false}
                              animate={isOpen ? 'open' : 'closed'}
                          >
                              {renderToggleButton &&
                              typeof renderToggleButton === 'function' ? (
                                  renderToggleButton({
                                      isOpen,
                                      onToggleOpen,
                                      isDisabled: isTransitioning,
                                  })
                              ) : (
                                  <WidgetToggle
                                      style={{...toggleButtonStyle, backgroundColor: primaryColor}}
                                      isDisabled={isTransitioning}
                                      isOpen={isOpen}
                                      isLoading={needOpen}
                                      customIconUrl={customIconUrl}
                                      iconVariant={iconVariant}
                                      toggle={onToggleOpen}
                                  />
                              )}
                          </motion.div>
                      )}
                  </React.Fragment>
              );
          }}
      </ChatWidgetContainer>
    </ErrorBoundary>
  );
};

export default ChatWidget;
