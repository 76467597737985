import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { SiteContext } from '@homeplay/app';
import { CashInMethod, Page } from '@homeplay/components';
import classes from '../CashIn.module.scss';

const LoadFunds = () => {
  const { siteName, isTouchUnit } = useContext(SiteContext);

  return (
    <Page headerContent={'Load Funds'}>
      <div className="container">
        <div className={classes.MethodsList}>
          <CashInMethod
            path={`/${siteName ? siteName + '/' : ''}cashin/voucher`}
            title="Deposit using voucher"
            description="Use this method if you already have a 12-digits or 16-digits PIN voucher"
          >
            <svg
              className="color-primary"
              viewBox="0 0 160 214"
              width="100%"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M64.6999 74.6998C67.4999 79.1998 71.2999 80.7998 76.2999 78.6998C79.5999 77.2998 83.3999 74.1998 81.7999 70.0998C80.7999 67.7998 78.6999 67.3998 76.4999 67.4998C75.3999 67.5998 73.0999 67.8998 69.4999 68.3998C64.8999 69.0998 61.3999 68.9998 58.9999 68.1998C55.6999 66.9998 53.4999 64.8998 52.1999 61.7998C49.2999 54.7998 53.7999 49.1998 59.5999 46.0998L56.5999 38.7998L62.6999 36.2998L65.6999 43.6998C71.2999 42.2998 76.6999 43.0998 80.1999 47.9998C78.3999 49.9998 75.2999 52.9998 73.9999 54.2998C71.5999 50.2998 68.6999 49.8998 64.4999 51.5998C62.0999 52.5998 58.0999 54.9998 59.3999 58.1998C60.7999 61.5998 67.1999 60.1998 69.8999 59.6998C77.9999 58.3998 85.8999 57.6998 89.5999 66.8998C92.6999 74.5998 88.1999 80.4998 81.9999 83.9998L84.2999 89.7998L77.1999 89.6998L75.8999 86.5998C69.3999 88.4998 63.7999 87.2998 59.5999 82.1998C61.4999 79.1998 62.3999 78.0998 64.6999 74.6998Z"
                fill="currentColor"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M117.4 89.5999C108.3 59.5999 96.8999 29.5999 84.1999 0.399902C75.0999 3.6999 66.0999 7.0999 57.0999 10.3999C61.8999 15.4999 59.9999 23.9999 53.2999 26.4999C46.5999 28.9999 39.6999 23.6999 39.9999 16.6999C30.9999 19.9999 21.8999 23.3999 12.8999 26.6999C22.3999 47.2999 31.4999 68.5999 38.6999 89.6999H45.6999C39.2999 69.8999 30.7999 50.0999 21.6999 30.7999L36.4999 25.2999C40.2999 31.5999 48.0999 34.5999 55.2999 31.8999C62.3999 29.2999 66.4999 21.8999 65.2999 14.5999C70.1999 12.7999 75.1999 10.9999 80.0999 9.1999C91.8999 35.1999 102.7 62.0999 110.1 89.4999L117.4 89.5999Z"
                fill="currentColor"
              />
              <path
                d="M134.2 89.1997H31.7002V96.9997H134.2V89.1997Z"
                fill="currentColor"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M140 89.1998C150.9 89.8998 159.5 99.0998 159.5 110.2V192.5C159.5 204.1 150.1 213.6 138.6 213.6H21.4C9.9 213.6 0.5 204.1 0.5 192.5V110.2C0.5 84.5998 15.7 72.2998 34.7 71.3998L40.4 89.2998H114L107.8 71.2998H124.4C132.9 71.2998 139.9 78.2998 139.9 86.8998V89.1998H140ZM135.1 141.9C140.8 141.9 145.4 146.5 145.4 152.1C145.4 157.8 140.8 162.4 135.2 162.4C129.6 162.4 125 157.8 125 152.1C125 146.5 129.5 141.9 135.1 141.9ZM128 136.2H151.3V113C151.3 102.6 144.2 96.7998 133.9 96.7998H26.1C15.8 96.7998 8.7 102.6 8.7 113V189.6C8.7 200 15.8 205.7 26.1 205.7H133.9C144.2 205.7 151.3 200 151.3 189.6V168H128C107.4 168.1 108.4 136.6 128 136.2Z"
                fill="currentColor"
              />
            </svg>
          </CashInMethod>

          <CashInMethod
            path={`/${siteName ? siteName + '/' : ''}cashin/phone`}
            title="Deposit directly from your voucher wallet"
            description="Use this method if you have voucher account linked to your phone number"
          >
            <svg
              className="color-primary"
              viewBox="0 0 198 212"
              width="100%"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.3 0.399902H109.1C115.6 0.399902 120.9 5.6999 120.9 12.2999V67.1999C118.4 72.0999 115.2 78.1999 112 83.8999V15.9999C112 12.0999 108.9 8.9999 105 8.9999H89.6L84.6 17.8999C82.9 20.8999 78.7 23.3999 75.1 23.3999C65.5 23.3999 56 23.3999 46.4 23.3999C42.9 23.3999 38.6 20.8999 36.9 17.8999L31.9 8.9999H16.4C12.5 8.9999 9.4 12.1999 9.4 15.9999V189.1C9.4 193 12.5 196.1 16.4 196.1H70C70.4 198.6 71.5 201.8 74.7 204.7H12.3C5.8 204.7 0.5 199.4 0.5 192.8V12.2999C0.5 5.6999 5.8 0.399902 12.3 0.399902Z"
                fill="currentColor"
              />
              <path
                d="M48.7001 83.9999C49.9001 90.7999 53.6001 94.5999 60.7001 94.5999C65.4001 94.5999 71.4001 92.6999 71.4001 86.9999C71.4001 83.6999 69.1001 82.2999 66.3001 81.2999C65.0001 80.7999 62.1001 80.0999 57.4001 78.8999C51.5001 77.3999 47.3001 75.5999 44.9001 73.4999C41.6001 70.4999 39.9001 66.7999 39.9001 62.3999C39.9001 52.5999 48.0001 48.0999 56.4001 47.1999V36.8999H65.0001V47.2999C72.5001 48.3999 78.5001 51.9999 80.3001 59.5999C77.1001 61.0999 72.0001 63.1999 69.8001 64.0999C68.8001 58.0999 65.6001 56.0999 59.8001 56.0999C56.5001 56.0999 50.5001 57.0999 50.5001 61.4999C50.5001 66.2999 58.9001 67.6999 62.3001 68.4999C72.6001 70.9999 82.5001 73.9999 82.5001 86.7999C82.5001 97.5999 74.3001 102.4 65.1001 103.5V112.8H56.5001V103.5C47.8001 102.5 41.7001 98.2999 39.1001 90.0999C42.7001 87.7999 44.2001 86.8999 48.7001 83.9999Z"
                fill="currentColor"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M56.6001 129.9H64.8001C73.3001 129.9 73.3001 144.9 64.8001 144.9H56.6001C48.1001 144.9 48.1001 129.9 56.6001 129.9ZM38.8001 169.5H30.6001C22.1001 169.5 22.1001 184.5 30.6001 184.5H38.8001C47.3001 184.5 47.3001 169.5 38.8001 169.5ZM38.8001 149.7H30.6001C22.1001 149.7 22.1001 164.7 30.6001 164.7H38.8001C47.3001 164.7 47.3001 149.7 38.8001 149.7ZM38.8001 129.9H30.6001C22.1001 129.9 22.1001 144.9 30.6001 144.9H38.8001C47.3001 144.9 47.3001 129.9 38.8001 129.9ZM56.6001 169.5H64.8001C73.3001 169.5 73.3001 184.5 64.8001 184.5H56.6001C48.1001 184.5 48.1001 169.5 56.6001 169.5ZM56.6001 149.7H64.8001C73.3001 149.7 73.3001 164.7 64.8001 164.7H56.6001C48.1001 164.7 48.1001 149.7 56.6001 149.7Z"
                fill="currentColor"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M179.7 110.8C188.5 111.4 195.5 118.8 195.5 127.8V194.5C195.5 203.9 187.9 211.6 178.5 211.6H83.6001C74.3001 211.6 66.6001 203.9 66.6001 194.5V127.8C66.6001 106.2 79.9001 96.2998 96.2001 96.2998H104.7C102.1 101.1 99.9001 106.3 98.1001 110.8H167C169.1 106.4 171.4 101.7 173.4 98.0998C177.1 100.3 179.6 104.4 179.6 109V110.8H179.7ZM175.8 153.5C180.4 153.5 184.1 157.2 184.1 161.8C184.1 166.4 180.4 170.1 175.8 170.1C171.2 170.1 167.5 166.4 167.5 161.8C167.5 157.3 171.2 153.5 175.8 153.5ZM170 148.9H188.9V130.1C188.9 121.7 183.2 117 174.8 117H87.4001C79.0001 117 73.3001 121.7 73.3001 130.1V192.2C73.3001 200.6 79.0001 205.3 87.4001 205.3H174.8C183.2 205.3 188.9 200.6 188.9 192.2V174.7H170C153.3 174.7 154.1 149.2 170 148.9Z"
                fill="currentColor"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M94.2998 114.2C106.3 85.9999 120.6 58.6999 136.3 31.8999C144.1 35.8999 151.8 39.8999 159.6 43.8999C154.8 47.9999 155.5 55.7999 161.3 58.7999C167 61.7999 173.8 57.7999 174.2 51.3999C182 55.3999 189.7 59.3999 197.5 63.3999C187.2 80.0999 177.4 96.8999 168.9 114.2H162.1C169.9 97.7999 179.2 81.7999 189.1 66.0999L176.4 59.5999C172.3 64.8999 165 66.6999 158.8 63.4999C152.7 60.3999 149.8 53.2999 151.7 46.8999C147.5 44.6999 143.2 42.4999 139 40.3999C124.5 64.0999 111.3 88.4999 101.4 114.3H94.2998V114.2Z"
                fill="currentColor"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M171.8 82.1997L168.1 80.3997C165.9 79.2997 164.2 82.6997 166.5 83.7997L170.2 85.5997C172.4 86.5997 174 83.1997 171.8 82.1997ZM134.5 64.0997L130.8 62.2997C128.6 61.1997 127 64.5997 129.2 65.6997L132.9 67.4997C135.1 68.4997 136.7 65.0997 134.5 64.0997ZM143.8 68.5997L140.1 66.7997C137.9 65.6997 136.3 69.0997 138.5 70.1997L142.2 71.9997C144.4 72.9997 146 69.6997 143.8 68.5997ZM153.1 73.0997L149.4 71.2997C147.2 70.1997 145.6 73.5997 147.8 74.6997L151.5 76.4997C153.7 77.5997 155.3 74.1997 153.1 73.0997ZM162.4 77.5997L158.7 75.7997C156.5 74.6997 154.8 78.0997 157.1 79.1997L160.8 80.9997C163 82.0997 164.7 78.6997 162.4 77.5997Z"
                fill="currentColor"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M145.8 100.1L141.6 98.0997C139.1 96.8997 137.2 100.7 139.7 101.9L143.9 103.9C146.5 105.2 148.4 101.3 145.8 100.1ZM120.3 98.6997L116.1 96.6997C113.6 95.4997 111.7 99.2997 114.3 100.5L118.5 102.5C121.1 103.7 122.9 99.8997 120.3 98.6997ZM131 103.8L126.8 101.8C124.3 100.6 122.4 104.4 125 105.6L129.2 107.6C131.7 108.9 133.5 105.1 131 103.8ZM141.6 109L137.4 107C134.9 105.8 133 109.6 135.5 110.8L139.7 112.8C142.3 114 144.1 110.2 141.6 109ZM156.4 105.3L152.2 103.3C149.7 102.1 147.8 105.9 150.4 107.1L154.6 109.1C157.1 110.3 159 106.5 156.4 105.3ZM124.6 89.7997L120.4 87.7997C117.9 86.5997 116 90.3997 118.5 91.5997L122.7 93.5997C125.3 94.8997 127.1 90.9997 124.6 89.7997ZM135.2 94.9997L131 92.9997C128.5 91.7997 126.6 95.5997 129.2 96.7997L133.4 98.7997C135.9 99.9997 137.7 96.1997 135.2 94.9997Z"
                fill="currentColor"
              />
            </svg>
          </CashInMethod>
        </div>

        {!isTouchUnit && (
          <Link
            to={`/${siteName ? siteName + '/' : ''}cashin/help`}
            state={{ from: `/${siteName ? siteName + '/' : ''}cashin` }}
            className="link"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="fill-primary"
                d="M12 2C17.514 2 22 6.486 22 12C22 17.514 17.514 22 12 22C6.486 22 2 17.514 2 12C2 6.486 6.486 2 12 2ZM12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM13.25 17C13.25 17.69 12.691 18.25 12 18.25C11.311 18.25 10.75 17.69 10.75 17C10.75 16.31 11.311 15.75 12 15.75C12.691 15.75 13.25 16.31 13.25 17ZM14.643 7.002C14.035 6.386 13.128 6.047 12.092 6.047C9.912 6.047 8.502 7.597 8.502 9.997H10.513C10.513 8.511 11.342 7.984 12.051 7.984C12.685 7.984 13.358 8.405 13.415 9.21C13.477 10.057 13.025 10.487 12.453 11.031C11.041 12.374 11.015 13.024 11.021 14.499H13.026C13.013 13.835 13.056 13.296 13.961 12.321C14.638 11.591 15.48 10.683 15.497 9.299C15.508 8.375 15.213 7.58 14.643 7.002Z"
              />
            </svg>
            <span>Where to Buy Voucher?</span>
          </Link>
        )}
      </div>
    </Page>
  );
};

export default LoadFunds;
