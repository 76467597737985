import { combineReducers } from 'redux';

import authReducer from './authSlice';
import unitReducer from './unitSlice';
import promotionsReducer from './promotionsSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  unit: unitReducer,
  promotions: promotionsReducer,
});

export default rootReducer;
