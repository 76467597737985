import React from 'react';
import { GamesList, ProductPage } from '@homeplay/components';
import classes from './PTClub.module.scss';
import { selectPtcGames } from '@homeplay/app';
import logoPTC from '../../../../shared/assets/images/ptc-logo.png';
import { useSelector } from 'react-redux';

const PTClub = () => {
  const ptcGames = useSelector(selectPtcGames);

  const getGameLink = (loadParams) => {
    return `${process.env.REACT_APP_PTC_BASE_URL}/${loadParams.path}?hash=${loadParams.hash}&curr=ZAR&w=w1&lang=en&config=1861&exit=${window.location.origin}/exit`;
  };

  return (
    <ProductPage getGameLink={getGameLink} customClass={classes.PTC}>
      <div className="container">
        <img
          className={classes.Logo}
          src={logoPTC}
          alt="Palm Treasures Club"
          width="237"
          height="144"
        />
        {ptcGames && <GamesList games={ptcGames} />}
      </div>
    </ProductPage>
  );
};

export default PTClub;
