import React, { useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PRODUCTS } from '@homeplay/api';
import {
  SiteContextProvider,
  UnitSocketProvider,
  autoLogin,
  loadUnitFail,
  selectHHConfigs,
  selectIsRegistrationRequired,
  selectIsVerificationEnabled,
  selectProducts,
  selectUser,
} from '@homeplay/app';
import { apolloRedirects } from '@homeplay/config/redirects';
import {
  Auth,
  CashIn,
  CashOut,
  Club888,
  Fafi,
  GreenDream,
  InfoPage,
  PlaynGo,
  PPLiveCasino,
  PTClub,
  VisoGames,
  NetEnt,
  ResetPassword,
  RLN,
  OnlyPlay,
  OLN,
  Statement,
  BonusGames,
  EditProfile,
  Verification,
  Aviator,
  Profile,
  EditPhone,
} from '@homeplay/containers';
import { Layout } from '@homeplay/components';
import MainPage from './containers/MainPage';

const PAPER_PUSHER_SITES = [
  'jennifer',
  'gretta',
  'charlotta',
  'maria',
  'nina',
  'urilia',
  'vince',
  'kiko',
  'ivette',
  'lowell',
  'victoria',
  'clare',
  'flora',
  'maxy',
  'rima',
  'nataly',
  'lada',
  'netty',
  'bonsai',
  'roxanne',
  'coffee',
  'lota',
  'azul',
  'roho',
  'ellin',
  'blanco',
  'king',
  'olana',
  'iune',
  'iggy',
  'sands',
  'supa',
];

function App() {
  const { site: siteName } = useParams();
  const products = useSelector(selectProducts);
  const hhConfigs = useSelector(selectHHConfigs);
  const user = useSelector(selectUser);
  const isVerificationEnabled = useSelector(selectIsVerificationEnabled);
  const isRegistrationRequired = useSelector(selectIsRegistrationRequired);
  const dispatch = useDispatch();
  const location = useLocation();

  let isTouchUnit = false;
  const query = new URLSearchParams(location.search);
  for (let param of query.entries()) {
    if (param[0] === 'touchscreen') {
      isTouchUnit = true;
    }
  }
  if (isTouchUnit) {
    sessionStorage.setItem('hp-touchscreen', '1');
  } else if (sessionStorage.getItem('hp-touchscreen')) {
    isTouchUnit = true;
  }

  //TODO: Temporary solution
  if (!isTouchUnit && PAPER_PUSHER_SITES.includes(siteName)) {
    window.location.replace('https://mobigames.bet');
  }

  //TODO: Temporary SR-179
  if (!isTouchUnit && apolloRedirects.includes(siteName)) {
    window.location.replace(`${process.env.REACT_APP_BASE_URL}/erika`);
  }

  if (!isTouchUnit && ['juliet', 'josin'].includes(siteName)) {
    window.location.replace(`${process.env.REACT_APP_BASE_URL}/amber`);
  }

  useEffect(() => {
    const site = siteName.charAt(0).toUpperCase() + siteName.slice(1);
    const baseUrl = window.location.origin;
    const dynamicManifest = {
      short_name: site,
      name: site,
      icons: [
        {
          src: `${baseUrl}/logo57pwa.png`,
          type: 'image/png',
          sizes: '57x57',
          purpose: 'any maskable',
        },
        {
          src: `${baseUrl}/logo60pwa.png`,
          type: 'image/png',
          sizes: '60x60',
          purpose: 'any maskable',
        },
        {
          src: `${baseUrl}/logo72pwa.png`,
          type: 'image/png',
          sizes: '72x72',
          purpose: 'any maskable',
        },
        {
          src: `${baseUrl}/logo76pwa.png`,
          type: 'image/png',
          sizes: '76x76',
          purpose: 'any maskable',
        },
        {
          src: `${baseUrl}/logo114pwa.png`,
          type: 'image/png',
          sizes: '114x114',
          purpose: 'any maskable',
        },
        {
          src: `${baseUrl}/logo120pwa.png`,
          type: 'image/png',
          sizes: '120x120',
          purpose: 'any maskable',
        },
        {
          src: `${baseUrl}/logo144pwa.png`,
          type: 'image/png',
          sizes: '144x144',
          purpose: 'any maskable',
        },
        {
          src: `${baseUrl}/logo152pwa.png`,
          type: 'image/png',
          sizes: '152x152',
          purpose: 'any maskable',
        },
        {
          src: `${baseUrl}/logo180pwa.png`,
          type: 'image/png',
          sizes: '180x180',
          purpose: 'any maskable',
        },
        {
          src: `${baseUrl}/logo192pwa.png`,
          type: 'image/png',
          sizes: '192x192',
          purpose: 'any maskable',
        },
        {
          src: `${baseUrl}/logo32pwa.png`,
          type: 'image/png',
          sizes: '32x32',
          purpose: 'any maskable',
        },
        {
          src: `${baseUrl}/logo96pwa.png`,
          type: 'image/png',
          sizes: '96x96',
          purpose: 'any maskable',
        },
        {
          src: `${baseUrl}/logo16pwa.png`,
          type: 'image/png',
          sizes: '16x16',
          purpose: 'any maskable',
        },
        {
          src: `${baseUrl}/logo512pwa.png`,
          type: 'image/png',
          sizes: '512x512',
          purpose: 'any maskable',
        },
      ],
      start_url: `${baseUrl}/${siteName}`,
      display: 'standalone',
      theme_color: '#000000',
      background_color: '#ffffff',
    };
    const stringManifest = JSON.stringify(dynamicManifest);
    const blob = new Blob([stringManifest], { type: 'application/json' });
    const manifestURL = URL.createObjectURL(blob);
    document
      .querySelector('#manifest-placeholder')
      .setAttribute('href', manifestURL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // TODO temporary solution
    if (siteName === 'dalila' && !isTouchUnit) {
      dispatch(
        loadUnitFail({
          error: { message: 'This site is not active now.', redirect: '/' },
        })
      );
    } else {
      dispatch(autoLogin(siteName, isTouchUnit));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteName]);

  let enabledProducts = {
    'only-play': true,
    'pp-live-casino': true,
    rln: true,
    fafi: true,
    oln: true,
  };
  let enabledHH = true;
  if (products) {
    enabledHH = !!hhConfigs;
    enabledProducts = {};
    products.forEach((product) => {
      enabledProducts[product.id] = true;
    });
  }

  return (
    <SiteContextProvider siteName={siteName} isTouchUnit={isTouchUnit}>
      <UnitSocketProvider siteName={siteName} isTouchUnit={isTouchUnit}>
        <Layout>
          <Routes>
            <Route path={'/'} element={<MainPage />} />
            <Route path={`${PRODUCTS.aviator.id}/*`} element={<Aviator />} />
            <Route path={`${PRODUCTS.ptc.id}/*`} element={<PTClub />} />
            <Route path={`${PRODUCTS.club888.id}/*`} element={<Club888 />} />
            <Route path={`${PRODUCTS.playnGo.id}/*`} element={<PlaynGo />} />
            <Route
              path={`${PRODUCTS.greenDream.id}/*`}
              element={<GreenDream />}
            />
            <Route
              path={`${PRODUCTS.visoGames.id}/*`}
              element={<VisoGames />}
            />
            <Route path={`${PRODUCTS.netEnt.id}/*`} element={<NetEnt />} />
            {enabledProducts[PRODUCTS.fafi.id] && (
              <Route path={`${PRODUCTS.fafi.id}/*`} element={<Fafi />} />
            )}
            {enabledProducts[PRODUCTS.onlyPlay.id] && (
              <Route
                path={`${PRODUCTS.onlyPlay.id}/*`}
                element={<OnlyPlay />}
              />
            )}
            {enabledProducts[PRODUCTS.ppLiveCasino.id] && (
              <Route
                path={`${PRODUCTS.ppLiveCasino.id}/*`}
                element={<PPLiveCasino />}
              />
            )}
            {enabledProducts[PRODUCTS.rln.id] && (
              <Route path={`${PRODUCTS.rln.id}/*`} element={<RLN />} />
            )}
            {enabledProducts[PRODUCTS.oln.id] && (
              <Route path={`${PRODUCTS.oln.id}/*`} element={<OLN />} />
            )}
            <Route path={'auth'} element={<Auth />} />
            <Route path={'reset-password'} element={<ResetPassword />} />
            {(user || !isRegistrationRequired) && (
              <Route path={'cashin/*'} element={<CashIn />} />
            )}
            <Route path={'cashout/*'} element={<CashOut />} />
            {enabledHH && <Route path={'happy-hour'} element={<InfoPage />} />}
            {enabledHH && (
              <Route path={'bonus-games'} element={<BonusGames />} />
            )}
            {!!user && <Route path={'profile'} element={<Profile />} />}
            {!!user && <Route path={'statement'} element={<Statement />} />}
            {!!user && (
              <Route path={'edit-profile'} element={<EditProfile />} />
            )}
            {!!user && <Route path={'edit-phone'} element={<EditPhone />} />}
            {!!user && isVerificationEnabled && !isTouchUnit && (
              <Route path={'verification/*'} element={<Verification />} />
            )}
            <Route path="*" element={<Navigate to=".." replace />} />
          </Routes>
        </Layout>
      </UnitSocketProvider>
    </SiteContextProvider>
  );
}

export default App;
