import React, { useEffect, useState } from 'react';
import SiteContext from './siteContext';

const SiteContextProvider = ({
  children,
  siteName = null,
  isTouchUnit,
  cashoutPeriod = null,
}) => {
  const [site, setSite] = useState({
    siteName: siteName,
    isDesktop: window.innerWidth > 991,
    isTouchUnit,
    screenKeyboard: null,
    enableFullScreen: false,
    cashoutPeriod,
  });

  useEffect(() => {
    if (
      !isTouchUnit &&
      ('ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0) &&
      (document.requestFullscreen ||
        document.webkitRequestFullscreen ||
        document.mozRequestFullScreen ||
        document.exitFullscreen ||
        document.webkitExitFullscreen ||
        document.mozCancelFullScreen)
    ) {
      setSite((site) => ({
        ...site,
        enableFullScreen: true,
      }));
    }

    window.addEventListener('resize', resizeApp);
    return () => window.removeEventListener('resize', resizeApp);
  }, []);

  const resizeApp = () => {
    setSite((site) => ({
      ...site,
      isDesktop: window.innerWidth > 991,
    }));
  };

  const setScreenKeyboard = (screenKeyboard) => {
    setSite((site) => ({
      ...site,
      screenKeyboard,
    }));
  };

  return (
    <SiteContext.Provider
      value={{
        ...site,
        setScreenKeyboard,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};

export default SiteContextProvider;
