import React, { useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useState from 'react-usestateref';
import {
  SiteContext,
  cashOperationFail,
  cashOperationStart,
  cashOperationSuccess,
  selectUnitToken,
  loadUnitStart,
  disableUnitLoading,
  selectShowAcoinLink,
} from '@homeplay/app';
import { CashInTerminal, Page } from '@homeplay/components';
import Divider from '@homeplay/components/src/UI/Divider';
import {
  handleError,
  cashInTicket,
  startAcoinCashIn,
  cancelAcoinCashIn,
  completeAcoinCashIn,
  startAcoinPhoneRedemption,
} from '@homeplay/api';
import { getValidPhoneNumber } from '@homeplay/utils';
import { useNotification } from '@homeplay/hooks';

const CashinByVoucher = () => {
  const { siteName, isTouchUnit } = useContext(SiteContext);
  const [acoinBatch, setAcoinBatch, acoinBatchRef] = useState(null);
  const unitToken = useSelector(selectUnitToken);
  const showAcoinLink = useSelector(selectShowAcoinLink);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { depositNotification } = useNotification();

  useEffect(() => {
    let timerId;
    if (acoinBatch) {
      timerId = setTimeout(
        () => {
          setAcoinBatch(null);
        },
        acoinBatch.expires * 1000 - Date.now()
      );

      return () => {
        clearTimeout(timerId);
        const redemptionJSON = localStorage.getItem('hp-pending-redemption');
        const batch = acoinBatchRef.current;
        if (batch && redemptionJSON) {
          if (batch.expires && batch.expires * 1000 > Date.now()) {
            cancelAcoinTransaction(batch.transactionToken);
          }
          setAcoinBatch(null);
        }
      };
    }
  }, [acoinBatch]);

  const applyToken = async (tokenOrPhone) => {
    try {
      switch (redemptionInputType(tokenOrPhone)) {
        case 'voucher':
          dispatch(loadUnitStart());
          const { amount, expires, transactionToken } = await startAcoinCashIn(
            unitToken,
            tokenOrPhone
          );
          setAcoinBatch({ amount, expires, transactionToken });
          dispatch(disableUnitLoading());
          break;

        case 'ticket':
          dispatch(
            cashOperationStart({ loadingMessage: 'Progressing. Please wait' })
          );
          const {
            balance,
            depositBalance,
            depositAmount,
            status: cashinStatus,
          } = await cashInTicket(unitToken, tokenOrPhone);
          if (cashinStatus === 'completed') {
            dispatch(cashOperationSuccess({ balance, depositBalance }));
            navigate(`/${siteName}`);
            depositNotification(depositAmount);
          } else {
            dispatch(
              cashOperationFail({
                error: {
                  message:
                    "Ticket loading is still in progress. We'll continue our attempts to load your ticket. You can close this page and check the loading ticket status in your statement later",
                  redirect: null,
                },
              })
            );
          }
          break;

        case 'phone':
          const phoneNumber = getValidPhoneNumber(tokenOrPhone);
          dispatch(cashOperationStart());
          const { confirmationUrl, status } = await startAcoinPhoneRedemption(
            unitToken,
            phoneNumber,
            siteName
          );
          if (status === 'in_progress') {
            window.location.replace(confirmationUrl);
          } else {
            dispatch(
              cashOperationFail({
                error: { message: 'Something Wrong', redirect: null },
              })
            );
          }
          break;
      }
    } catch (error) {
      dispatch(cashOperationFail({ error: handleError(error) }));
    }
  };

  const redemptionInputType = (value) => {
    if (value.length === 16) {
      return 'voucher';
    }

    if (value.length === 12) {
      return 'ticket';
    }

    return 'phone';
  };

  const cancelAcoinTransaction = async (transactionToken) => {
    try {
      await cancelAcoinCashIn(unitToken, transactionToken);
    } catch (error) {
      dispatch(cashOperationFail({ error: handleError({}, 'reload') }));
    }
  };

  const completeAcoinTransaction = async (transactionToken, amount) => {
    dispatch(cashOperationStart());
    try {
      const { balance, depositBalance } = await completeAcoinCashIn(
        unitToken,
        transactionToken,
        amount
      );
      navigate(`/${siteName}`);
      dispatch(cashOperationSuccess({ balance, depositBalance }));
      depositNotification(amount);
    } catch (error) {
      dispatch(cashOperationFail({ error: handleError({}, 'reload') }));
    }
  };

  return (
    <Page
      headerContent={'Load Funds by Voucher'}
      backPath={
        !isTouchUnit && showAcoinLink
          ? `/${siteName ? siteName + '/' : ''}cashin`
          : null
      }
    >
      <div className="container">
        <CashInTerminal
          applyToken={applyToken}
          completeTransaction={completeAcoinTransaction}
          acoinBatch={acoinBatch}
          cancelTransaction={cancelAcoinTransaction}
          backPath={`${siteName ? '/' + siteName : ''}${
            !isTouchUnit && showAcoinLink ? '/cashin' : ''
          }`}
        />
        <Divider />
        {!isTouchUnit && (
          <Link
            to={`/${siteName ? siteName + '/' : ''}cashin/help`}
            state={{ from: `/${siteName ? siteName + '/' : ''}cashin` }}
            className="link"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="fill-primary"
                d="M12 2C17.514 2 22 6.486 22 12C22 17.514 17.514 22 12 22C6.486 22 2 17.514 2 12C2 6.486 6.486 2 12 2ZM12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM13.25 17C13.25 17.69 12.691 18.25 12 18.25C11.311 18.25 10.75 17.69 10.75 17C10.75 16.31 11.311 15.75 12 15.75C12.691 15.75 13.25 16.31 13.25 17ZM14.643 7.002C14.035 6.386 13.128 6.047 12.092 6.047C9.912 6.047 8.502 7.597 8.502 9.997H10.513C10.513 8.511 11.342 7.984 12.051 7.984C12.685 7.984 13.358 8.405 13.415 9.21C13.477 10.057 13.025 10.487 12.453 11.031C11.041 12.374 11.015 13.024 11.021 14.499H13.026C13.013 13.835 13.056 13.296 13.961 12.321C14.638 11.591 15.48 10.683 15.497 9.299C15.508 8.375 15.213 7.58 14.643 7.002Z"
              />
            </svg>
            <span>Where to Buy Voucher?</span>
          </Link>
        )}
      </div>
    </Page>
  );
};

export default CashinByVoucher;
