import React from 'react';
import { Link } from 'react-router-dom';
import classes from './Button.module.scss';

const Button = ({
  children,
  navPath,
  state = {},
  onClick,
  disabled = false,
  customClass,
  fakeDisabled = false,
}) => {
  let button;
  if (navPath && !disabled) {
    button = (
      <Link
        to={navPath}
        state={state}
        className={
          classes.Button +
          ' button-primary' +
          (customClass ? ' ' + customClass : '')
        }
      >
        {children}
      </Link>
    );
  } else if (onClick || (navPath && disabled)) {
    button = (
      <span
        onClick={onClick && !disabled ? onClick : null}
        className={
          classes.Button +
          ' button-primary' +
          (customClass ? ' ' + customClass : '') +
          (disabled ? ' ' + classes.Disabled : '')
        }
      >
        {children}
      </span>
    );
  } else {
    button = (
      <button
        type="submit"
        className={
          classes.Button +
          ' button-primary' +
          (customClass ? ' ' + customClass : '') +
          (disabled ? ' ' + classes.Disabled : '') +
          (fakeDisabled ? ' disabled' : '')
        }
        disabled={disabled}
      >
        {children}
      </button>
    );
  }

  return button;
};

export default React.memo(Button);
