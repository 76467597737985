import React from 'react';
import { GamesList, ProductPage } from '@homeplay/components';
import classes from './OLN.module.scss';
import { selectOlnGames, selectTopOlnGames } from '@homeplay/app';
import olnLogo from '../../../../shared/assets/images/oln-logo.png';
import { useSelector } from 'react-redux';

const OLN = () => {
  const olnGames = useSelector(selectOlnGames);
  const topOlnGames = useSelector(selectTopOlnGames);

  const getGameLink = (loadParams) => {
    return `${process.env.REACT_APP_OLN_BASE_URL}/lotto/${loadParams.url_slug}?token=${loadParams.player_token}&exit=${window.location.origin}/exit`;
  };

  return (
    <ProductPage
      getGameLink={getGameLink}
      allowScrolling={true}
      customClass={classes.Onl}
    >
      <div className="container">
        <img
          className={classes.Logo}
          src={olnLogo}
          alt="Online Lucky Numbers"
          width="604"
          height="62"
        />

        {topOlnGames && (
          <React.Fragment>
            <h4>Most Popular</h4>
            <GamesList games={topOlnGames} />

            <h4>All Lucky Numbers</h4>
          </React.Fragment>
        )}

        {olnGames && <GamesList games={olnGames} />}
      </div>
    </ProductPage>
  );
};

export default OLN;
