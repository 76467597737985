import React from 'react';
import ModalContainer from '../Modal/ModalContainer';
import ButtonPrimary from '../UI/ButtonPrimary';
import classes from './HappyHour.module.scss';
import hhInfoImg from '../../../../shared/assets/images/happyHour/info.png';
import hhCloseImg from '../../../../shared/assets/images/happyHour/close.png';
import { formatBalance } from '@homeplay/utils';

const InfoModal = ({ onClickClose, amount }) => {
  return (
    <ModalContainer>
      <div className={classes.InfoModal}>
        <img src={hhInfoImg} alt="" width="532" height="235" />
        <img
          onClick={onClickClose}
          className={classes.Close}
          src={hhCloseImg}
          alt=""
          width="58"
          height="58"
        />
        <div className={classes.Content}>
          Spend another <span>{formatBalance(amount, false)}</span> <br /> to
          claim the <br /> Happy Hour bonus
        </div>
        <ButtonPrimary onClick={onClickClose} customClass={classes.Button}>
          OK
        </ButtonPrimary>
      </div>
    </ModalContainer>
  );
};

export default React.memo(InfoModal);
