import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { formatBalance } from '@homeplay/utils';

export const useNotification = () => {
  const depositNotification = useCallback(
    (amount) =>
      toast.info(
        () => (
          <div>
            You have deposited{' '}
            <span style={{ color: 'gold', fontWeight: 700 }}>
              {formatBalance(amount)}
            </span>{' '}
            to your account
          </div>
        ),
        {
          icon: () => '🪙',
          className: 'app-toast-success',
        }
      ),
    []
  );

  const withdrawalNotification = useCallback(
    (amount) =>
      toast.info(
        () => (
          <div>
            You have withdrew{' '}
            <span style={{ color: 'gold', fontWeight: 700 }}>
              {formatBalance(amount)}
            </span>{' '}
            from your account
          </div>
        ),
        {
          className: 'app-toast-success',
        }
      ),
    []
  );

  const editPhoneNotification = useCallback(
    () =>
      toast.info('Success! Your phone number has been changed', {
        className: 'app-toast-success',
      }),
    []
  );

  const operationCancelledNotification = useCallback(
    () => toast.warn('Operation canceled'),
    []
  );

  const errorNotification = useCallback(
    () => toast.error('Something went wrong'),
    []
  );

  return {
    depositNotification,
    withdrawalNotification,
    editPhoneNotification,
    operationCancelledNotification,
    errorNotification,
  };
};
