import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import classes from './Product.module.scss';
import { SiteContext } from '@homeplay/app';

const Product = ({ productId, imgPath, alt, imgWigth, imgHeight }) => {
  const { siteName } = useContext(SiteContext);

  return (
    <div className={`${classes.Product} product`}>
      <Link to={`/${siteName ? siteName + '/' : ''}${productId}`}>
        <img src={imgPath} alt={alt} width={imgWigth} height={imgHeight} />
        <span className="product-button" />
      </Link>
    </div>
  );
};

export default Product;
