import React from 'react';
import Modal from './index';
import Button from '../UI/Button';

const UpdateServiceWorkerModal = () => {
  const onClose = () => {
    window.location.reload();
  };

  return (
    <Modal modalClose={onClose}>
      <h3>Update Available</h3>
      <div className="text-center">
        <p> We have deployed new updates to enhance your experience.</p>
        <p> Please click "OK" to apply the updates</p>
      </div>

      <Button onClick={onClose} customClass="d-block">
        OK
      </Button>
    </Modal>
  );
};

export default UpdateServiceWorkerModal;
