import React, { useEffect, useState } from 'react';
import { Page, TransactionsList } from '@homeplay/components';
import { getTransactions, handleError } from '@homeplay/api';
import { useDispatch, useSelector } from 'react-redux';
import { loadUnitFail, selectUnitToken, selectUser } from '@homeplay/app';
import classes from './Statement.module.scss';
import Button from '@homeplay/components/src/UI/Button';

const Statement = () => {
  const dispatch = useDispatch();
  const unitToken = useSelector(selectUnitToken);
  const user = useSelector(selectUser);
  const [transactions, setTransactions] = useState([]);
  const [cursor, setCursor] = useState(null);
  const [isMore, setIsMore] = useState(false);

  useEffect(() => {
    if (user && unitToken) {
      fetchTransactions();
    }
  }, [unitToken]);

  const fetchTransactions = async () => {
    try {
      const data = await getTransactions(unitToken);
      if (data.success) {
        setTransactions([...transactions, ...data.transactions]);
        setCursor(data.meta.paging.cursor);
        setIsMore(data.meta.paging.more);
      }
    } catch (error) {
      dispatch(loadUnitFail({ error: handleError(error) }));
    }
  };

  const handleClickLoadMore = async () => {
    if (cursor) {
      try {
        const data = await getTransactions(unitToken, cursor);
        if (data.success) {
          setTransactions([...transactions, ...data.transactions]);
          setCursor(data.meta.paging.cursor);
          setIsMore(data.meta.paging.more);
        }
      } catch (error) {
        dispatch(loadUnitFail({ error: handleError(error) }));
      }
    }
  };

  return (
    <Page headerContent={'My Statement'} customClass={classes.Statement}>
      <div className="container">
        <TransactionsList transactions={transactions} />

        {isMore && cursor && (
          <Button onClick={handleClickLoadMore}>Load More</Button>
        )}
      </div>
    </Page>
  );
};

export default Statement;
