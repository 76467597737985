import React from 'react';
import classes from './LcGameItem.module.scss';

const RouletteResults = ({results}) => {
    const resultsItems = results.map((result, index) => (
        <li key={index} className={result.color}>
            {result.result}
        </li>
    ))

    return (
        <ul className={classes.RouletteResults}>
            {resultsItems}
        </ul>
    );
};

export default RouletteResults;
