import {helpers} from './helpers';

export function getUserInfo() {
  const {
    stripEmptyProperties,
    getQueryParam,
  } = helpers();
  const userAgent = window.navigator.userAgent;

  const info = {
    campaignParams: function () {
      const campaign_keywords = 'utm_source utm_medium utm_campaign utm_content utm_term'.split(
          ' '
      );
      let kw = '';
      let params = {};

      campaign_keywords.forEach((kwkey) => {
        kw = getQueryParam(document.URL, kwkey);

        if (kw.length) {
          params[kwkey] = kw;
        }
      });

      return params;
    },

    searchEngine: function (referrer) {
      if (referrer.search('https?://(.*)google.([^/?]*)') === 0) {
        return 'google';
      } else if (referrer.search('https?://(.*)bing.com') === 0) {
        return 'bing';
      } else if (referrer.search('https?://(.*)yahoo.com') === 0) {
        return 'yahoo';
      } else if (referrer.search('https?://(.*)duckduckgo.com') === 0) {
        return 'duckduckgo';
      } else {
        return null;
      }
    },

    searchInfo: function (referrer) {
      const search = info.searchEngine(referrer);
      const param = search !== 'yahoo' ? 'q' : 'p';
      let ret = {};

      if (search !== null) {
        ret['$search_engine'] = search;

        const keyword = getQueryParam(referrer, param);
        if (keyword.length) {
          ret['ph_keyword'] = keyword;
        }
      }

      return ret;
    },

    /**
     * This function detects which browser is running this script.
     * The order of the checks are important since many user agents
     * include key words used in later checks.
     */
    browser: function (user_agent, vendor, opera) {
      vendor = vendor || ''; // vendor is undefined for at least IE9
      if (opera || user_agent.includes(' OPR/')) {
        if (user_agent.includes('Mini')) {
          return 'Opera Mini';
        }
        return 'Opera';
      } else if (/(BlackBerry|PlayBook|BB10)/i.test(user_agent)) {
        return 'BlackBerry';
      } else if (
          user_agent.includes('IEMobile') ||
          user_agent.includes('WPDesktop')
      ) {
        return 'Internet Explorer Mobile';
      } else if (user_agent.includes('SamsungBrowser/')) {
        // https://developer.samsung.com/internet/user-agent-string-format
        return 'Samsung Internet';
      } else if (user_agent.includes('Edge') || user_agent.includes('Edg/')) {
        return 'Microsoft Edge';
      } else if (user_agent.includes('FBIOS')) {
        return 'Facebook Mobile';
      } else if (user_agent.includes('Chrome')) {
        return 'Chrome';
      } else if (user_agent.includes('CriOS')) {
        return 'Chrome iOS';
      } else if (
          user_agent.includes('UCWEB') ||
          user_agent.includes('UCBrowser')
      ) {
        return 'UC Browser';
      } else if (user_agent.includes('FxiOS')) {
        return 'Firefox iOS';
      } else if (vendor.includes('Apple')) {
        if (user_agent.includes('Mobile')) {
          return 'Mobile Safari';
        }
        return 'Safari';
      } else if (user_agent.includes('Android')) {
        return 'Android Mobile';
      } else if (user_agent.includes('Konqueror')) {
        return 'Konqueror';
      } else if (user_agent.includes('Firefox')) {
        return 'Firefox';
      } else if (
          user_agent.includes('MSIE') ||
          user_agent.includes('Trident/')
      ) {
        return 'Internet Explorer';
      } else if (user_agent.includes('Gecko')) {
        return 'Mozilla';
      } else {
        return '';
      }
    },

    /**
     * This function detects which browser version is running this script,
     * parsing major and minor version (e.g., 42.1). User agent strings from:
     * http://www.useragentstring.com/pages/useragentstring.php
     */
    browserVersion: function (userAgent, vendor, opera) {
      const browser = info.browser(userAgent, vendor, opera);
      const versionRegexs = {
        'Internet Explorer Mobile': /rv:(\d+(\.\d+)?)/,
        'Microsoft Edge': /Edge?\/(\d+(\.\d+)?)/,
        Chrome: /Chrome\/(\d+(\.\d+)?)/,
        'Chrome iOS': /CriOS\/(\d+(\.\d+)?)/,
        'UC Browser': /(UCBrowser|UCWEB)\/(\d+(\.\d+)?)/,
        Safari: /Version\/(\d+(\.\d+)?)/,
        'Mobile Safari': /Version\/(\d+(\.\d+)?)/,
        Opera: /(Opera|OPR)\/(\d+(\.\d+)?)/,
        Firefox: /Firefox\/(\d+(\.\d+)?)/,
        'Firefox iOS': /FxiOS\/(\d+(\.\d+)?)/,
        Konqueror: /Konqueror:(\d+(\.\d+)?)/,
        BlackBerry: /BlackBerry (\d+(\.\d+)?)/,
        'Android Mobile': /android\s(\d+(\.\d+)?)/,
        'Samsung Internet': /SamsungBrowser\/(\d+(\.\d+)?)/,
        'Internet Explorer': /(rv:|MSIE )(\d+(\.\d+)?)/,
        Mozilla: /rv:(\d+(\.\d+)?)/,
      };
      const regex = versionRegexs[browser];
      if (regex === undefined) {
        return null;
      }
      const matches = userAgent.match(regex);
      if (!matches) {
        return null;
      }

      return parseFloat(matches[matches.length - 2]).toString();
    },

    os: function () {
      const a = userAgent;
      if (/Windows/i.test(a)) {
        if (/Phone/.test(a) || /WPDesktop/.test(a)) {
          return 'Windows Phone';
        }
        return 'Windows';
      } else if (/(iPhone|iPad|iPod)/.test(a)) {
        return 'iOS';
      } else if (/Android/.test(a)) {
        return 'Android';
      } else if (/(BlackBerry|PlayBook|BB10)/i.test(a)) {
        return 'BlackBerry';
      } else if (/Mac/i.test(a)) {
        return 'Mac OS X';
      } else if (/Linux/.test(a)) {
        return 'Linux';
      } else if (/CrOS/.test(a)) {
        return 'Chrome OS';
      } else {
        return '';
      }
    },

    device: function (user_agent) {
      if (/Windows Phone/i.test(user_agent) || /WPDesktop/.test(user_agent)) {
        return 'Windows Phone';
      } else if (/iPad/.test(user_agent)) {
        return 'iPad';
      } else if (/iPod/.test(user_agent)) {
        return 'iPod Touch';
      } else if (/iPhone/.test(user_agent)) {
        return 'iPhone';
      } else if (/(BlackBerry|PlayBook|BB10)/i.test(user_agent)) {
        return 'BlackBerry';
      } else if (/Android/.test(user_agent)) {
        return 'Android';
      } else {
        return '';
      }
    },

    referringDomain: function (referrer) {
      const split = referrer.split('/');
      if (split.length >= 3) {
        return split[2];
      }
      return '';
    },

    timezone: function (intl) {
      try {
        return intl.DateTimeFormat().resolvedOptions().timeZone;
      } catch (e) {
        return null;
      }
    },

    properties: function () {
      return Object.assign(stripEmptyProperties({
        os: info.os(),
        browser: info.browser(userAgent, window.navigator.vendor, window.opera),
        referrer: document.referrer,
        referring_domain: info.referringDomain(document.referrer),
        device: info.device(userAgent),
        time_zone: info.timezone(window.Intl),
      }), {
        current_url: window.location.href,
        host: window.location.host,
        pathname: window.location.pathname,
        browser_version: info.browserVersion(userAgent, window.navigator.vendor, window.opera),
        screen_height: window.screen.height,
        screen_width: window.screen.width,
        lib: 'web',
        insert_id:
            Math.random().toString(36).substring(2, 10) +
            Math.random().toString(36).substring(2, 10),
        time: Date.now() / 1000, // epoch time in seconds
      })
    },
  };

  return info.properties();
}
