import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useState from 'react-usestateref';
import {
  SiteContext,
  cashOperationFail,
  cashOperationStart,
  selectUnitToken,
  selectUser,
} from '@homeplay/app';
import { Page } from '@homeplay/components';
import Divider from '@homeplay/components/src/UI/Divider';
import { handleError, startAcoinPhoneRedemption } from '@homeplay/api';
import { getValidPhoneNumber } from '@homeplay/utils';
import classes from '../CashIn.module.scss';
import PhoneInput from '@homeplay/components/src/UI/Input/PhoneInput';
import Button from '@homeplay/components/src/UI/Button';
import ButtonSecondary from '@homeplay/components/src/UI/ButtonSecondary';

const CashinByPhone = () => {
  const dispatch = useDispatch();
  const { siteName } = useContext(SiteContext);
  const user = useSelector(selectUser);
  const unitToken = useSelector(selectUnitToken);
  const [phoneNumber, setPhoneNumber] = useState(user?.phone ?? '');

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!isInputsValid()) return;

    try {
      dispatch(cashOperationStart());
      const { confirmationUrl, status } = await startAcoinPhoneRedemption(
        unitToken,
        getValidPhoneNumber(phoneNumber),
        siteName
      );
      if (status === 'in_progress') {
        window.location.replace(confirmationUrl);
      } else {
        dispatch(
          cashOperationFail({
            error: { message: 'Something Wrong', redirect: null },
          })
        );
      }
    } catch (error) {
      dispatch(cashOperationFail({ error: handleError(error) }));
    }
  };

  const isInputsValid = () => {
    return !!getValidPhoneNumber(phoneNumber);
  };

  return (
    <Page
      headerContent={'Load Funds with Phone Number'}
      backPath={`/${siteName ? siteName + '/' : ''}cashin`}
    >
      <div className={`${classes.CashinByPhone} container`}>
        <p>
          Simply use your registered phone number to redeem the desired amount
          from your wallet balance.
        </p>
        <form name="form" onSubmit={handleSubmit}>
          <label className="label" htmlFor="phone">
            Your Phone
          </label>

          <PhoneInput
            id="phone"
            onChange={setPhoneNumber}
            value={phoneNumber}
            focus={true}
            autocomplete="phone"
          />

          <Button disabled={!isInputsValid()} customClass="d-block">
            Submit
          </Button>

          <ButtonSecondary
            navPath={`/${siteName ? siteName + '/' : ''}cashin`}
            value="Cancel"
            customClass="d-block"
          />
        </form>

        <Divider />

        <Link
          to={`/${siteName ? siteName + '/' : ''}cashin/help`}
          state={{ from: `/${siteName ? siteName + '/' : ''}cashin` }}
          className="link"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="fill-primary"
              d="M12 2C17.514 2 22 6.486 22 12C22 17.514 17.514 22 12 22C6.486 22 2 17.514 2 12C2 6.486 6.486 2 12 2ZM12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM13.25 17C13.25 17.69 12.691 18.25 12 18.25C11.311 18.25 10.75 17.69 10.75 17C10.75 16.31 11.311 15.75 12 15.75C12.691 15.75 13.25 16.31 13.25 17ZM14.643 7.002C14.035 6.386 13.128 6.047 12.092 6.047C9.912 6.047 8.502 7.597 8.502 9.997H10.513C10.513 8.511 11.342 7.984 12.051 7.984C12.685 7.984 13.358 8.405 13.415 9.21C13.477 10.057 13.025 10.487 12.453 11.031C11.041 12.374 11.015 13.024 11.021 14.499H13.026C13.013 13.835 13.056 13.296 13.961 12.321C14.638 11.591 15.48 10.683 15.497 9.299C15.508 8.375 15.213 7.58 14.643 7.002Z"
            />
          </svg>
          <span>Where to Buy Voucher?</span>
        </Link>
      </div>
    </Page>
  );
};

export default CashinByPhone;
