import React, { useContext, useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { SiteContext, loadUnitFail, selectUnitToken } from '@homeplay/app';
import { getGameParams, handleError } from '@homeplay/api';
import { Frame } from '@homeplay/components';
import { useDispatch, useSelector } from 'react-redux';

const Game = ({ getGameLink, allowScrolling = false }) => {
  const dispatch = useDispatch();
  const [gameSrc, setGameSrc] = useState(null);
  const { siteName, isTouchUnit } = useContext(SiteContext);
  const unitToken = useSelector(selectUnitToken);
  const { gameSlug } = useParams();
  const location = useLocation();
  let exitUrl;

  if (location.state) {
    exitUrl = location.state.exitUrl;
  } else {
    exitUrl = window.location.origin + `/${siteName}`;
  }

  useEffect(() => {
    if (unitToken) {
      fetchGameParams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitToken]);

  const fetchGameParams = async () => {
    try {
      const data = await getGameParams(
        unitToken,
        gameSlug,
        exitUrl,
        isTouchUnit
      );
      setGameSrc(getGameLink(data.load_params, data.game.vendor_data, exitUrl));
    } catch (error) {
      dispatch(loadUnitFail({ error: handleError(error, exitUrl) }));
    }
  };

  return (
    <Frame
      frameSrc={gameSrc}
      frameTitle="Game Frame"
      allowScrolling={allowScrolling}
    />
  );
};

export default Game;
