export const helpers = () => {
  function isString(obj) {
    return Object.prototype.toString.call(obj) === '[object String]';
  }

  function stripEmptyProperties(obj) {
    let ret = {};

    for (let key in obj) {
      if (isString(obj[key]) && obj[key].length > 0) {
        ret[key] = obj[key];
      }
    }

    return ret;
  }

  function getQueryParam(url, param) {
    // Expects a raw URL
    param = param.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');

    const regexS = '[\\?&]' + param + '=([^&#]*)';
    const regex = new RegExp(regexS);
    const results = regex.exec(url);

    if (results === null || (results && typeof results[1] !== 'string' && results[1].length)) {
      return '';
    } else {
      let result = results[1];
      try {
        result = decodeURIComponent(result);
      } catch (err) {
        console.error('Skipping decoding for malformed query param: ' + result);
      }
      return result.replace(/\+/g, ' ');
    }
  }

  return {
    stripEmptyProperties,
    getQueryParam,
  };
};
