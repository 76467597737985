import React, { useContext } from 'react';
import { SiteContext } from '@homeplay/app';
import Button from '../UI/Button';
import Modal from './index';

const NeedRegisterModal = ({ onClose }) => {
  const { siteName } = useContext(SiteContext);

  return (
    <Modal modalClose={onClose}>
      <div className="text-center">
        <p>You need to log in or create an account before adding funds</p>
      </div>

      <Button
        navPath={`/${siteName ? siteName + '/' : ''}auth`}
        customClass="d-block"
      >
        Login/Register
      </Button>
    </Modal>
  );
};

export default NeedRegisterModal;
