import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Loader } from '@homeplay/components';

const VerificationPreload = React.lazy(() => import('./VerificationPreload'));
const VerificationForm = React.lazy(() => import('./VerificationForm'));
const VerificationInfo = React.lazy(() => import('./VerificationInfo'));

const Verification = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={<VerificationForm />} />
        <Route path="preload" element={<VerificationPreload />} />
        <Route path="info" element={<VerificationInfo />} />
        <Route path="*" element={<Navigate to=".." replace />} />
      </Routes>
    </Suspense>
  );
};

export default Verification;
