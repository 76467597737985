const PREFIX = 'lc_';

const fromFallbackStorage = () => {
  return {
    _db: {},
    getItem(key) {
      return this._db[key] || null;
    },
    setItem(key, value) {
      this._db[key] = value;
    },
    removeItem(key) {
      delete this._db[key];
    },
    // Aliases
    get(key) {
      return this._db[key] || null;
    },
    set(key, value) {
      this._db[key] = value;
    },
    remove(key) {
      delete this._db[key];
    },
  };
};

const fromLocalStorage = (w) => {
  try {
    const storage = w && w.localStorage;

    return {
      ...storage,
      get: (key) => {
        const result = storage.getItem(`${PREFIX}${key}`);

        if (!result) {
          return null;
        }

        try {
          return JSON.parse(result);
        } catch (e) {
          return result;
        }
      },
      set: (key, value) => {
        storage.setItem(`${PREFIX}${key}`, JSON.stringify(value));
      },
      remove: (key) => {
        storage.removeItem(key);
      },
    };
  } catch (e) {
    return fromFallbackStorage();
  }
};

const fromSessionStorage = (w) => {
  try {
    const storage = w && w.sessionStorage;

    // NB: this is the same as `localStorage` above
    return {
      ...storage,
      get: (key) => {
        const result = storage.getItem(`${PREFIX}${key}`);

        if (!result) {
          return null;
        }

        try {
          return JSON.parse(result);
        } catch (e) {
          return result;
        }
      },
      set: (key, value) => {
        storage.setItem(`${PREFIX}${key}`, JSON.stringify(value));
      },
      remove: (key) => {
        storage.removeItem(key);
      },
    };
  } catch (e) {
    return fromFallbackStorage();
  }
};

const fromCookieStorage = () => {
  try {
    throw new Error('Cookie storage has not been implemented!');
  } catch (e) {
    return fromFallbackStorage();
  }
};

// FIXME: this is just a workaround until we can stop
// relying on localStorage in our chat iframe
const getPreferredStorage = (w, type = 'local') => {
  try {
    switch (type) {
      case 'local':
        return fromLocalStorage(w);
      case 'session':
        return fromSessionStorage(w);
      case 'cookie':
        return fromCookieStorage();
      case 'memory':
      default:
        return fromFallbackStorage();
    }
  } catch (e) {
    return fromFallbackStorage();
  }
};

export default function store(w, options = {}) {
  const {defaultType = 'local', openStateType = 'session'} = options;
  // TODO: add support for using cookies as well
  const defaultStorage = getPreferredStorage(w, defaultType);
  const openStateStorage = getPreferredStorage(w, openStateType);

  // TODO: improve these names
  return {
    getCustomerId: () => defaultStorage.get('customer_id'),
    setCustomerId: (id) => defaultStorage.set('customer_id', id),
    removeCustomerId: () => defaultStorage.remove('customer_id'),
    // Open state
    getOpenState: () => openStateStorage.get(':open'),
    setOpenState: (state) =>
      openStateStorage.set(':open', state),
    clearOpenState: () => openStateStorage.remove(':open'),
    // Popup seen state
    getPopupSeen: () => openStateStorage.get(':pop_up_seen'),
    setPopupSeen: (state) =>
      openStateStorage.set(':pop_up_seen', state),
    clearPopupSeen: () => openStateStorage.remove(':pop_up_seen'),
  };
}
