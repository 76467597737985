import React from 'react';
import { Link } from 'react-router-dom';
import classes from './CashInMethod.module.scss';

const CashInMethod = ({ title, description, path, children }) => {
  return (
    <Link to={path} className={classes.CashInMethod}>
      <span>{children}</span>
      <span>
        <span className={`${classes.Title} color-primary`}>{title}</span>
        <span className={classes.Description}>{description}</span>
      </span>
    </Link>
  );
};

export default CashInMethod;
