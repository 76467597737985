import React, { useContext, useEffect, useState } from 'react';
import { SiteContext } from '@homeplay/app';
import { formatBalance } from '@homeplay/utils';
import Key from './Key';
import Button from '../UI/Button';
import ButtonSecondary from '../UI/ButtonSecondary';
import classes from './CashInTerminal.module.scss';

const CashInTerminal = ({
  applyToken,
  acoinBatch,
  completeTransaction,
  cancelTransaction,
  backPath,
}) => {
  const { isTouchUnit } = useContext(SiteContext);
  const [inputValue, setInputValue] = useState([]);
  const [inputError, setInputError] = useState(null);
  const [firstEditAmountClick, setFirstEditAmountClick] = useState(false);

  useEffect(() => {
    if (acoinBatch) {
      const stringAmount = (acoinBatch.amount / 100).toFixed(2) + '';
      setInputValue(stringAmount.split(''));
    } else {
      setInputValue([]);
    }
  }, [acoinBatch]);

  const addDigit = (digit) => {
    if (acoinBatch) {
      if (inputError) {
        setInputError(null);
      }

      if (firstEditAmountClick) {
        const stringValue = formatInputAmountValue(inputValue.join('') + digit);
        const floatValue = parseFloat(stringValue);
        const voucherAmount = (acoinBatch.amount / 100).toFixed(2);
        setInputValue(stringValue.split(''));

        if (floatValue > voucherAmount) {
          setInputError(`Must be less or equal ${voucherAmount}`);
        } else if (floatValue <= 0) {
          setInputError(`Must be greater than 0`);
        }
      } else {
        setFirstEditAmountClick(true);
        setInputValue(['0', '.', '0', '' + digit]);
      }
    } else {
      if (inputValue.length < 16) {
        setInputValue((inputValue) => {
          return [...inputValue, digit];
        });
      }
    }
  };

  const removeDigit = () => {
    if (inputError) {
      setInputError(null);
    }
    if (acoinBatch) {
      if (firstEditAmountClick) {
        const stringValue = formatInputAmountValue(
          inputValue.join('').slice(0, -1).replace(/\D/g, '')
        );
        const floatValue = parseFloat(stringValue);
        const voucherAmount = (acoinBatch.amount / 100).toFixed(2);
        setInputValue(stringValue.split(''));

        if (floatValue > voucherAmount) {
          setInputError(`Must be less or equal ${voucherAmount}`);
        } else if (floatValue <= 0) {
          setInputError(`Must be greater than 0`);
        }
      } else {
        setFirstEditAmountClick(true);
        setInputValue(['0', '.', '0', '0']);
      }
    } else {
      if (inputValue.length) {
        setInputValue((inputValue) => {
          return inputValue.slice(0, -1);
        });
      }
    }
  };

  const onClickApplyHandler = () => {
    const value = inputValue.join('');
    if (acoinBatch) {
      if (acoinBatch.expires && acoinBatch.expires * 1000 > Date.now()) {
        const amount = Math.round(parseFloat(value) * 100);
        completeTransaction(acoinBatch.transactionToken, amount);
      } else {
        cancelTransaction(acoinBatch.transactionToken);
      }
    } else {
      applyToken(value);
      setInputValue([]);
      if (inputError) {
        setInputError(null);
      }
    }
  };

  const pasteFromClipboard = () => {
    navigator.clipboard
      .readText()
      .then((clipboard) => {
        let clipboardContent = clipboard.replace(/[\s-]/g, '');

        const voucher = clipboardContent.match(/([0-9]{16})/g);
        if (!!voucher) {
          setInputValue(voucher[0].split(''));
          return;
        }

        const ticket = clipboardContent.match(/([0-9]{12})/g);
        if (!!ticket) {
          setInputValue(ticket[0].split(''));
          return;
        }

        if (inputValue.length) {
          setInputValue([]);
        }
        setInputError('Clipboard does not contain voucher or ticket');
      })
      .catch((err) => {
        console.log('Something went wrong', err);
      });
  };

  const formatInputAmountValue = (stringAmount) => {
    let value = stringAmount.replace(/\D/g, '').replace(/^0*/g, '');
    if (value === '') {
      return '0.00';
    } else {
      if (value.length < 3) {
        value = ('000' + value).slice(-3);
      }
      return `${value.slice(0, -2)}.${value.slice(-2)}`;
    }
  };

  const displayCode = inputValue.map((item, i) => <span key={i}>{item}</span>);

  const keyboardActive = inputValue.length < 16;

  const isInputValueValid = () => {
    if (acoinBatch) {
      return !inputError;
    }

    return inputValue.length === 12 || inputValue.length === 16;
  };

  const digitKeys = [1, 2, 3, 4, 5, 6, 7, 8, 9].map((digit) => (
    <Key
      key={digit}
      value={digit}
      handleClick={() => addDigit('' + digit)}
      disabled={!keyboardActive}
    />
  ));

  return (
    <div className={classes.CashInTerminal}>
      {acoinBatch ? (
        <React.Fragment>
          <div className={classes.Input}>
            <span className={classes.Currency}>R</span>
            {displayCode}
          </div>
          {!!inputError && <span className={classes.Error}>{inputError}</span>}

          <p className={classes.Text}>
            If you want to load the full amount of your voucher{' '}
            <span className="bold">{formatBalance(acoinBatch.amount)}</span>,{' '}
            <br className="hidden-mobile" /> please click OK <br />
            If you would like to load a partial amount, enter the amount and
            press OK. You can find new voucher with the balance in your voucher
            wallet.
          </p>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className={classes.Input + ' ' + classes.VoucherInput}>
            {inputError && !inputValue.length ? (
              <span className={classes.Error}>{inputError}</span>
            ) : (
              displayCode
            )}
            {!inputError && !inputValue.length ? (
              <span className={classes.Info}>Ticket or Voucher Number</span>
            ) : null}
          </div>
          {!isTouchUnit &&
            navigator.clipboard &&
            navigator.clipboard.readText && (
              <ButtonSecondary
                onClick={pasteFromClipboard}
                value="Paste from Clipboard"
                customClass="button-paste"
              />
            )}
        </React.Fragment>
      )}
      <div className={classes.Keyboard}>
        {digitKeys}
        <Key
          value="OK"
          handleClick={onClickApplyHandler}
          customClass={classes.Green}
          disabled={!isInputValueValid()}
        />
        <Key
          value={0}
          handleClick={() => addDigit('0')}
          disabled={!keyboardActive}
        />
        <Key
          value="X"
          handleClick={removeDigit}
          customClass="cashin-terminal-button"
          disabled={inputValue.length === 0 && !inputError}
        />
      </div>
      <Button navPath={backPath}>Cancel</Button>
    </div>
  );
};

export default CashInTerminal;
