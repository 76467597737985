import { Socket } from 'phoenix';

export default class UnitSocket {
  static getInstance(token, isReconnect) {
    if (!UnitSocket.instance || isReconnect) {
      UnitSocket.instance = new Socket(
        `wss://${process.env.REACT_APP_API_URL}/v2/units/socket`,
        { params: { token } }
      );
      UnitSocket.instance.connect();
    }

    return UnitSocket.instance;
  }
}
