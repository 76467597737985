import React, { useContext } from 'react';
import { GamesList, Page } from '@homeplay/components';
import { useSelector } from 'react-redux';
import { selectBonusGames, SiteContext } from '@homeplay/app';
import classes from './BonusGames.module.scss';

const BonusGames = () => {
  const { siteName } = useContext(SiteContext);
  const games = useSelector(selectBonusGames);

  return (
    <Page
      headerContent={'Bonus Games'}
      customClass={classes.BonusGames}
      backPath={`/${siteName ? siteName + '/' : ''}happy-hour`}
    >
      <div className="container">{games && <GamesList games={games} />}</div>
    </Page>
  );
};

export default BonusGames;
