import React from 'react';
import TransactionItem from './TransactionItem';
import classes from './TransactionsList.module.scss';
import { AutoSizer, List, WindowScroller } from 'react-virtualized';

const TransactionsList = ({ transactions }) => {
  const renderRow = ({ index, key, style }) => {
    const transaction = transactions[index];
    return (
      <div key={key} style={style}>
        <TransactionItem
          type={transaction.type}
          amount={transaction.amount}
          depositAmount={transaction.deposit_amount}
          timestamp={transaction.timestamp}
          detail={
            transaction.corr_account
              ? transaction.corr_account.entity.code
              : null
          }
          status={transaction.operation_status}
        />
      </div>
    );
  };

  return (
    <div className={classes.TransactionsList}>
      <WindowScroller>
        {({ height, registerChild, scrollTop }) => (
          <AutoSizer disableHeight>
            {({ width }) => (
              <div ref={registerChild}>
                <List
                  autoHeight
                  scrollTop={scrollTop}
                  width={width}
                  height={height}
                  rowHeight={54}
                  rowRenderer={renderRow}
                  rowCount={transactions.length}
                />
              </div>
            )}
          </AutoSizer>
        )}
      </WindowScroller>
    </div>
  );
};

export default TransactionsList;
