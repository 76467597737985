import React, {useState} from 'react';
import classes from './RadioButtonsList.module.scss';
import {CSSTransition} from "react-transition-group";
import ButtonSecondary from "../ButtonSecondary";
import Modal from "../../Modal";
import Button from "../Button";

const RadioButtonsList = ({items, name, selected, onSelect, onDeleteItem}) => {
    const [deleteItemId, setDeleteItemId] = useState(null);

    const handleChange = e => {
        onSelect(e.target.value)
    }

    const handleDeleteItem = (itemId) => {
        onDeleteItem(itemId);
        setDeleteItemId(null);
    }

    const itemsList = items.map((item) => (
        <li key={item.id} className={classes.RadioButtonItem}>
            <label>
                {item.value}
                <input
                    type="radio"
                    name={name}
                    value={item.id}
                    id={item.id}
                    checked={item.id === selected}
                    onChange={handleChange}
                    className="visibility-hidden"
                />
                <span className="color-primary"></span>
            </label>

            {onDeleteItem &&
                <div className={classes.ButtonDelete} onClick={() => setDeleteItemId(item.id)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"/>
                    </svg>
                </div>
            }
        </li>
    ));

    return (
        <React.Fragment>
            <ul className={classes.RadioButtonsList}>
                {itemsList}
            </ul>
            {onDeleteItem &&
                <CSSTransition in={!!deleteItemId} timeout={0} classNames="modal" unmountOnExit>
                    <Modal modalClose={() => setDeleteItemId(null)}>
                        <div>
                            <h4>Are you sure you want to delete your bank account?</h4>
                            <p>This action cannot be undone</p>

                            <Button onClick={() => handleDeleteItem(deleteItemId)} customClass='d-block'>Delete</Button>
                            <ButtonSecondary onClick={() => setDeleteItemId(null)} value='Cancel' customClass='d-block'/>
                        </div>
                    </Modal>
                </CSSTransition>
            }
        </React.Fragment>
    );
};

export default React.memo(RadioButtonsList);
