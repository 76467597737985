import { DateTime } from 'luxon';
import cronParser from 'cron-parser';

//Convert cron string to local time zone
const cronConverter = (
  cronExpression,
  originalTimeZone,
  targetTimeZone,
  includeSeconds = true
) => {
  const interval = cronParser.parseExpression(cronExpression);
  const now = DateTime.local();

  const originalZoneOffset = now.setZone(originalTimeZone).offset;
  const targetZoneOffset = now.setZone(targetTimeZone).offset;

  const timeZoneOffsetMin = targetZoneOffset - originalZoneOffset;

  const c = getDaysHoursMinutes(
    interval.fields.hour[0],
    interval.fields.minute[0],
    timeZoneOffsetMin
  );
  const cronExpressionFields = getFieldsCron(cronExpression);

  // Minute
  cronExpressionFields.minute = addMinutes(
    cronExpressionFields.minute,
    c.minutes
  );

  // Hour
  cronExpressionFields.hour = addHours(cronExpressionFields.hour, c.hours);

  // Month
  if (
    (cronExpressionFields.dayOfMonth.indexOf(1) >= 0 && c.days === -1) ||
    (cronExpressionFields.dayOfMonth.indexOf(31) >= 0 && c.days === 1)
  ) {
    cronExpressionFields.month = addMonth(cronExpressionFields.month, c.days);
  }

  // Day of month
  cronExpressionFields.dayOfMonth = addDayOfMonth(
    cronExpressionFields.dayOfMonth,
    c.days
  );

  // Day of week
  cronExpressionFields.dayOfWeek = addDayOfWeek(
    cronExpressionFields.dayOfWeek,
    c.days
  );
  try {
    return setFieldsCron(cronExpressionFields, includeSeconds);
  } catch (err) {
    if (err.message.includes('Invalid explicit day of month definition')) {
      cronExpressionFields.dayOfMonth = [1];
      cronExpressionFields.month = addMonth(cronExpressionFields.month, 1);
      return setFieldsCron(cronExpressionFields, includeSeconds);
    }
    return cronExpression;
  }
};

const getDaysHoursMinutes = (hour, minute, timeZoneOffset) => {
  const minutes = hour * 60 + minute;
  const newMinutes = minutes + timeZoneOffset;
  const diffHour = (Math.floor(newMinutes / 60) % 24) - hour;
  const diffMinutes = (newMinutes % 60) - minute;
  const diffDays = Math.floor(newMinutes / (60 * 24));

  return { hours: diffHour, minutes: diffMinutes, days: diffDays };
};

const getFieldsCron = (expression) => {
  const interval = cronParser.parseExpression(expression);
  return JSON.parse(JSON.stringify(interval.fields));
};

const setFieldsCron = (fields, includeSeconds) => {
  const expression = cronParser.fieldsToExpression(fields).stringify();

  if (includeSeconds) {
    const second = getSeconds({ ...fields });
    return `${second} ${expression}`;
  }

  return expression;
};

const getSeconds = (fields) => {
  fields.minute = fields.second;
  return cronParser.fieldsToExpression(fields).stringify().split(' ')[0];
};

const addHours = (hours, hour) =>
  hours.map((n) => {
    const h = n + hour;
    if (h > 23) return h - 24;
    if (h < 0) return h + 24;
    return h;
  });

const addMinutes = (minutes, minute) =>
  minutes.map((n) => {
    const m = n + minute;
    if (m > 59) return m - 60;
    if (m < 0) return m + 60;
    return m;
  });

const addDayOfMonth = (dayOfMonth, day) => {
  if (dayOfMonth.length > 30) return dayOfMonth;
  return dayOfMonth.map((n) => {
    const d = n + day;
    if (d > 31 || n === 'L') return 1;
    if (d < 1) return 'L';
    return d;
  });
};

const addDayOfWeek = (dayOfWeek, day) => {
  // Sunday is 0, Saturday is 6
  const dayOfWeekFiltered = dayOfWeek.filter((n) => n !== 7);

  if (dayOfWeekFiltered.length > 6) return dayOfWeekFiltered;
  return dayOfWeekFiltered.map((n) => {
    const d = n + day;
    if (d > 6) return 0;
    if (d < 0) return 6;
    return d;
  });
};

const addMonth = (month, mon) => {
  if (month.length > 11) return month;
  return month.map((n) => {
    const m = n + mon;
    if (m > 12) return 1;
    if (m < 1) return 12;
    return m;
  });
};

export default cronConverter;
