import React, { useContext } from 'react';
import {
  Footer,
  MainHeader,
  GamesList,
  ProductsList,
  InfoBanner,
} from '@homeplay/components';
import {
  SiteContext,
  selectProducts,
  selectTopGames,
  selectHhSchedules,
} from '@homeplay/app';
import classes from './MainPage.module.scss';
import bannerImg from '../../assets/images/banner.jpg';
import bannerMobileImg from '../../assets/images/banner-mobile.jpg';
import { useSelector } from 'react-redux';

const MainPage = () => {
  const { isDesktop } = useContext(SiteContext);
  const products = useSelector(selectProducts);
  const topGames = useSelector(selectTopGames);
  const { nearestHh } = useSelector(selectHhSchedules);

  return (
    <React.Fragment>
      <MainHeader />
      <main className={classes.MainMenu}>
        {nearestHh && <InfoBanner texts={nearestHh.texts} />}

        <div className={classes.Banner}>
          {isDesktop ? (
            <img src={bannerImg} alt="" width="1920" height="658" />
          ) : (
            <img src={bannerMobileImg} alt="" width="920" height="416" />
          )}
        </div>
        {products && <ProductsList products={products} />}
        <div className="container">
          <h4>Enjoy Playing Games</h4>
          <svg
            className={classes.Divider}
            width="246"
            height="15"
            viewBox="0 0 246 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="116"
              y="7.07324"
              width="10.003"
              height="10.0025"
              transform="rotate(-45 116 7.07324)"
              fill="#C5C5C5"
            />
            <path
              d="M0 7.2L97.8676 4L110 7.2L97.8676 10L0 7.2Z"
              fill="#C4C4C4"
            />
            <path
              d="M246 6.8L148.132 10L136 6.8L148.132 4L246 6.8Z"
              fill="#C4C4C4"
            />
          </svg>
          <h4>Most Popular</h4>
          {topGames && <GamesList games={topGames} />}
        </div>
      </main>
      <Footer />
    </React.Fragment>
  );
};

export default MainPage;
