import React, { useEffect, useState } from 'react';
import classes from './ScrollArrows.module.scss';
import { scrollTo } from 'scroll-js';

const ScrollArrows = () => {
  const [arrowUp, setArrowUp] = useState(false);
  const [arrowDown, setArrowDown] = useState(true);

  useEffect(() => {
    onWindowScroll();
    window.addEventListener('load', onWindowScroll);
    window.addEventListener('scroll', onWindowScroll);
    const timerId = setTimeout(onWindowScroll, 2000);

    return () => {
      window.removeEventListener('load', onWindowScroll);
      window.removeEventListener('scroll', onWindowScroll);
      clearTimeout(timerId);
    };
  }, []);

  const onWindowScroll = () => {
    const scroll = window.scrollY;
    if (scroll === 0) {
      setArrowUp(false);
    } else {
      setArrowUp(true);
    }

    const scrollHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );

    if (scroll + window.innerHeight === scrollHeight) {
      setArrowDown(false);
    } else {
      setArrowDown(true);
    }
  };

  const handleScrollUp = () => {
    scrollTo(window, {
      top: window.scrollY - window.innerHeight + 200,
      easing: 'ease-in-out',
    }).then(function () {});
  };

  const handleScrollDown = () => {
    scrollTo(window, {
      top: window.scrollY + window.innerHeight - 200,
      easing: 'ease-in-out',
    }).then(function () {});
  };

  if (!arrowUp && !arrowDown) {
    return null;
  }

  return (
    <div className={classes.ScrollArrows} data-testid="scroll-arrows">
      <span
        className={classes.ArrowUp + (!arrowUp ? ' ' + classes.Disabled : '')}
        onClick={arrowUp ? handleScrollUp : null}
      >
        <svg
          width="54"
          height="62"
          viewBox="0 0 54 46"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="fill-primary"
            d="M13 45V32.5186H1L27 1L53 32.3925H40.875V45H13Z"
            stroke="white"
            strokeWidth="1.6695"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
      <span
        className={
          classes.ArrowDown + (!arrowDown ? ' ' + classes.Disabled : '')
        }
        onClick={arrowDown ? handleScrollDown : null}
      >
        <svg
          width="54"
          height="62"
          viewBox="0 0 54 45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="fill-primary"
            d="M13 1V13.1977H1L27 44L53 13.3209H40.875V1H13Z"
            stroke="white"
            strokeWidth="1.6695"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    </div>
  );
};

export default ScrollArrows;
