import React from 'react';
import Modal from './index';
import classes from './Modal.module.scss';

const ErrorModal = ({ error, onClose }) => {
  if (!error) {
    return null;
  }

  return (
    <Modal modalClose={onClose}>
      <h2 className={classes.ErrorMessage}>{error.message}</h2>
      {error.additionalInfo && (
        <p className={classes.additionalInfo}>{error.additionalInfo}</p>
      )}
    </Modal>
  );
};

export default ErrorModal;
