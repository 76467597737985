import React, { useEffect, useState } from 'react';
import hhFrameImg from '../../../../shared/assets/images/happyHour/frame.png';
import hhBonusImg from '../../../../shared/assets/images/happyHour/bonus-base.png';
import hhArrowImg from '../../../../shared/assets/images/happyHour/arrow.png';
import classes from './HappyHour.module.scss';

const BonusBannerBase = ({ bonusParams, onClick }) => {
  const [timerInitValue, setTimerInitValue] = useState(null);
  const [timer, setTimer] = useState(null);

  const getTimerValue = (distance) => {
    const hours = (
      '0' + Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    ).slice(-2);
    const minutes = (
      '0' + Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
    ).slice(-2);
    const seconds = ('0' + Math.floor((distance % (1000 * 60)) / 1000)).slice(
      -2
    );
    return `${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    if (bonusParams) {
      const distance = bonusParams.countDownDate - Date.now() + 1000;
      if (distance > 0) {
        setTimerInitValue(getTimerValue(distance));
      }
    }
  }, [bonusParams]);

  useEffect(() => {
    if (bonusParams) {
      let timerId;
      const distance = bonusParams.countDownDate - Date.now();

      if (distance > 0) {
        timerId = setTimeout(() => {
          setTimer(getTimerValue(distance));
        }, 1000);
      }

      return () => {
        clearTimeout(timerId);
      };
    }
  }, [timer, bonusParams]);

  return (
    <div onClick={onClick} className={classes.BonusBannerBase}>
      <img
        className={classes.Frame}
        src={hhFrameImg}
        alt=""
        width="326"
        height="70"
      />
      <img
        className={classes.Bonus}
        src={hhBonusImg}
        alt=""
        width="171"
        height="78"
      />
      <img
        className={classes.Arrow}
        src={hhArrowImg}
        alt=""
        width="67"
        height="48"
      />
      {bonusParams && bonusParams.countDownDate ? (
        <div className={classes.Content}>
          Ends In <br /> <span>{timer ? timer : timerInitValue}</span>
        </div>
      ) : (
        <div className={classes.Content}>
          Click Here <br /> To Activate
        </div>
      )}
    </div>
  );
};

export default React.memo(BonusBannerBase);
