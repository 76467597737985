import React from 'react';
import classes from './Checkbox.module.scss';

const Checkbox = ({id, text, error, errorMessage, onChange, checked = false}) => {
    return (
        <div className={classes.Checkbox}>
            <input id={id} type="checkbox" checked={checked} onChange={onChange}/>
            <label htmlFor={id}>
                <svg width="12" height="10" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.05592 1.09115L8.73912 0.774346C8.53662 0.572097 8.26212 0.458496 7.97592 0.458496C7.68972 0.458496 7.41522 0.572097 7.21272 0.774346L3.67992 4.29995L2.78712 3.39995C2.58462 3.1977 2.31012 3.0841 2.02392 3.0841C1.73772 3.0841 1.46322 3.1977 1.26072 3.39995L0.943921 3.72035C0.843597 3.82064 0.764013 3.93971 0.709716 4.07077C0.655419 4.20182 0.627472 4.34229 0.627472 4.48415C0.627472 4.62601 0.655419 4.76647 0.709716 4.89753C0.764013 5.02858 0.843597 5.14766 0.943921 5.24795L2.92152 7.22555C3.12402 7.4278 3.39852 7.5414 3.68472 7.5414C3.97092 7.5414 4.24542 7.4278 4.44792 7.22555L9.05592 2.61875C9.15625 2.51846 9.23583 2.39938 9.29013 2.26833C9.34442 2.13727 9.37237 1.9968 9.37237 1.85495C9.37237 1.71309 9.34442 1.57262 9.29013 1.44157C9.23583 1.31051 9.15625 1.19144 9.05592 1.09115Z" fill="white"/></svg>
                {text}
            </label>
            {!checked && error && errorMessage &&
            <p className={classes.ErrorMessage}>{errorMessage}</p>
            }
        </div>
    );
};

export default React.memo(Checkbox);
