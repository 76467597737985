import React, { useContext } from 'react';
import classes from './Footer.module.scss';
import {
  SiteContext,
  selectUnitId,
  selectUser,
  selectSupportParams,
} from '@homeplay/app';
import WhatsAppLink from '../WhatsAppLink';
import ScrollArrows from '../ScrollArrows';
import { useSelector } from 'react-redux';
import ChatWidget from '../LiveChatWidget';
import { useNavigate } from 'react-router-dom';
import ContactInfoWidget from '../LiveChatWidget/components/ContactInfoWidget';

const Footer = () => {
  const { siteName, isTouchUnit } = useContext(SiteContext);
  const unitId = useSelector(selectUnitId);
  const user = useSelector(selectUser);
  const supportParams = useSelector(selectSupportParams);
  const loading = useSelector(
    (state) => state.unit.loading || state.auth.loading
  );
  const navigate = useNavigate();

  const handleClickAuth = () => {
    navigate(`/${siteName ? siteName + '/' : ''}auth`);
  };

  let support = null;

  if (unitId && !isTouchUnit && supportParams) {
    if (supportParams.useChat) {
      support = (
        <ChatWidget
          title="Welcome to HomePlay!"
          primaryColor="#a9499d"
          accountId={
            supportParams.accountId
              ? supportParams.accountId
              : process.env.REACT_APP_LIVECHAT_ACCOUNT_ID
          }
          greeting="Hi there! How can I help you?"
          awayMessage="Sorry, we're not available at the moment!"
          newMessagePlaceholder="Start typing..."
          newMessagesNotificationText="View new messages"
          authMessage="You should login to start conversation"
          requireEmailUpfront={false}
          requireAuth={true}
          customer={
            user && !loading
              ? {
                  phone: user.phone,
                  external_id: user.id,
                  metadata: {
                    site_name: siteName ?? process.env.REACT_APP_SITE_NAME,
                    unit_id: unitId,
                  },
                }
              : null
          }
          baseUrl={process.env.REACT_APP_LIVECHAT_BASE_URL}
          iframeUrlOverride={process.env.REACT_APP_LIVECHAT_IFRAME_URL}
          iconVariant="filled"
          onClickAuth={handleClickAuth}
        />
      );
    } else if (supportParams.whatsapp) {
      support = <WhatsAppLink phoneNumber={supportParams.whatsapp} />;
    } else {
      support = <ContactInfoWidget />;
    }
  } else if (unitId && !isTouchUnit) {
    support = <ContactInfoWidget />;
  }

  return (
    <React.Fragment>
      <footer className={classes.Footer}>
        {siteName && <p>Site Name: {siteName}</p>}
        <p>ID {unitId}</p>
        {support}
      </footer>
      {isTouchUnit && <ScrollArrows />}
    </React.Fragment>
  );
};

export default Footer;
