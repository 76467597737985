export default class PragmaticDGA {
  constructor(wsUri, casinoId, gamesTableIds, onReceiveGame, onError) {
    this.websocket = null;
    this.wsUri = null;
    this.tableId = null;
    this.casinoId = null;
    this.tryToConnect = true;
    this.onReceiveGame = onReceiveGame;
    this.gamesTableIds = gamesTableIds;
    this.onError = onError;

    this.connect(wsUri, casinoId);
  }

  connect = (wsUri, casinoId, tableId) => {
    const self = this;
    self.tryToConnect = true;
    self.wsUri = wsUri;
    if (self.websocket !== null && self.websocket.readyState !== 3) {
      self.websocket.close();
    }
    self.websocket = new WebSocket('wss://' + wsUri + '/ws');
    self.websocket.onopen = function (evt) {
      self.onWsOpen(evt, casinoId, tableId);
    };
    self.websocket.onclose = function (evt) {
      self.onWsClose(evt);
    };
    self.websocket.onmessage = function (evt) {
      self.onWsMessage(evt);
    };
    self.websocket.onerror = function (evt) {
      self.onWsError(evt);
    };
    if (tableId) {
      self.tableId = tableId;
    }
    self.casinoId = casinoId;
  };

  onMessage = (data) => {
    if (data.tableImage) {
      this.onReceiveGame(data);
    } else if (data.tableKey && !data.tableKey.length) {
      this.onError('Games are temporarily unavailable');
    }
  };

  onConnect = () => {
    const self = this;
    self.available(this.casinoId);
    this.gamesTableIds.forEach((tableId) => {
      self.subscribe(self.casinoId, tableId, 'ZAR');
    });
  };

  disconnect = () => {
    const self = this;
    self.tryToConnect = false;
    self.websocket.close();
  };

  subscribe = (casinoId, tableId, currency) => {
    const subscribeMessage = {
      type: 'subscribe',
      key: tableId,
      casinoId: casinoId,
      currency: currency,
    };

    const jsonSub = JSON.stringify(subscribeMessage);
    this.doWsSend(jsonSub);
  };

  available = (casinoId) => {
    const availableMessage = {
      type: 'available',
      casinoId: casinoId,
    };
    const self = this;
    const jsonSub = JSON.stringify(availableMessage);
    self.doWsSend(jsonSub);
  };

  onWsOpen = () => {
    const self = this;
    if (self.onConnect != null) {
      self.onConnect();
    }
    if (self.tableId) {
      self.subscribe(self.casinoId, self.tableId, 'ZAR');
    }
  };

  onWsClose = () => {
    const self = this;
    if (self.tryToConnect === true) {
      self.connect(self.wsUri, self.casinoId, self.tableId);
    }
  };

  onWsMessage = (evt) => {
    const self = this;
    const data = JSON.parse(evt.data);
    if (self.onMessage != null) {
      self.onMessage(data);
    }
  };

  onWsError = (evt) => {
    console.log('ERROR: ' + evt.data);
  };

  doWsSend = (message) => {
    const self = this;
    self.websocket.send(message);
  };
}
