import 'core-js/features/string/replace-all';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { SiteNotFound } from '@homeplay/containers';
import { FrameExit } from '@homeplay/components';
import UpdateServiceWorkerModal from '@homeplay/components/src/Modal/UpdateServiceWorkerModal';
import AppWrap from './AppWrap';
import './index.scss';

const Root = () => {
  const [isShowUpdateModal, setIsShowUpdateModal] = useState(false);

  const swConfig = {
    onUpdate: (registration) => {
      if (registration && registration.waiting) {
        setIsShowUpdateModal(true);
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      }
    },
  };

  useEffect(() => {
    if (
      window.location.href.includes('touchscreen') ||
      sessionStorage.getItem('hp-touchscreen')
    ) {
      serviceWorkerRegistration.unregister();
    } else {
      serviceWorkerRegistration.register(swConfig);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isShowUpdateModal && <UpdateServiceWorkerModal />}
      <BrowserRouter>
        <Routes>
          <Route path={'/'} element={<SiteNotFound />} />
          <Route path={'/exit'} element={<FrameExit />} />
          <Route path="/:site/*" element={<AppWrap />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Root />);
