import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { ToastContainer } from 'react-toastify';
import { cancelAcoinPendingRedemption } from '@homeplay/api';
import {
  SiteContext,
  resetAuthError,
  resetUnitError,
  selectUnitToken,
} from '@homeplay/app';
import { useDepositGlobalNotification } from '@homeplay/hooks';
import Loader from '../UI/Loader';
import Maintenance from './Maintenance';
import IdleModal from '../IdleModal';
import ErrorModal from '../Modal/ErrorModal';
import 'react-toastify/dist/ReactToastify.css';

const Layout = (props) => {
  const { isTouchUnit } = useContext(SiteContext);
  const loadingMessage = useSelector((state) => state.unit.loadingMessage);
  const authError = useSelector((state) => state.auth.error);
  const unitError = useSelector((state) => state.unit.error);
  const unitToken = useSelector(selectUnitToken);
  const loading = useSelector(
    (state) => state.unit.loading || state.auth.loading
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useDepositGlobalNotification();

  const error = unitError || authError;

  useEffect(() => {
    if (unitToken) {
      cancelAcoinPendingRedemption(unitToken);
    }
  }, [unitToken]);

  const handleCloseError = () => {
    if (error.redirect) {
      if (error.redirect === 'reload') {
        window.location.reload();
      } else {
        navigate(error.redirect);
      }
    }
    if (unitError) {
      dispatch(resetUnitError());
    }
    if (authError) {
      dispatch(resetAuthError());
    }
  };

  if (error && error.additionalInfo === 'server_offline') {
    return <Maintenance />;
  }

  return (
    <>
      <ToastContainer
        theme="colored"
        draggablePercent={25}
        autoClose={10_000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
      />

      {props.children}

      {!error ? loading && <Loader message={loadingMessage} /> : null}

      <CSSTransition in={!!error} timeout={0} classNames="modal" unmountOnExit>
        <ErrorModal error={error} onClose={handleCloseError} />
      </CSSTransition>

      {isTouchUnit && <IdleModal />}
    </>
  );
};

export default Layout;
