import ChatWidget from './components/ChatWidget';

export const open = () => window.dispatchEvent(new Event('papercups:open'));
export const close = () => window.dispatchEvent(new Event('papercups:close'));
export const toggle = () => window.dispatchEvent(new Event('papercups:toggle'));

export const identify = () => {
    // TODO: add ability to create/update customer information
    console.warn('`LiveChat.identify` has not been implemented yet!');
};

export const LiveChat = {
    open,
    close,
    toggle,
};

export {ChatWidget};

export default ChatWidget;
