import React, { useEffect, useState } from 'react';
import classes from './Header.module.scss';
import CountUp from 'react-countup';
import { formatBalance } from '@homeplay/utils';
import { useSelector } from 'react-redux';
import { selectUnitId } from '@homeplay/app';

const Balance = ({ user, balance, depositBalance, bonus }) => {
  const [bonusValue, setBonusValue] = useState(0);
  const unitId = useSelector(selectUnitId);
  const totalBalance = balance + depositBalance;

  useEffect(() => {
    setBonusValue(bonus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitId]);

  if (user && bonus > 0) {
    return (
      <div className={classes.Balance}>
        Total: <span>{formatBalance(totalBalance)}</span>
        <br />
        Free Spins:{' '}
        <CountUp
          start={bonusValue}
          end={bonus}
          onEnd={() => setBonusValue(bonus)}
          formattingFn={formatBalance}
        />
      </div>
    );
  }

  return <div>{totalBalance || user ? formatBalance(totalBalance) : null}</div>;
};

export default Balance;
