import React, { Suspense, useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectShowAcoinLink, SiteContext } from '@homeplay/app';
import { Loader } from '@homeplay/components';
import LoadFunds from './LoadFunds';
import CashinByVoucher from './CashinByVoucher';
import CashinByPhone from './CashinByPhone';

const HelpPage = React.lazy(() => import('./HelpPage'));

const CashIn = () => {
  const { isTouchUnit } = useContext(SiteContext);
  const showAcoinLink = useSelector(selectShowAcoinLink);

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {!isTouchUnit && showAcoinLink && (
          <Route path="/" element={<LoadFunds />} />
        )}
        {!isTouchUnit && showAcoinLink && (
          <Route path="/phone" element={<CashinByPhone />} />
        )}
        {(isTouchUnit || !showAcoinLink) && (
          <Route path="/" element={<CashinByVoucher />} />
        )}
        <Route path="/voucher" element={<CashinByVoucher />} />
        <Route path="help" element={<HelpPage />} />
        <Route path="*" element={<Navigate to=".." replace />} />
      </Routes>
    </Suspense>
  );
};

export default CashIn;
