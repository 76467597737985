import React, { useContext } from 'react';
import { SiteContext } from '@homeplay/app';
import ModalContainer from '../Modal/ModalContainer';
import ButtonPrimary from '../UI/ButtonPrimary';
import classes from './HappyHour.module.scss';
import hhBonusImg from '../../../../shared/assets/images/happyHour/bonus.png';
import hhCloseImg from '../../../../shared/assets/images/happyHour/close.png';
import { formatBalance } from '@homeplay/utils';

const BonusBanner = ({ onClickClose, minAmount, bonusAmount }) => {
  const { siteName } = useContext(SiteContext);

  return (
    <ModalContainer>
      <div className={classes.BonusBanner}>
        <img src={hhBonusImg} alt="" width="550" height="442" />
        <img
          onClick={onClickClose}
          className={classes.Close}
          src={hhCloseImg}
          alt=""
          width="58"
          height="58"
        />
        <div className={classes.Content}>
          Load <span>{formatBalance(minAmount, false)}</span> or more <br /> at
          once <br /> If no luck we will <br /> boost your play by
        </div>
        <div className={classes.BonusAmount}>
          {formatBalance(bonusAmount, false)}
        </div>
        <ButtonPrimary
          navPath={`/${siteName ? siteName + '/' : ''}cashin`}
          customClass={classes.Button}
        >
          Load {formatBalance(minAmount, false)}
        </ButtonPrimary>{' '}
        <br />
        <span onClick={onClickClose} className={classes.Link}>
          No, thanks
        </span>
      </div>
    </ModalContainer>
  );
};

export default React.memo(BonusBanner);
